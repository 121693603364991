import { Button, colorClassNameWrapper, variantClassNameWrapper } from '@frontend/shared';
import clsx from 'clsx';

import React, { FC, ReactNode } from 'react';

import { Spinner, SpinnerSize } from '../../spinner';

import styles from './styles.module.scss';

export type ModalTemplateProps = {
  content: ReactNode;
  onConfirm?: () => void;
  onReject?: () => void;
  confirmButtonLabel?: ReactNode;
  rejectButtonLabel?: ReactNode;
  hideConfirmButton?: boolean;
  hideRejectButton?: boolean;
  pending?: boolean;
  isLoading?: boolean;
  spinnerSize?: SpinnerSize;
  confirmButtonColor?: keyof typeof colorClassNameWrapper;
  confirmButtonVariant?: keyof typeof variantClassNameWrapper;
};

export const ModalTemplate: FC<ModalTemplateProps> = ({
  content,
  onConfirm,
  onReject,
  confirmButtonLabel = 'Yes',
  rejectButtonLabel = 'No',
  hideConfirmButton = false,
  hideRejectButton = false,
  pending = false,
  isLoading = false,
  spinnerSize = '24px',
  confirmButtonColor = 'primary',
  confirmButtonVariant = 'normal',
}) => {
  return (
    <div className={styles.modalContainer}>
      <div className={clsx(styles.content, { [styles.contentLoading]: pending })}>{content}</div>
      <div className={clsx(styles.buttons, { [styles.contentLoading]: pending })}>
        {!hideRejectButton && (
          <Button
            disabled={isLoading}
            type="button"
            color="secondary"
            modificator="wide"
            variant="outline"
            onClick={onReject}
          >
            {rejectButtonLabel}
          </Button>
        )}
        {!hideConfirmButton && (
          <Spinner fadeIn="delayed" size={spinnerSize} loading={isLoading}>
            <Button
              disabled={isLoading}
              type="button"
              color={confirmButtonColor}
              modificator="wide"
              variant={confirmButtonVariant}
              onClick={onConfirm}
            >
              {confirmButtonLabel}
            </Button>
          </Spinner>
        )}
      </div>
    </div>
  );
};
