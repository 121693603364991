import React, { FC } from 'react';

import { DidCatch } from './did-catch';

export const ErrorBoundary: FC = ({ children }) => {
  return (
    <>
      <DidCatch>{children}</DidCatch>
    </>
  );
};
