import { Button, CrossIcon } from '@frontend/shared';
import clsx from 'clsx';
import React, { FC, useCallback, useState } from 'react';

import { useLocalStorage } from 'helpers/hooks';

import styles from './styles.module.scss';

type TopBarProps = {
  closable?: boolean;
  onClose?: () => void;
  localStorageKey?: string;
  className?: string;
};

export const TopBar: FC<TopBarProps> = ({ onClose, closable = false, children, className, localStorageKey }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isTopBarClosedLS, setTopBarClosedLS] = useLocalStorage(localStorageKey || '', false);

  const handleClose = useCallback(() => {
    setIsVisible(false);
    if (localStorageKey) {
      setTopBarClosedLS(true);
    }
    onClose?.();
  }, [setIsVisible, onClose, setTopBarClosedLS, localStorageKey]);

  if (!isVisible || isTopBarClosedLS) {
    return null;
  }

  return (
    <div className={clsx(styles.redirectBarContainer, className)}>
      <div className={styles.redirectBarContent}>
        {children}
        {closable && (
          <Button className={styles.closeButton} variant="icon" color="transparent" onClick={handleClose}>
            <CrossIcon color="#FFF" />
          </Button>
        )}
      </div>
    </div>
  );
};
