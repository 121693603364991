import { createAction, createReducer } from 'redux-act';

export const manageCollectionViewActions = {
  registerOpenedView: createAction<string>('INSERT_PRODUCTS_MANAGE_COLLECTION--OPEN'),
  registerClosedView: createAction('INSERT_PRODUCTS_MANAGE_COLLECTION--CLOSE'),
  registerOpenPopup: createAction('INSERT_PRODUCTS_MANAGE_COLLECTION--REGISTER-OPEN-POPUP'),
  registerClosedPopup: createAction('INSERT_PRODUCTS_MANAGE_COLLECTION--REGISTER-CLOSE-POPUP'),
};

export const manageCollectionViewReducer = createReducer<{ currentCollectionId?: string; isPopupOpen: boolean }>(
  {},
  {
    currentCollectionId: undefined,
    isPopupOpen: false,
  }
)
  .on(manageCollectionViewActions.registerOpenedView, (state, payload) => ({
    ...state,
    currentCollectionId: payload,
  }))
  .on(manageCollectionViewActions.registerClosedView, () => ({
    isPopupOpen: false,
    currentCollectionId: undefined,
  }))
  .on(manageCollectionViewActions.registerOpenPopup, (state) => ({
    ...state,
    isPopupOpen: true,
  }))
  .on(manageCollectionViewActions.registerClosedPopup, (state) => ({
    ...state,
    isPopupOpen: false,
  }));
