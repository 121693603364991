import React, { FC, FunctionComponent, useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Action } from 'redux';

import { ChunkErrorModal } from './chunk-error-modal';
import { registerLocalOnErrorHandler, removeLocalOnErrorHandler } from './error-modal-error-handler';
import { errorModalRegistry, ErrorModalRegistryEntry } from './error-modal-registry';
import {
  registerExplicitlyShowErrorModalHandler,
  removeExplicitlyShowErrorModalHandler,
} from './explicitly-show-error-modal';
import { RetryableErrorModal } from './retryable-error-modal';
import { SimpleErrorModal } from './simple-error-modal';

import { ModalType } from './types';

type OnErrorCallbackResult =
  | {
      type: 'built-in';
      buildInType: ModalType;
    }
  | {
      type: 'registry';
      entry: ErrorModalRegistryEntry;
    };

export const ErrorModal: FC = () => {
  const [modalType, setModalType] = useState<ModalType | undefined>(undefined);
  const [modalComponent, setModalComponent] = useState<
    { Component?: ErrorModalRegistryEntry['ErrorModalComponent'] } | undefined
  >({});
  const [caughtRetryableReduxAction, setCaughtRetryableReduxAction] = useState<Action | undefined>(undefined);
  const dispatch = useDispatch();

  const closeModal = useCallback(() => {
    setModalType(undefined);
    setCaughtRetryableReduxAction(undefined);
    setModalComponent(undefined);
  }, []);

  const retryAction = useCallback(() => {
    dispatch(caughtRetryableReduxAction);
    closeModal();
  }, [closeModal, dispatch, caughtRetryableReduxAction]);

  const onSagaErrorCallback = useCallback<(arg: { error: any; action: Action }) => OnErrorCallbackResult>(
    ({ error, action }) => {
      setCaughtRetryableReduxAction(action);
      const errorModalFromRegistry = getErrorModalFromRegistry(error);
      if (errorModalFromRegistry) {
        return {
          type: 'registry',
          entry: errorModalFromRegistry,
        };
      }
      return {
        type: 'built-in',
        buildInType: errorToModalType(error, { allowRetryable: true }),
      };
    },
    []
  );

  const onAsyncErrorCallback = useCallback<(error: any) => OnErrorCallbackResult>((error) => {
    const errorModalFromRegistry = getErrorModalFromRegistry(error);
    if (errorModalFromRegistry) {
      return {
        type: 'registry',
        entry: errorModalFromRegistry,
      };
    }
    return {
      type: 'built-in',
      buildInType: errorToModalType(error),
    };
  }, []);

  const onError = useCallback<(arg: { error: any; action?: Action } | any) => { handledNicely: boolean }>(
    (errorPayload) => {
      let result: OnErrorCallbackResult;

      if (errorPayload.error != null && errorPayload.action != null) {
        result = onSagaErrorCallback({
          error: errorPayload.error,
          action: errorPayload.action,
        });
      } else {
        result = onAsyncErrorCallback(errorPayload);
      }

      if (result.type === 'built-in') {
        setModalType(result.buildInType);
        return {
          handledNicely: modalTypeToHandledNicely[result.buildInType] ?? true,
        };
      }

      if (result.type === 'registry') {
        const { errorModalComponentFactory, ErrorModalComponent, handlesErrorNicely } = result.entry;
        if (ErrorModalComponent) {
          setModalComponent({ Component: ErrorModalComponent });
        } else if (errorModalComponentFactory) {
          setModalComponent({ Component: errorModalComponentFactory(errorPayload.error) });
        }

        return {
          handledNicely:
            typeof handlesErrorNicely === 'function' ? handlesErrorNicely(errorPayload.error) : handlesErrorNicely,
        };
      }

      throw new Error(`Unknown result type: '${JSON.stringify(result)}'`);
    },
    [onSagaErrorCallback, onAsyncErrorCallback]
  );

  useEffect(() => {
    const onShowErrorModal = (Component: FunctionComponent<{ onClose: () => void }>) =>
      setModalComponent({ Component });
    registerLocalOnErrorHandler(onError);
    registerExplicitlyShowErrorModalHandler(onShowErrorModal);
    return () => {
      removeLocalOnErrorHandler(onError);
      removeExplicitlyShowErrorModalHandler(onShowErrorModal);
    };
  }, [onError]);

  if (modalComponent?.Component) {
    return React.createElement(modalComponent.Component, { onClose: closeModal });
  }

  return (
    <>
      {modalType === 'chunk-load-error' && <ChunkErrorModal onClose={closeModal} />}
      {modalType === 'generic-simple' && <SimpleErrorModal closeModal={closeModal} />}
      {modalType === 'generic-retryable' && <RetryableErrorModal closeModal={closeModal} handleRetry={retryAction} />}
    </>
  );
};

function getErrorModalFromRegistry(error: any): ErrorModalRegistryEntry | undefined {
  return errorModalRegistry.find(({ shouldOpenModal }) => shouldOpenModal(error));
}

function errorToModalType(error: any, options: { allowRetryable?: boolean } = {}): ModalType {
  const isClientErrorApiRequest = error?.response?.status > 399 && error?.response?.status < 500;

  if (error?.name === 'ChunkLoadError' || error?.code === 'CSS_CHUNK_LOAD_FAILED') {
    return 'chunk-load-error';
  }

  if (isClientErrorApiRequest || !options.allowRetryable) {
    return 'generic-simple';
  }

  return 'generic-retryable';
}

const modalTypeToHandledNicely: Record<ModalType, boolean> = {
  'chunk-load-error': true,
  'generic-simple': false,
  'generic-retryable': false,
};
