import { select, call, put, StrictEffect } from 'redux-saga/effects';

import { UserAPI } from 'services/api';
import { CurrentProfileResponse } from 'services/api/user/profile/types-get-current';
import { createDefaultActions } from 'store/helpers';

import { logoutSoftly } from '../common-actions';

const fetchProfileActions = createDefaultActions<void, CurrentProfileResponse>('FETCH_PROFILE');

function* profileFetchSaga(): Generator<StrictEffect, any, any> {
  try {
    const token = yield select((state) => state.auth.data.token);
    const data: CurrentProfileResponse = yield call(UserAPI.getCurrentProfile, token);

    yield put(fetchProfileActions.success(data));
  } catch (e: any) {
    yield put(fetchProfileActions.failure(e));
    if (e?.response?.status === 403) {
      // @ts-ignore
      yield logoutSoftly();
      return;
    }
    throw e;
  }
}

export { profileFetchSaga, fetchProfileActions };
