let localErrorHandler: (error: any) => void;

export function registerHandlerOnBoundaryError(callback: typeof localErrorHandler) {
  localErrorHandler = callback;
}

export function removeOnErrorBoundaryError(callback: typeof localErrorHandler) {
  if (localErrorHandler === callback) {
    // @ts-ignore
    localErrorHandler = undefined;
  }
}
export function onBoundaryError(error: any): void {
  localErrorHandler?.(error);
}
