import { safeTakeLatest } from '@frontend/commons';

import { put } from 'redux-saga/effects';

import { createDefaultAsyncHelpers } from 'store/helpers';

import { actions as merchantSettingsActions } from 'store/merchant-settings';

import { updateMerchantPublicInfo } from './service';

const {
  saga: updateSaga,
  actions: updateActions,
  reducer,
} = createDefaultAsyncHelpers({
  fetchFunc: updateMerchantPublicInfo,
  actionName: 'MERCHANT_PUBLIC_INFO__UPDATE',
  initialData: { updatedSettingsFetched: false },
});

reducer.off(updateActions.request);

reducer.on(updateActions.request, () => {
  return {
    error: undefined,
    pending: true,
    pristine: false,
    initialPending: false,
    data: {
      updatedSettingsFetched: false,
    },
  };
});

reducer.on(merchantSettingsActions.fetchMerchantSettingsActionSuccess, (state) => {
  return {
    ...state,
    data: {
      updatedSettingsFetched: true,
    },
  };
});

function* updateSuccessSaga() {
  yield put(merchantSettingsActions.fetchMerchantSettingsAction());
}

function* saga() {
  yield safeTakeLatest(updateActions.request, updateSaga);
  yield safeTakeLatest(updateActions.success, updateSuccessSaga);
}

const actions = { updateMerchantPublicInfo: updateActions.request };

export { saga, actions, reducer };
