import { combineReducers } from 'redux';

import {
  reducer as collectionProductsReducer,
  saga as collectionProductsSaga,
} from '../manage-collection/collection-products/store/main.duck';

import { fetchCategoryPropertiesReducer, categoryPropertiesSaga } from './category-properties';

import { metadataReducer, metadataUpdateSaga } from './metadata';
import {
  fetchProductCustomizationSaga,
  createOrUpdateProductCustomizationSaga,
  createOrUpdateProductCustomizationReducer,
  fetchProductCustomizationReducer,
} from './product-customization';
import {
  detailedProductsReducer,
  fetchCommissionsReducer,
  fetchProductsSaga,
  pageNumberReducer,
  productsReducer,
} from './products';
import { searchParamsReducer, searchParamsSaga } from './search-params/duck';
import { State } from './types';

export const reducer = combineReducers<State>({
  products: productsReducer,
  metadataUpdate: metadataReducer,
  commissions: fetchCommissionsReducer,
  searchParams: searchParamsReducer,
  detailedProducts: detailedProductsReducer,
  singleCollection: collectionProductsReducer,
  pageNumber: pageNumberReducer,
  createOrUpdateProductCustomization: createOrUpdateProductCustomizationReducer,
  productCustomization: fetchProductCustomizationReducer,
  categoryProperties: fetchCategoryPropertiesReducer,
});

export function* saga() {
  yield* searchParamsSaga();
  yield* fetchProductsSaga();
  yield* collectionProductsSaga();
  yield* fetchProductCustomizationSaga();
  yield* createOrUpdateProductCustomizationSaga();
  yield* categoryPropertiesSaga();
  yield* metadataUpdateSaga();
}

export * from './search-params/duck';
export * from './products/duck';
export { resetFiltersAndResult } from './common';
