import { capitalize } from 'lodash';

import { Category } from 'services/api/categories/types';

import { FilterGroup, FilterGroups, StringFilter } from 'types/filters';

import { UNCATEGORIZED } from '../../../../constants';
import { AvailableMultiSelectFilter } from '../types';

import { ALL_KNOWN_FILTERS, KnownMultiselectFiltersKeys } from './FILTERS';
import { categorySort, stringFiltersToMultiselectOptions } from './misc-helpers';

const isHandleableFilter = (filterGroup: FilterGroup): filterGroup is FilterGroup<StringFilter[]> =>
  ['string', 'color', 'range'].includes(filterGroup.type);

const getHandleableFilters = (allFilters: FilterGroups): FilterGroups<StringFilter[]> =>
  allFilters.filter(isHandleableFilter);

export const extractKnownSearchFilters = (
  allFilters: FilterGroups,
  knownFiltersToExtract: KnownMultiselectFiltersKeys[]
) => {
  const handleableFilters = getHandleableFilters(allFilters);
  return knownFiltersToExtract
    .map((primaryFilter: KnownMultiselectFiltersKeys) => handleableFilters.find((item) => item.value === primaryFilter))
    .filter((item): item is FilterGroup<StringFilter[]> => item != null)
    .map(({ value, available, id }) => ({
      available: stringFiltersToMultiselectOptions(available).sort((dynamicFilter1, dynamicFilter2) => {
        if (!dynamicFilter2.count || !dynamicFilter1.count) {
          return 1;
        }
        return dynamicFilter1.label.localeCompare(dynamicFilter2.label);
      }),
      name: value,
      value: id,
      label: value.replace(/\w+/g, capitalize),
      searchableLabel: value.replace(/\w+/g, capitalize),
      filterName: value,
    }));
};

export const extractUnknownSearchFilters = (allFilters: FilterGroups) =>
  getHandleableFilters(allFilters)
    .filter((filterItem) => !(ALL_KNOWN_FILTERS as string[]).includes(filterItem.value))
    .map(({ value, available, id }) => ({
      available: stringFiltersToMultiselectOptions(available).sort((dynamicFilter1, dynamicFilter2) => {
        if (!dynamicFilter2.count || !dynamicFilter1.count) {
          return 1;
        }
        return dynamicFilter1.label.localeCompare(dynamicFilter2.label);
      }),
      name: value,
      value: id,
      label: value.replace(/\w+/g, capitalize),
      searchableLabel: value.replace(/\w+/g, capitalize),
      filterName: value,
    }));

export const generateCategoryAvailables = (
  filtersCount: { [catId: string]: number },
  categories: Category[],
  currentCategory: Category,
  depth = 0
): AvailableMultiSelectFilter[] => {
  const depthToFilterName = {
    0: 'departments',
    1: 'categories',
    2: 'subcategories',
  };

  return categories.reduce((acc, cat) => {
    if (cat.parentId === currentCategory.id) {
      const availableCategories = generateCategoryAvailables(filtersCount, categories, cat, depth + 1).sort(
        categorySort
      );
      const searchableLabel =
        depth === 1 ? [cat.name, ...availableCategories.map((aCat) => aCat.label)].join(' ') : cat.name;

      const label =
        cat.name === UNCATEGORIZED && currentCategory.parentId !== null
          ? `${UNCATEGORIZED} ${currentCategory.name}`
          : cat.name.replace(/\w+/g, capitalize);

      acc.push({
        value: cat.id,
        parentId: cat.parentId,
        name: cat.name,
        filterName: depthToFilterName[depth],
        label,
        count: filtersCount[cat.id],
        ...(availableCategories.length ? { available: availableCategories } : {}),
        searchableLabel,
      });
    }

    return acc;
  }, [] as AvailableMultiSelectFilter[]);
};

export const generateDepartmentsSearchFilter = (
  categoryTree: Category[],
  allFilters: FilterGroup[]
): AvailableMultiSelectFilter[] => {
  if (!categoryTree) {
    return [];
  }

  const filtersCount = allFilters.reduce((filtersAcc, filter) => {
    if (['departments', 'categories', 'subcategories'].includes(filter.value)) {
      return {
        ...filtersAcc,
        ...filter.available.reduce(
          (acc, availableFilter) => ({ ...acc, [availableFilter.id]: availableFilter.count }),
          {}
        ),
      };
    }
    return filtersAcc;
  }, {} as { [key: string]: number });

  const departmentsAvailableIds =
    allFilters.find((filter) => filter.id === 'departments')?.available?.map((dep) => dep.id) || [];
  const departments = categoryTree.filter((cat) => !cat.parentId && departmentsAvailableIds.includes(cat.id));
  const allCategories = categoryTree.filter((cat) => cat.parentId);

  return departments
    .reduce((acc, dep) => {
      const availableCategories: AvailableMultiSelectFilter[] = [
        {
          label: `All ${dep.name}`,
          value: dep.id,
          name: dep.name,
          filterName: 'departments',
          searchableLabel: `All ${dep.name}`,
          count: filtersCount[dep.id],
        },
        ...generateCategoryAvailables(filtersCount, allCategories, dep, 1).sort(categorySort),
      ];

      acc.push({
        ...dep,
        value: dep.id,
        available: availableCategories,
        label: dep.name.replace(/\w+/g, capitalize),
        filterName: 'departments',
        searchableLabel: dep.name,
        count: filtersCount[dep.id],
      });

      return acc;
    }, [] as AvailableMultiSelectFilter[])
    .sort((filter1, filter2) => filter1.label.localeCompare(filter2.label));
};
