import { addProducts } from './add-products';
import { create } from './create';
import { getAllCollections } from './get-all';
import { getById } from './get-by-id';
import { getCollectionsByProductId } from './get-by-product-id';
import { byProfileName } from './get-by-profile-name';
import { getPrivateCollectionsProductsAvailability } from './get-private-availability';
import { getSharedCollectionsProductsAvailability } from './get-shared-availability';
import { getStoreCollections } from './get-store';
import { removeCollection } from './remove';
import { removeProduct } from './remove-product';
import { removeProducts } from './remove-products';
import { reorder } from './reorder';
import { setOrder } from './set-order';
import { setStoreCollections } from './set-store';
import { updateCollection } from './update';

export const CollectionsAPI = {
  getById,
  getStoreCollections,
  getAllCollections,
  getSharedCollectionsProductsAvailability,
  getPrivateCollectionsProductsAvailability,
  byProfileName,
  updateCollection,
  create,
  addProducts,
  removeProduct,
  removeProducts,
  reorder,
  getCollectionsByProductId,
  setOrder,
  setStoreCollections,
  removeCollection,
};
