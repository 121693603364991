import { createAction, createReducer } from 'redux-act';

import { manageCollectionViewActions } from '../manage-collection-view';

import { FiltersState, AvailabilityFilter } from './types';

const initialState: FiltersState = {
  query: '',
  availability: [],
};

export const changeFiltersAction = createAction<{ query?: string; availability?: AvailabilityFilter[] }>(
  'INSERT_PRODUCTS_MANAGE_COLLECTION--CHANGE_FILTERS'
);

export const filtersReducer = createReducer<FiltersState>(
  {},
  {
    query: '',
    availability: [],
  }
);

filtersReducer.on(changeFiltersAction, (state, payload) => ({
  ...state,
  ...payload,
}));

filtersReducer.on(manageCollectionViewActions.registerClosedView, () => initialState);
