import { get } from 'services/api/base-portal-api';

import { MerchantBestSellersResponse } from 'types/merchant-best-sellers';

export function getPosMerchantBestSellers(request: {
  id: string;
  count: number;
}): Promise<MerchantBestSellersResponse> {
  return get(`v1/pos/merchants/${request.id}/best-sellers?size=${request.count}`).json();
}
