import clsx from 'clsx';
import React, { FC } from 'react';

import animations from '../../../assets/stylesheets/animations.module.scss';

import styles from './styles.module.scss';

export type SpinnerSize = '50px' | '24px' | '12px';
export type FadeInType = 'instant' | 'delayed';

type Props = {
  fadeIn?: FadeInType;
  overlay?: boolean;
  className?: string;
  spinnerClassName?: string;
  size?: SpinnerSize;
  inline?: boolean;
};

export const SimpleSpinner: FC<Props> = ({
  fadeIn,
  className,
  spinnerClassName,
  overlay,
  inline,
  size = '50px',
}: Props) => {
  return (
    <div
      className={clsx(
        styles.container,
        {
          [styles.containerDelayedFadeIn]: fadeIn === 'delayed',
          [styles.containerFadeIn]: fadeIn === 'instant',
          [styles.inline]: inline,
          [styles.size12px]: size === '12px',
          [styles.size24px]: size === '24px',
        },
        className
      )}
    >
      {overlay && <div className={styles.overlay} />}
      <div className={clsx(styles.spinner, animations.rotateAnimation, spinnerClassName)} />
    </div>
  );
};
