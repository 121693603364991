import { safeTakeLatest } from '@frontend/commons';

import { createDefaultMultiAsyncHelpers } from 'store/helpers/default-multi-async-helpers';

import { getPosMerchant } from './service';

const {
  actions,
  reducer,
  saga: fetchAsyncSaga,
} = createDefaultMultiAsyncHelpers('POS_CAMPAIGN_PROPOSAL_MERCHANT__FETCH', getPosMerchant);

const fetchPosMerchantAction = actions.request;

export { reducer, fetchPosMerchantAction };

export function* saga() {
  yield safeTakeLatest(fetchPosMerchantAction, fetchAsyncSaga);
}
