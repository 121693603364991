import { BrandsAPI } from 'services/api/brands';
import { CategoriesAPI } from 'services/api/categories';
import { TaxRatesAPI } from 'services/api/tax-rates';

export async function fetchAllRelatedResources({ market, marketId }: { market: string; marketId: string }) {
  const [brands, taxRatesForCategories, categories, categoryProperties] = await Promise.all([
    BrandsAPI.fetchBrands({ market }),
    TaxRatesAPI.getForCategories(marketId),
    CategoriesAPI.index(),
    CategoriesAPI.properties(marketId),
  ]);
  return {
    brands,
    taxRatesForCategories,
    categories,
    categoryProperties,
  };
}
