import { safeTakeLatest } from '@frontend/commons';
import { Action, createAction } from 'redux-act';

import { call, put } from 'redux-saga/effects';

import { CollectionsAPI } from 'services/api/collections';

type ActionPayload = Parameters<typeof CollectionsAPI.setOrder>[0];

const actions = {
  request: createAction<ActionPayload>(),
  success: createAction<ActionPayload>(),
  failure: createAction<void>(),
};

function* asyncRequestSaga(action: Action<ActionPayload>) {
  try {
    yield call(CollectionsAPI.setOrder, action.payload);
    yield put(actions.success(action.payload));
  } catch (e: any) {
    yield put(actions.failure(e));
    throw e;
  }
}

function* localSaga() {
  yield safeTakeLatest(actions.request, asyncRequestSaga);
}

export const sharedCollectionPersistOrder = {
  reorderAndPersistAction: actions.request,
  reorderAndPersistSuccessAction: actions.success,
  saga: localSaga,
};
