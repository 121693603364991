import { CategoriesAPI } from 'services/api/categories';
import { Category } from 'services/api/categories/types';
import { getMerchant } from 'services/api/merchants/get-merchant';
import { updateLogo } from 'services/api/merchants/logo/update-logo';
import { MerchantSettingsUpdateRequest } from 'services/api/merchants/settings/types';
import { updateMerchantSettings } from 'services/api/merchants/settings/udpate-settings';
import { MerchantsResponse } from 'services/api/merchants/types';

export async function fetchDataForMerchantSetting(): Promise<{ categories: Category[]; merchant: MerchantsResponse }> {
  const [categoriesResponse, merchant] = await Promise.all([CategoriesAPI.index(), getMerchant()]);

  return {
    categories: categoriesResponse.categories,
    merchant,
  };
}

export async function updateServiceSettings(
  newSettings: MerchantSettingsUpdateRequest
): Promise<{ merchant: MerchantsResponse }> {
  const { uploadImageInstruction, ...restOfNewSettings } = newSettings;

  if (uploadImageInstruction?.type === 'new') {
    const { Name } = await updateLogo(uploadImageInstruction.data);
    restOfNewSettings.logoImageName = Name;
  }

  if (uploadImageInstruction?.type === 'clear') {
    restOfNewSettings.logoImageName = undefined;
  }

  if (uploadImageInstruction?.type === 'keep') {
    restOfNewSettings.logoImageName = uploadImageInstruction.name;
  }

  await updateMerchantSettings(restOfNewSettings);

  const newMerchantSettings = await getMerchant();
  return { merchant: newMerchantSettings };
}
