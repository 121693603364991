import { useEffect } from 'react';

import { MerchantProfile, PosProfile } from 'services/api/user/profile/types-get-user-profiles';
import { useActionWithDispatch, useSelector } from 'store';

import { requestAction, tokenOutOfSyncModalClosedAction } from './duck';
import { tokenOfOutSyncDataSelector } from './selectors';

export function useEffectFetchLocalState() {
  const dispatchModalClosedAction = useActionWithDispatch(tokenOutOfSyncModalClosedAction);
  const dispatchRequestAction = useActionWithDispatch(requestAction);

  useEffect(() => {
    dispatchRequestAction(undefined);

    return () => {
      dispatchModalClosedAction();
    };
  }, [dispatchRequestAction, dispatchModalClosedAction]);
}

export function useMerchantOrPosDataFromLocalState(
  posId?: string,
  merchantId?: string
): { pos?: PosProfile; merchant?: MerchantProfile; pending: boolean } {
  const localState = useSelector(tokenOfOutSyncDataSelector);

  if (!localState.data || (posId == null && merchantId == null)) {
    return {
      pending: localState.pending,
      pos: undefined,
      merchant: undefined,
    };
  }

  if (posId) {
    const pos = localState.data.posProfiles.find((p) => p.posId === posId);

    return {
      pending: false,
      pos,
      merchant: undefined,
    };
  }

  const merchant = localState.data.merchantProfiles.find((m) => m.id === merchantId);

  return {
    pending: false,
    pos: undefined,
    merchant,
  };
}
