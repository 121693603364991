import { errorModalRegistry } from '@frontend/commons';

import { getSessionRelatedModal, isSessionRelatedModal } from './is-session-related-error';

export function initializeLocalErrorModals() {
  errorModalRegistry.push({
    handlesErrorNicely: true,
    errorModalComponentFactory: getSessionRelatedModal,
    shouldOpenModal: isSessionRelatedModal,
  });
}
