import { searchMerchants as globalSearchMerchants } from 'services/api/pos/merchants/search-merchants';
import { PosSearchMerchantsRequest } from 'services/api/pos/merchants/types-search-merchants';

export async function searchMerchants({
  categoryIds,
  sortField,
  pageSize,
  pageIndex,
}: {
  categoryIds: string[];
  sortField: PosSearchMerchantsRequest['sortField'];
  pageIndex: number;
  pageSize: number;
}) {
  const result = await globalSearchMerchants({
    sortField,
    categoryIds,
    pageSize,
    pageIndex,
  });

  return {
    ...result,
    requestedPage: pageIndex,
  };
}
