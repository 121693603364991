import React, { FC } from 'react';

import { PageTitle } from 'components/atoms/page-title';
import { Text } from 'components/atoms/text';

import styles from './content-404-styles.module.scss';

export const Content404: FC = () => {
  return (
    <>
      <PageTitle>404 - looks like it&apos;s not there</PageTitle>
      <Text type="primary" as="div">
        You have requested <pre className={styles.preInline}>{window.location.pathname}</pre>, but it seems we can’t
        find it.
      </Text>
    </>
  );
};
