import { safeTakeLatest } from '@frontend/commons';
import { createAction, createReducer } from 'redux-act';

import { put, select, take } from 'redux-saga/effects';

import { SingleCollectionProductItem } from 'services/api/collections/types';

import { fetchCollectionActions, fetchMoreCollectionItemsActions } from '../collection';
import { currentCollectionSelector } from '../collection/selectors';
import { collectionFiltersSelector } from '../filters';

import { rearrangeStartAction } from './is-rearrange-on.duck';
import { saveReorderedCollectionActionSuccess } from './save-new-order.duck';
import { isRearrangeModeOnSelector } from './selectors';

const collectionItemsReferenceReducer = createReducer<SingleCollectionProductItem[]>({}, []);
const keepItemsReferenceAction = createAction<SingleCollectionProductItem[]>('MANAGE_COLLECTION--KEEP_ITEMS_REFERENCE');

collectionItemsReferenceReducer.on(keepItemsReferenceAction, (_, payload) => payload);
collectionItemsReferenceReducer.on(saveReorderedCollectionActionSuccess, () => []);

function* handleRearrangeStartSaga() {
  const appliedAvailabilityFilters = collectionFiltersSelector(yield select());
  if (appliedAvailabilityFilters.query || appliedAvailabilityFilters.availability.length) {
    yield take(fetchCollectionActions.success);
  }
  const items = currentCollectionSelector(yield select());
  yield put(keepItemsReferenceAction(items.data?.items ?? []));
}

function* updateReferenceItems() {
  const isRearrangeModeOn = isRearrangeModeOnSelector(yield select());
  if (isRearrangeModeOn) {
    const items = currentCollectionSelector(yield select());
    yield put(keepItemsReferenceAction(items.data?.items ?? []));
  }
}

function* collectionItemsReferenceSaga() {
  yield safeTakeLatest(rearrangeStartAction, handleRearrangeStartSaga);
  yield safeTakeLatest(fetchMoreCollectionItemsActions.success, updateReferenceItems);
}

export { collectionItemsReferenceReducer, collectionItemsReferenceSaga };
