import { safeTakeLatest } from '@frontend/commons';

import { setProductEditorTerms } from 'services/api/profile';

import { createDefaultAsyncHelpers } from 'store/helpers';

const {
  saga,
  reducer: setProductEditorTermsReducer,
  actions,
} = createDefaultAsyncHelpers({
  actionName: 'PROFILE__SET_PRODUCT_EDITOR_TERMS',
  fetchFunc: setProductEditorTerms,
  initialData: undefined,
});

function* setProductEditorTermsSaga() {
  yield safeTakeLatest(actions.request, saga);
}

const setProductEditorTermsReducerAction = actions.request;
const setProductEditorTermsReducerActions = actions;

export {
  setProductEditorTermsReducerAction,
  setProductEditorTermsReducerActions,
  setProductEditorTermsReducer,
  setProductEditorTermsSaga,
};
