import { createEmptyPrice, isPrice } from 'types/model/price';

import { get } from '../base';

import { SingleCollection } from './types';

export async function getById({
  collectionId,
  isPrivate,
  take,
  skip = 0,
  query,
  availability,
}: {
  collectionId: string;
  isPrivate: boolean;
  take: number;
  skip?: number;
  query?: string;
  availability?: string[];
}): Promise<SingleCollection> {
  const updateContext = isPrivate ? 'users' : 'pos';
  const collection: SingleCollection = await get(`v5/${updateContext}/collections/${collectionId}`, {
    searchParams: {
      take,
      skip,
      ...(query ? { query } : {}),
      ...(availability?.length ? { availability: availability.join(', ') } : {}),
    },
  }).json();

  const validItems = collection.items.map((x) => {
    if (!isPrice(x.product.priceIncVat)) {
      const validProduct = {
        ...x.product,
        priceIncVat: createEmptyPrice(),
      };

      return {
        ...x,
        product: validProduct,
      };
    }

    return x;
  });

  const extendedCollections = {
    ...collection,
    items: validItems,
    isPrivate,
  };

  return extendedCollections;
}
