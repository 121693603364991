import { setAuthToken, removeTempInitialToken, removeAllAuthTokens, safeTakeLatest } from '@frontend/commons';
import { replace, push } from 'connected-react-router';
import { createAction, Action, createReducer } from 'redux-act';
import { select, put, call } from 'redux-saga/effects';

import { USER_PRODUCT_EDITOR } from 'constants/USER_CLAIMS';

import { PasswordAPI } from 'services/api';
import { createDefaultActions } from 'store/helpers';
import { setProductEditorTermsReducerActions } from 'store/user-profiles/set-product-editor-terms/set-product-editor-terms-duck';

import { getPreviousLocation } from '../app/selectors';
import { cleanAction } from '../clean-action';

import { logout, logoutSoftly } from './common-actions';
import { authViaCredentialsSaga } from './sub-ducks/authenticate-via-credentials';
import {
  authorizeViaInitialTokenAction,
  authViaInitialTokenSaga,
} from './sub-ducks/authenticate-via-initial-token-duck';
import {
  authenticateViaTokenAction,
  authenticateViaTokenReducer,
  authenticateViaTokenSaga,
} from './sub-ducks/authenticate-via-token-duck';
import {
  authorizeViaProfileIdAction,
  authorizeViaProfileIdReducer,
  authViaProfileIdSaga,
} from './sub-ducks/authorize-via-profile-id';
import { authenticateAction, authenticateReducer, initState } from './sub-ducks/common';
import { fetchProfileActions, profileFetchSaga } from './sub-ducks/profile-duck';
import { State, FullyAuthenticatedUserResponse } from './types';

// Actions

const resetPasswordAction = createDefaultActions<{ email: string }, void, string>('RESET_PASSWORD');
const newPasswordAction =
  createDefaultActions<{ email: string; password: string; token: string }, void, string>('NEW_PASSWORD');

const countdownLogout = createAction<void>('COUNTDOWN_LOGOUT');

export const authActions = {
  authenticate: authenticateAction,
  authenticateViaToken: authenticateViaTokenAction,
  authorizeViaProfileId: authorizeViaProfileIdAction,
  resetPassword: resetPasswordAction,
  newPassword: newPasswordAction,
  authorizeViaInitialToken: authorizeViaInitialTokenAction,
  logout,
  logoutSoftly,
  countdownLogout,
};

// Reducer

const customReducer = createReducer<State>({});
customReducer.on(logout, () => ({
  data: initState,
  pending: false,
  initialPending: false,
  pristine: true,
}));
customReducer.on(resetPasswordAction.request, () => ({
  pending: true,
  error: undefined,
  initialPending: true,
  pristine: false,
  data: undefined,
}));
customReducer.on(resetPasswordAction.success, () => ({
  pending: false,
  initialPending: false,
  pristine: false,
  data: undefined,
}));
customReducer.on(resetPasswordAction.failure, (_state, payload) => ({
  pending: false,
  initialPending: false,
  error: payload,
  pristine: false,
  data: undefined,
}));
customReducer.on(newPasswordAction.request, () => ({
  pending: true,
  error: undefined,
  initialPending: true,
  pristine: false,
  data: undefined,
}));
customReducer.on(newPasswordAction.success, () => ({
  pending: false,
  initialPending: false,
  pristine: false,
  data: undefined,
}));
customReducer.on(newPasswordAction.failure, (_state, payload) => ({
  pending: false,
  error: payload,
  initialPending: false,
  pristine: false,
  data: undefined,
}));
customReducer.on(fetchProfileActions.request, (state) => ({
  ...state,
  pending: true,
}));
customReducer.on(fetchProfileActions.failure, (state) => ({
  ...state,
  pending: false,
}));
customReducer.on(fetchProfileActions.success, (state, payload) => {
  if (!state.data) {
    return state;
  }

  return {
    ...state,
    data: {
      ...state.data,
      profile: payload,
    },
  };
});

export const reducer = (state: State, action: any): State =>
  authorizeViaProfileIdReducer(
    authenticateViaTokenReducer(authenticateReducer(customReducer(state, action), action), action),
    action
  );

function* onLoginSaga(action: Action<FullyAuthenticatedUserResponse>) {
  yield setAuthToken(action.payload.token);
  // @ts-ignore
  const prevLocation = yield select(getPreviousLocation);
  if (prevLocation && !prevLocation.location.pathname.startsWith('/login')) {
    const searchPart = prevLocation.location.search ? prevLocation.location.search : '';
    yield put(replace(prevLocation.location.pathname + searchPart));
  }
  yield removeTempInitialToken();
}

function* onAuthorizeProfileSaga(action: Action<FullyAuthenticatedUserResponse>) {
  yield setAuthToken(action.payload.token);
}

function* onLogoutSaga() {
  removeAllAuthTokens();
  yield put(push('/login'));
  yield put(cleanAction());
}

function* onLogoutSoftlySaga() {
  removeAllAuthTokens();
  const { pathname } = window.location;

  if (pathname === '/') {
    yield put(push('/login'));
  }
  yield put(cleanAction());
}

function* resetPasswordSaga(action: Action<{ email: string }>) {
  try {
    yield call(PasswordAPI.resetPassword, action.payload);
    yield put(resetPasswordAction.success());
    yield put(push('/auth/forgot-password/success/email'));
  } catch (e: any) {
    yield put(resetPasswordAction.failure(e));
    throw e;
  }
}

function* newPasswordSaga(action: Action<{ email: string; password: string; token: string }>) {
  try {
    yield call(PasswordAPI.newPassword, action.payload);
    yield put(newPasswordAction.success());
    yield put(push('/auth/forgot-password/success/password'));
  } catch (e: any) {
    yield put(newPasswordAction.failure(e));
  }
}

export function* saga() {
  yield safeTakeLatest(authActions.authenticate.request, authViaCredentialsSaga);
  yield safeTakeLatest(authActions.authenticate.success, onLoginSaga);
  yield safeTakeLatest(authActions.authenticateViaToken.request, authenticateViaTokenSaga);
  yield safeTakeLatest(authActions.authenticateViaToken.success, onLoginSaga);
  yield safeTakeLatest(authActions.authorizeViaProfileId.request, authViaProfileIdSaga);
  yield safeTakeLatest(authActions.authorizeViaProfileId.success, onAuthorizeProfileSaga);
  yield safeTakeLatest(authActions.resetPassword.request, resetPasswordSaga);
  yield safeTakeLatest(authActions.newPassword.request, newPasswordSaga);
  yield safeTakeLatest(authActions.authorizeViaInitialToken, authViaInitialTokenSaga);
  yield safeTakeLatest(fetchProfileActions.request, profileFetchSaga);
  yield safeTakeLatest(logout, onLogoutSaga);
  yield safeTakeLatest(logoutSoftly, onLogoutSoftlySaga);
}

customReducer.on(setProductEditorTermsReducerActions.success, (state) => {
  return assignProductEditorClaim(state);
});

function assignProductEditorClaim(state: State) {
  if (!state.data) {
    return state;
  }

  return {
    ...state,
    data: {
      ...state.data,
      profile: {
        ...state?.data.profile,
        claims: [...state.data.profile.claims, USER_PRODUCT_EDITOR],
      },
    },
  };
}
