import React, { FC, useMemo } from 'react';

import { withModal } from '../with-modal';

import { ModalTemplateProps, ModalTemplate } from './modal-template';

type ConfirmationModalProps = ModalTemplateProps & {
  title: string;
  onReject: () => void;
};

export const ConfirmationModal: FC<ConfirmationModalProps> = ({ title, ...rest }) => {
  const ModalComponent = useMemo(() => withModal(ModalTemplate, <h1>{title}</h1>), [title]);
  return <ModalComponent onClose={rest.onReject} opened size="medium" {...rest} />;
};
