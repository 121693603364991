import { getAuthToken, SimpleSpinner as Spinner } from '@frontend/commons';
import React, { FC } from 'react';

import { Redirect, Route, RouteProps } from 'react-router';

import { DEFAULT_ROUTES } from 'constants/DEFAULT_ROUTES';
import { userTypeSelector } from 'store/auth';
import { useSelector } from 'store/helpers';

type UserTypes = 'unauthenticated' | 'user' | 'pos' | 'merchant';
type ValidFor = UserTypes | UserTypes[];

export type Props = {
  validFor: ValidFor;
} & RouteProps;

export const ProtectedRoute: FC<Props> = (props: Props) => {
  const { validFor = 'user', ...routeProps } = props;

  const validForArray = Array.isArray(validFor) ? validFor : [validFor];
  const userType = useSelector(userTypeSelector);
  const authToken = getAuthToken();

  if (authToken && userType === 'unauthenticated') {
    return <Spinner fadeIn="delayed" />;
  }

  if (!validForArray.includes(userType)) {
    return <Redirect to={DEFAULT_ROUTES[userType]} />;
  }

  return <Route {...routeProps} />;
};
