import { combineReducers } from 'redux';

import { cancelReorderSaga } from './cancel-reorder.duck';
import { isRearrangeOnReducer } from './is-rearrange-on.duck';
import { collectionItemsReferenceReducer, collectionItemsReferenceSaga } from './items-reference.duck';
import { saveReorderedCollectionReducer, saveReorderedCollectionItemsSaga } from './save-new-order.duck';
import { RearrangeStateType } from './types';

export function* rearrangeSaga() {
  yield* saveReorderedCollectionItemsSaga();
  yield* collectionItemsReferenceSaga();
  yield* cancelReorderSaga();
}

export const rearrangeReducer = combineReducers<RearrangeStateType>({
  isRearrangeOn: isRearrangeOnReducer,
  itemsReference: collectionItemsReferenceReducer,
  saveNewOrder: saveReorderedCollectionReducer,
});
