import { LINK_WITH_PROTOCOL_REGEX } from 'constants/VALIDATION_PATTERNS';

export const getTipserOriginUrl = (): string => {
  return process.env.REACT_APP_TIPSER_ORIGIN_URL as string;
};

export const getTipserOriginAuthUrl = (): string => {
  return `${getTipserOriginUrl()}/auth/login`;
};

export function removeTrailingSlash(url: string): string {
  if (url[url.length - 1] === '/') {
    return url.slice(0, url.length - 1);
  }
  return url;
}

export function generateUrlFromString(probablyUrl?: string): string {
  if (!probablyUrl) {
    return '';
  }

  const isUrl = LINK_WITH_PROTOCOL_REGEX.test(probablyUrl);

  if (isUrl) {
    return probablyUrl;
  }

  // Remove leading slashes
  const preparedUrl = probablyUrl.replace(/^\/+/, '');
  return `https://${preparedUrl}`;
}
