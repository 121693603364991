import { get } from '../base';

import { CollectionAvailability } from './types';

export function createGetCollectionProductsAvailabilityApiHandler(
  collectionOwner: 'pos' | 'users'
): (collectionIds: string[]) => Promise<CollectionAvailability[]> {
  return (collectionIds: string[]) =>
    get(`v5/${collectionOwner}/collections/productsAvailability`, {
      searchParams: { collectionIds: collectionIds.join(',') },
    })
      .json<{ availabilityInformation: CollectionAvailability[] }>()
      .then((x) => x.availabilityInformation);
}
