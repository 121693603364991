import { safeTakeLatest } from '@frontend/commons';

import { createDefaultMultiAsyncHelpers } from 'store/helpers/default-multi-async-helpers';

import { getCampaignDetails } from './fetch-campaign-service/fetch-pos-campaign.service';

const {
  actions,
  reducer,
  saga: fetchAsyncSaga,
} = createDefaultMultiAsyncHelpers('POS_CAMPAIGN__FETCH', getCampaignDetails);

const fetchCampaignAction = actions.request;

export { reducer, fetchCampaignAction };

export function* saga() {
  yield safeTakeLatest(fetchCampaignAction, fetchAsyncSaga);
}
