import { omit } from 'lodash';
import { createReducer, Action, ActionCreator } from 'redux-act';
import { put, call } from 'redux-saga/effects';

import { AsyncReturnType } from 'types/async-return-type';

import { createDefaultActions } from './default-async-helpers';
import { processSagaErrorHandlers } from './process-saga-error-handlers';

import { DefaultActions, DefaultError, DefaultMultiAsyncState, SagaCustomErrorHandler } from './types';

type DefaultMultiRequestPayload = {
  id: string;
};

type DefaultSuccessMultiPayload<T = any> = {
  id: string;
  data: T;
};

type DefaultFailureMultiPayload<T = any> = {
  id: string;
  error: T;
};

export const createDefaultMultiSaga = <RequestPayload, SuccessPayload, FailurePayload>(
  func: (arg: DefaultMultiRequestPayload & RequestPayload) => Promise<SuccessPayload>,
  actions: DefaultActions<
    RequestPayload,
    DefaultSuccessMultiPayload<SuccessPayload>,
    DefaultFailureMultiPayload<FailurePayload>
  >,
  sagaErrorHandlers?: SagaCustomErrorHandler<FailurePayload>[]
) =>
  function* (action: Action<DefaultMultiRequestPayload & RequestPayload>) {
    const { id } = action.payload;

    try {
      const data: SuccessPayload = yield call(func, action.payload);
      yield put(actions.success({ data, id }));
    } catch (e: any) {
      if (sagaErrorHandlers) {
        const { isHandled, failurePayload }: AsyncReturnType<typeof processSagaErrorHandlers> =
          yield processSagaErrorHandlers(e, sagaErrorHandlers);
        if (isHandled) {
          yield put(actions.failure({ error: failurePayload, id }));
          return;
        }
      }

      yield put(actions.failure({ error: e, id }));
      throw e;
    }
  };

export const getInitialState = <SuccessPayload, FailurePayload>(): DefaultMultiAsyncState<
  SuccessPayload,
  FailurePayload
> => ({
  data: {},
  pending: {},
  error: {},
});

export const contextSuccessStateUpdate = <SuccessPayload, FailurePayload>(
  state: DefaultMultiAsyncState<SuccessPayload, FailurePayload>,
  data: SuccessPayload,
  updatedKey: string
): DefaultMultiAsyncState<SuccessPayload, FailurePayload> => {
  const newErrors = omit(state.error, updatedKey);

  return {
    ...state,
    pending: {
      ...state.pending,
      [updatedKey]: false,
    },
    data: {
      ...state.data,
      [updatedKey]: data,
    },
    error: newErrors,
  };
};

export const contextPendingStateUpdate = <SuccessPayload, FailurePayload>(
  state: DefaultMultiAsyncState<SuccessPayload, FailurePayload>,
  isPending: boolean,
  updatedKey: string
): DefaultMultiAsyncState<SuccessPayload, FailurePayload> => {
  return {
    ...state,
    pending: {
      ...state.pending,
      [updatedKey]: isPending,
    },
  };
};

export const contextFailureStateUpdate = <SuccessPayload, FailurePayload>(
  state: DefaultMultiAsyncState<SuccessPayload, FailurePayload>,
  error: FailurePayload,
  updatedKey: string
): DefaultMultiAsyncState<SuccessPayload, FailurePayload> => {
  return {
    ...state,
    pending: {
      ...state.pending,
      [updatedKey]: false,
    },
    error: {
      ...state.error,
      [updatedKey]: error,
    },
  };
};

export const createDefaultMultiReducer = <
  RequestPayload extends { id: string },
  SuccessPayload,
  FailurePayload = undefined
>(
  actions: DefaultActions<
    RequestPayload,
    DefaultSuccessMultiPayload<SuccessPayload>,
    DefaultFailureMultiPayload<FailurePayload>
  >,
  cleanAction?: ActionCreator<any>
) =>
  createReducer<DefaultMultiAsyncState<SuccessPayload, FailurePayload>>(
    {
      [actions.request.toString()]: (state, payload) => contextPendingStateUpdate(state, true, payload.id),
      [actions.success.toString()]: (state, payload) => contextSuccessStateUpdate(state, payload.data, payload.id),
      [actions.failure.toString()]: (state, payload) => contextFailureStateUpdate(state, payload.error, payload.id),
      ...(cleanAction
        ? {
            [cleanAction.toString()]: () => getInitialState(),
          }
        : {}),
    },
    getInitialState()
  );

export function createDefaultMultiAsyncHelpers<
  RequestPayload extends { id: string },
  SuccessPayload,
  FailurePayload = DefaultError
>(
  actionName: string,
  fetchFunc: (payload: RequestPayload) => Promise<SuccessPayload>,
  sagaErrorHandlers?: SagaCustomErrorHandler<FailurePayload>[]
) {
  const actions =
    createDefaultActions<
      RequestPayload,
      DefaultSuccessMultiPayload<SuccessPayload>,
      DefaultFailureMultiPayload<FailurePayload>
    >(actionName);

  const reducer = createDefaultMultiReducer<RequestPayload, SuccessPayload, FailurePayload>(actions);

  const saga = createDefaultMultiSaga(fetchFunc, actions, sagaErrorHandlers);

  return {
    actions,
    reducer,
    saga,
  };
}
