import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { history } from 'routes/history';

import { rootReducer } from './root-reducer';
import { rootSaga } from './root-saga';

/* eslint-disable no-underscore-dangle */
const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 100 })) ||
  compose;
/* eslint-enable no-underscore-dangle */
const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
);

sagaMiddleware.run(rootSaga);
