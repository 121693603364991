import { safeTakeLatest } from '@frontend/commons';
import { createAction } from 'redux-act';

import { put } from 'redux-saga/effects';

const cancelReorderAction = {
  start: createAction('MANAGE_COLLECTION--CANCEL_REORDER--START'),
  end: createAction('MANAGE_COLLECTION--CANCEL_REORDER--END'),
};

function* handleReorderCancellationSaga() {
  yield put(cancelReorderAction.end());
}

function* cancelReorderSaga() {
  yield safeTakeLatest(cancelReorderAction.start, handleReorderCancellationSaga);
}

export { cancelReorderAction, cancelReorderSaga };
