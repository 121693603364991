import { DEFAULT_LIST_PAGE_SIZE } from 'constants/PAGINATION';

import { getPrivateCollections } from './get-private';
import { getSharedCollections } from './get-shared';
import { getStoreCollections } from './get-store';
import { Collection, CollectionResponse } from './types';

const emptyPartialCollectionResponse = {
  pageIndex: 0,
  pageSize: DEFAULT_LIST_PAGE_SIZE,
  totalCount: 0,
};

export async function getAllCollections(posId: string) {
  const [privateCollectionsResponse, sharedCollectionsResponse, storeCollections] = await Promise.all<
    CollectionResponse,
    CollectionResponse,
    { collections: Collection[] }
  >([getPrivateCollections(), getSharedCollections(), getStoreCollections({ posId })]);

  const remainingPrivateCollections = await fetchRemainingCollections(
    privateCollectionsResponse,
    getPrivateCollections
  );
  const remainingSharedCollections = await fetchRemainingCollections(sharedCollectionsResponse, getSharedCollections);

  return {
    private: {
      collections: [...privateCollectionsResponse.collections, ...remainingPrivateCollections],
      ...emptyPartialCollectionResponse,
    },
    shared: {
      collections: [...sharedCollectionsResponse.collections, ...remainingSharedCollections].sort(
        (x, y) => x.zIndex - y.zIndex
      ),
      ...emptyPartialCollectionResponse,
    },
    store: storeCollections,
  };
}

const fetchRemainingCollections = async (
  collectionResponse: CollectionResponse,
  fetchingFunction: typeof getPrivateCollections | typeof getSharedCollections
): Promise<Collection[]> => {
  if (collectionResponse.totalCount <= collectionResponse.pageSize) {
    return [];
  }

  const maxPagesNeeded = Math.ceil(collectionResponse.totalCount / collectionResponse.pageSize);
  const allPagesArr = [...Array(maxPagesNeeded).keys()].filter((pageNumber) => pageNumber !== 0);
  const requestsArr = allPagesArr.map((pageIndex) => fetchingFunction({ pageIndex }));
  const results = await Promise.all(requestsArr);

  return results.flatMap((remainingResponse) => remainingResponse.collections);
};
