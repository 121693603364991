import { createSelector } from 'reselect';

import { GlobalState } from 'store/types';

export const categoriesSelector = (state: GlobalState) =>
  state.pages.productFormResources.data?.categories?.categories || [];

export const categoryTaxRatesSelector = (state: GlobalState) =>
  state.pages.productFormResources.data?.taxRatesForCategories.categoryTaxRates || [];

export const departmentsSelector = createSelector(categoriesSelector, (categories) =>
  categories.filter((x) => x.parentId == null).sort((dep1, dep2) => dep1.name.localeCompare(dep2.name))
);

export const fashionDepartmentsSelector = createSelector(categoriesSelector, (categories) =>
  categories.find((category) => category.name.startsWith('Fashion'))
);

export const categoryPropertySelector = (state: GlobalState) =>
  state.pages.productFormResources.data?.categoryProperties?.categoryProperties || [];

export const brandItemsSelector = (state: GlobalState) => state.pages.productFormResources.data?.brands?.items || [];
