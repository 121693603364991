let localErrorHandler: (error: any) => { handledNicely?: boolean };

export function registerLocalOnErrorHandler(callback: typeof localErrorHandler) {
  localErrorHandler = callback;
}

export function removeLocalOnErrorHandler(callback: typeof localErrorHandler) {
  if (localErrorHandler === callback) {
    // @ts-ignore
    localErrorHandler = undefined;
  }
}
export function errorModalErrorHandler(error: any): { handledNicely?: boolean } {
  return localErrorHandler?.(error) ?? {};
}
