import { GlobalState } from 'store/types';

export const detailedProductsSelector = (state: GlobalState) => state.pages.insertProducts.detailedProducts.data;

export const isPendingSelector = (state: GlobalState) => state.pages.insertProducts.detailedProducts.pending;

export const tagsFromPosProifleSelector = (state: GlobalState) =>
  state.auth.data?.posProfile?.settings.posProductTagsSettings || [];

export const categoryPropertiesSelector = (state: GlobalState) =>
  state.pages.insertProducts.categoryProperties.data?.categoryProperties;

export const detailedProductsErrorSelector = (state: GlobalState) => state.pages.insertProducts.detailedProducts.error;
