import { ClassConstructor, instanceToPlain, plainToInstance } from 'class-transformer';
import { stringify } from 'query-string';

import 'reflect-metadata';
import { QUERY_STRING_STRINGIFY_CONFIG } from 'constants/QUERY_STRING_CONFIG';

export const removeExtraneousProperties = <TFilterClass>(
  FilterClassConstructor: ClassConstructor<TFilterClass>,
  object: Record<string, unknown>
): Record<string, unknown> => {
  const emptyObject = plainToInstance(FilterClassConstructor, {});
  const allowedKeys = Object.keys(emptyObject);
  const allowedEntries = Object.entries(object).filter(([key]) => allowedKeys.includes(key));
  return Object.fromEntries(allowedEntries);
};

export const generateQueryParams = <TFilterClass>(
  FilterClassConstructor: ClassConstructor<TFilterClass>,
  newFiltersPlain: Record<string, unknown>
) => {
  const serializablePlainObject = instanceToPlain(plainToInstance(FilterClassConstructor, newFiltersPlain)); // make sure object's values gets converted to serializable format
  const newFiltersWithRemovedExtraneousValues = removeExtraneousProperties(
    FilterClassConstructor,
    serializablePlainObject
  );

  return stringify(newFiltersWithRemovedExtraneousValues, QUERY_STRING_STRINGIFY_CONFIG);
};

export const generateQueryObject = <TFilterClass>(
  FilterClassConstructor: ClassConstructor<TFilterClass>,
  queryParams: Record<string, unknown>
) => {
  const newFiltersWithRemovedExtraneousValues = removeExtraneousProperties(FilterClassConstructor, queryParams);

  return plainToInstance(FilterClassConstructor, newFiltersWithRemovedExtraneousValues);
};
