import { GlobalState } from 'store/types';

export const collectionsProductsAvailabilityDataSelector = (state: GlobalState) =>
  state.collectionsProductsAvailability.data;

export const collectionsProductsAvailabilitySelector = (state: GlobalState) => state.collectionsProductsAvailability;

export const collectionsProductsAvailabilityPendingSelector = (state: GlobalState) =>
  state.collectionsProductsAvailability.pending;

export const collectionsProductsAvailabilityInitialPendingSelector = (state: GlobalState) =>
  state.collectionsProductsAvailability.initialPending;
