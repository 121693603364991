import { ClassConstructor } from 'class-transformer';
import { parse } from 'query-string';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { QUERY_STRING_PARSE_CONFIG } from 'constants/QUERY_STRING_CONFIG';

import { generateQueryObject, generateQueryParams } from './helpers';

const SESSION_STORAGE_QUERY_PREFIX = 'QUERY_PARAMS';

export function useFiltersSyncedWithQueryParams<TFilterClass>(
  FilterClassConstructor: ClassConstructor<TFilterClass>,
  defaultFilterState?: Partial<TFilterClass>,
  options: { sessionStorage?: true } = { sessionStorage: true }
): [TFilterClass, (filters: Record<string, unknown>) => void] {
  const { search, pathname, state } = useLocation();
  const { replace } = useHistory();
  const persistedQueryParams = usePersistedQueryParams();

  const availableSearch = search || (options.sessionStorage && persistedQueryParams);
  const filters = useMemo(() => {
    const newFiltersAsPlainObject = availableSearch
      ? parse(availableSearch, QUERY_STRING_PARSE_CONFIG)
      : defaultFilterState ?? {};
    return generateQueryObject(FilterClassConstructor, newFiltersAsPlainObject);
  }, [FilterClassConstructor, defaultFilterState, availableSearch]);

  const updateFilters = useCallback(
    (newFiltersPlain: Record<string, unknown>) => {
      const queryParams = generateQueryParams(FilterClassConstructor, newFiltersPlain);
      replace(`${pathname}?${queryParams}`, state);
    },
    [FilterClassConstructor, pathname, replace, state]
  );

  return [filters, updateFilters];
}

export const usePersistedQueryParams = () => {
  const { pathname, search } = useLocation();
  const key = `${SESSION_STORAGE_QUERY_PREFIX}:${pathname}`;
  useEffect(() => {
    sessionStorage.setItem(key, search);
  }, [pathname, search, key]);

  return sessionStorage.getItem(key);
};
