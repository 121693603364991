import { safeTakeLatest } from '@frontend/commons';

import { CommissionsPayload } from 'services/api/common-types/types-commissions';

import { cleanAction } from 'store/clean-action';
import { createDefaultActions, createDefaultReducer, createDefaultSaga } from 'store/helpers';

import { fetchAllCommissionsData } from './api-services';
import { SalesData } from './types';

export const fetchCommissionsTable = createDefaultActions<CommissionsPayload, SalesData>(
  'MERCHANT-SALES__FETCH_COMMISSIONS'
);

export const reducer = createDefaultReducer<CommissionsPayload, SalesData | undefined>(
  fetchCommissionsTable,
  undefined,
  cleanAction
);

export const fetchCommissionsTableSaga = createDefaultSaga<CommissionsPayload, SalesData>(
  fetchAllCommissionsData,
  fetchCommissionsTable
);

export function* saga() {
  yield safeTakeLatest(fetchCommissionsTable.request, fetchCommissionsTableSaga);
}
