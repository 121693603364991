import { safeTakeLatest } from '@frontend/commons';
import { Action, createReducer } from 'redux-act';

import { call, put, select } from 'redux-saga/effects';

import { PosProductsAPI } from 'services/api';
import { Category } from 'services/api/categories/types';
import { ProductSingleDetailed } from 'services/api/pos/products/types-get-one-product';
import { MetadataPayload } from 'services/api/pos/products/types-update-metadata';
import { categoryTreeDataSelector } from 'store/category-tree/selectors';
import { createDefaultActions } from 'store/helpers';
import { SimpleLoadingAsyncState } from 'store/helpers/types';

import { detailedProductsSelector } from '../../product-details/selectors';

const productDetailsUpdateMetadataAction = createDefaultActions<
  { productId: string; payload: MetadataPayload },
  { updatedProduct: ProductSingleDetailed }
>('PRODUCT_DETAILS__UPDATE_METADATA');

const metadataReducer = createReducer<SimpleLoadingAsyncState>(
  {
    [productDetailsUpdateMetadataAction.request.getType()]: () => ({
      error: undefined,
      pending: true,
    }),
    [productDetailsUpdateMetadataAction.success.getType()]: () => ({
      pending: false,
    }),
    [productDetailsUpdateMetadataAction.failure.getType()]: (_, payload) => ({
      pending: false,
      error: payload,
    }),
  },
  {
    pending: false,
    error: undefined,
  }
);

function* productDetailsUpdateMetadataSaga(action: Action<{ productId: string; payload: MetadataPayload }>) {
  const { productId, payload } = action.payload;
  const categories = categoryTreeDataSelector(yield select());
  const products = detailedProductsSelector(yield select());
  const newCategory = categories?.categories.find((category: Category) => category.id === payload.categoryId);

  const currentProduct = products?.find((product: ProductSingleDetailed) => product.id === productId);
  if (!currentProduct || !newCategory) {
    return;
  }

  const updatedProduct = {
    ...currentProduct,
    category: { name: newCategory.name, id: newCategory.id },
    variants: currentProduct.variants.map((variant: ProductSingleDetailed['variants'][number]) => ({
      ...variant,
      metadata: payload.metadata,
    })),
  };

  try {
    yield call(PosProductsAPI.updateMetadata, action.payload);
    yield put(productDetailsUpdateMetadataAction.success({ updatedProduct }));
  } catch (e) {
    yield put(productDetailsUpdateMetadataAction.failure(e));
    throw e;
  }
}

function* metadataUpdateSaga() {
  yield safeTakeLatest(productDetailsUpdateMetadataAction.request, productDetailsUpdateMetadataSaga);
}

export { productDetailsUpdateMetadataAction, metadataReducer, metadataUpdateSaga };
