import { safeTakeLatest } from '@frontend/commons';

import { BrandsAPI } from 'services/api/brands';
import { cleanAction } from 'store/clean-action';
import { createDefaultAsyncHelpers } from 'store/helpers';

const {
  reducer,
  saga: fetchBrandsSaga,
  actions: fetchBrands,
} = createDefaultAsyncHelpers({
  actionName: 'BRANDS__FETCH_BRANDS',
  fetchFunc: BrandsAPI.fetchBrands,
  cleanAction,
  initialData: undefined,
});

export { reducer, fetchBrands };

export function* saga() {
  yield safeTakeLatest(fetchBrands.request, fetchBrandsSaga);
}
