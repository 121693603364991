import { PreviewCampaignProductsResponse } from 'services/api/common-types/types-campaign-product-preview';
import { MerchantCampaignsAPI } from 'services/api/merchants/campaigns';
import { getMerchantSingleCampaign } from 'services/api/merchants/campaigns/get-single-campaign';
import { getMerchantSingleCampaignsProposal } from 'services/api/merchants/campaigns/get-single-campaign-proposals';
import { getMerchantSingleCampaignSummary } from 'services/api/merchants/campaigns/get-single-campaigns-summary';
import { GetSingleCampaignRequest } from 'services/api/merchants/campaigns/types-get-single-campaign';

import { CampaignDetails } from './types';

export async function getCampaignDetails(request: GetSingleCampaignRequest): Promise<CampaignDetails> {
  let summary;
  const [campaign, proposal] = await Promise.all([
    getMerchantSingleCampaign(request),
    getMerchantSingleCampaignsProposal(request).catch(() => undefined),
  ]);

  if (campaign?.status === 'Completed') {
    summary = await getMerchantSingleCampaignSummary(request);
  }

  if (!campaign.commission?.totalCommission || campaign.campaignConditions.productIds.length < 1) {
    return {
      campaign,
      summary,
      proposal,
      products: [],
    };
  }

  const productsPreview: PreviewCampaignProductsResponse = await MerchantCampaignsAPI.fetchCampaignProductsPreview({
    discountType: campaign.actions.discount.discountType,
    discountValue: campaign.actions.discount.discountValue,
    productIds: campaign.campaignConditions.productIds,
    totalCommission: campaign.commission?.totalCommission,
  });

  return {
    campaign,
    proposal,
    products: productsPreview.products,
    summary,
  };
}
