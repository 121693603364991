import { StringFilter } from 'types/filters';

import { UNCATEGORIZED } from '../../../../constants';

export function stringFiltersToMultiselectOptions(filters: StringFilter[]) {
  return filters.map(stringFilterToMultiselectOption);
}

function stringFilterToMultiselectOption(filter: StringFilter) {
  return {
    value: filter.id,
    label: filter.value,
    searchableLabel: filter.value,
    count: filter.count,
    id: filter.id,
  };
}

export const categorySort = (filter1: any, filter2: any) => {
  if (filter1.label === UNCATEGORIZED) {
    return 1;
  }
  if (filter2.label === UNCATEGORIZED) {
    return -1;
  }

  return filter1.label.localeCompare(filter2.label);
};
