import { getPortalApiUrl, getAuthToken } from '@frontend/commons';
import ky from 'ky';

const apiClient = ky.extend({
  prefixUrl: getPortalApiUrl(),
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
  },
  timeout: 60_000,
  hooks: {
    beforeRequest: [
      (request) => {
        const authToken = getAuthToken();
        if (authToken && !request.headers.get('authorization')) {
          request.headers.set('authorization', `Bearer ${authToken}`);
        }
      },
    ],
  },
});

const { get, post, put, delete: destroy, patch } = apiClient;
export { get, post, put, destroy, patch };
