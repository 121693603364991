import { get } from '../base';

import { getStoreCollections } from './get-store';
import { Collection, OldCollection } from './types';

export async function byProfileName(profileName: string, posId: string): Promise<OldCollection[]> {
  const [allCollections, storeDataCollections] = await Promise.all<any, any>([
    get(`v3/users/${profileName}/collections`).json(),
    getStoreCollections({ posId }),
  ]);

  const storeCollections: Collection[] = storeDataCollections.collections;
  return allCollections.map((collection: Collection) => {
    const isStoreCollection = !!storeCollections.find((x) => x.id === collection.id);
    return {
      ...collection,
      isStoreCollection,
    };
  });
}
