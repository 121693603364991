import { PAGE_SIZE } from 'constants/PAGINATION';
import { searchMerchants as searchMerchantsApi } from 'services/api/pos/merchants/search-merchants';

export const searchMerchants = async ({ searchTerm, pageIndex }: { searchTerm: string; pageIndex: number }) => {
  const result = await searchMerchantsApi({
    query: searchTerm,
    categoryIds: [],
    sortField: { name: 'Name', direction: 'Ascending' },
    pageSize: PAGE_SIZE,
    pageIndex,
  });

  return {
    ...result,
    requestedPage: pageIndex,
  };
};
