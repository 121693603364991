import * as Sentry from '@sentry/react';

import { getSentryBuildId, getSentryEnv, getApiUrl } from '../helpers/env-helpers';
import { SentryError } from '../types/sentry';

let isSentryInitializedLocal = false;

export function isSentryInitialized(): boolean {
  return isSentryInitializedLocal;
}

export function initSentry(sentryDsn: string) {
  if (window.location.host.includes('localhost:')) {
    return;
  }

  const environment = getSentryEnv();
  const release = getSentryBuildId();

  Sentry.init({
    dsn: sentryDsn,
    environment,
    release,
    beforeSend: (event, hint) => {
      const newEvent = event;
      const exception = hint?.originalException as SentryError;
      const hasExceptionResponseStatus = !!exception?.response?.status;

      if (hasExceptionResponseStatus && exception.response) {
        const preparedUrl = exception.response.url.replace(getApiUrl(), '').replace(/[a-f\d]{24}/gi, '<object_id>');
        newEvent.fingerprint = ['httpError', String(exception.response.status), preparedUrl];

        if (newEvent?.exception?.values) {
          const httpErrorExceptionIndex = newEvent.exception.values.findIndex((val) => val.type === 'HTTPError');
          newEvent.exception.values[httpErrorExceptionIndex] = {
            ...newEvent.exception.values[httpErrorExceptionIndex],
            value: `${newEvent.exception.values[httpErrorExceptionIndex].value} - ${preparedUrl}`,
          };
        }
      }

      return newEvent;
    },
  });

  isSentryInitializedLocal = true;
}
