import { CurrencyCodeISO } from '../currency-iso-code';

export type Price = { value: number; formatted: string; currency: CurrencyCodeISO };

export type VatPrice = Omit<Price, 'currency'>;

export function isPrice(maybePrice: any): maybePrice is Price {
  if (maybePrice == null || typeof maybePrice !== 'object') {
    return false;
  }

  if (typeof maybePrice.value !== 'number') {
    return false;
  }

  if (typeof maybePrice.formatted !== 'string') {
    return false;
  }

  if (typeof maybePrice.currency !== 'string') {
    return false;
  }

  return true;
}

export function createEmptyPrice(): Price {
  return {
    value: 0,
    formatted: '0',
    currency: 'EUR',
  };
}
