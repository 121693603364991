import { PosProductsAPI } from 'services/api';
import { getProductCustomization } from 'services/api/product-customization';

export async function fetchProductWithoutCustomization({ productId }: { productId: string }) {
  const [productWithoutCustomization, customization] = await Promise.all([
    PosProductsAPI.getOne({ productId }),
    getProductCustomization({ productId }),
  ]);

  return {
    productWithoutCustomization,
    customization,
  };
}
