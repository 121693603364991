import React, { FC } from 'react';

import { TopBarBoltInfo } from 'components/molecules/top-bar-bolt-info';

import { MerchantActivationTopBar } from '../merchant-activation-top-bar';

export const MerchantTopBars: FC = () => {
  return (
    <div>
      <MerchantActivationTopBar />
      <TopBarBoltInfo localStorageKey="part-of-bolt-merchant-info" />
    </div>
  );
};
