import { safeTakeLatest } from '@frontend/commons';

import { cleanAction } from 'store/clean-action';
import { createDefaultAsyncHelpers } from 'store/helpers';

import { AsyncReturnType } from 'types/async-return-type';

import { fetchDataForMerchantSetting, updateServiceSettings } from './service';

const {
  reducer,
  actions: fetchMerchantActions,
  saga: fetchMerchantSaga,
} = createDefaultAsyncHelpers({
  actionName: 'FETCH_DATA_FOR_MERCHANT_SETTINGS',
  cleanAction,
  fetchFunc: fetchDataForMerchantSetting,
  initialData: {
    merchant: null,
    categories: null,
  },
});

const { saga: updateSettingsAsyncSaga, actions: updateSettingsActions } = createDefaultAsyncHelpers({
  fetchFunc: updateServiceSettings,
  actionName: 'MERCHANT_SETTINGS__UPDATE',
  initialData: {
    merchant: null,
    categories: null,
  },
});

reducer.on(updateSettingsActions.request, (state) => ({
  ...state,
  pending: true,
}));

reducer.on(updateSettingsActions.success, (state, payload) => ({
  ...state,
  pending: false,
  data: {
    ...state.data,
    ...payload,
  } as AsyncReturnType<typeof fetchDataForMerchantSetting>,
}));

reducer.on(updateSettingsActions.failure, (state, payload) => ({
  ...state,
  pending: false,
  error: payload.error,
}));

const actions = {
  fetchMerchantSettingsAction: fetchMerchantActions.request,
  fetchMerchantSettingsActionSuccess: fetchMerchantActions.success,
  updateMerchantSettingsAction: updateSettingsActions.request,
};

function* saga() {
  yield safeTakeLatest(fetchMerchantActions.request, fetchMerchantSaga);
  yield safeTakeLatest(updateSettingsActions.request, updateSettingsAsyncSaga);
}

export { saga, actions, reducer };
