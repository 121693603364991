import { safeTakeLatest } from '@frontend/commons';
import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';

import { createDefaultAsyncHelpers } from 'store/helpers';

import { removeCollection } from './remove-collection.service';

const {
  saga: removeCollectionAsyncLocalSaga,
  actions: removeCollectionActions,
  reducer: removeCollectionReducer,
} = createDefaultAsyncHelpers({
  fetchFunc: removeCollection,
  actionName: 'COLLECTION__REMOVE',
  initialData: undefined,
});

function* navigateToInsertProductsSaga() {
  yield put(push('/pos/inventory'));
}

function* removeCollectionSaga() {
  yield safeTakeLatest(removeCollectionActions.request, removeCollectionAsyncLocalSaga);
  yield safeTakeLatest(removeCollectionActions.success, navigateToInsertProductsSaga);
}

export { removeCollectionSaga, removeCollectionReducer, removeCollectionActions };
