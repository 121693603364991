import { uniqBy } from 'lodash';

import { get } from '../base';

import { BrandsPayload, BrandsResponse } from './types-get-pos-brands';

export const fetchBrands = async (params: BrandsPayload): Promise<BrandsResponse> => {
  return get('v5/brands/lookup', {
    searchParams: params,
  })
    .json<BrandsResponse>()
    .then(({ items }) => ({
      // This logic can be removed if backend stops returning duplicates...
      items: items.map((brand) => ({
        ...brand,
        values: uniqBy(brand.values, (value) => value.id),
      })),
    }));
};
