import { safeTakeLatest } from '@frontend/commons';
import { Action } from 'redux-act';

import { call, put } from 'redux-saga/effects';

import { findModifyAndReplace } from 'helpers/array';
import { updateMerchantProductStatus } from 'services/api/merchants/products';
import { getManyMerchantProducts } from 'services/api/merchants/products/get-many';
import { ProductPayloadUpdateStatus } from 'services/api/merchants/products/types-product-update-status';
import { cleanAction } from 'store/clean-action';
import { createDefaultAsyncHelpers } from 'store/helpers';

import { updateProductStatus } from './udpate-product-status-service';

const {
  reducer,
  saga: fetchAsyncSaga,
  actions: fetchActions,
} = createDefaultAsyncHelpers({
  actionName: 'PRODUCTS_FETCH',
  fetchFunc: getManyMerchantProducts,
  cleanAction,
  initialData: undefined,
});

const { actions: updateStatusActions } = createDefaultAsyncHelpers({
  actionName: 'PRODUCTS_UPDATE_STATUS',
  fetchFunc: updateProductStatus,
  initialData: undefined,
});

function* updateStatusAsyncSaga(action: Action<ProductPayloadUpdateStatus>) {
  try {
    yield call(updateMerchantProductStatus, action.payload);
    yield put(updateStatusActions.success(action.payload));
  } catch (e) {
    yield put(updateStatusActions.failure(e));
    throw e;
  }
}

reducer.on(updateStatusActions.request, (state) => ({
  ...state,
  pending: true,
}));

reducer.on(updateStatusActions.success, (state, payload) => {
  if (!state.data) {
    return state;
  }

  return {
    ...state,
    pending: false,
    data: {
      ...state.data,
      items: findModifyAndReplace(
        state.data.items,
        (item) => item.id === payload?.productId,
        (item) => ({
          ...item,
          status: payload?.body.status,
        })
      ),
    },
  };
});

const fetchProductsAction = fetchActions.request;
const updateProductsAction = updateStatusActions.request;

export { reducer, fetchProductsAction, updateProductsAction };

export function* saga() {
  yield safeTakeLatest(fetchProductsAction, fetchAsyncSaga);
  yield safeTakeLatest(updateProductsAction, updateStatusAsyncSaga);
}
