import { safeTakeLatest } from '@frontend/commons';

import { getNewMerchants } from 'services/api/pos/new-merchants';
import { cleanAction } from 'store/clean-action';
import { createDefaultAsyncHelpers } from 'store/helpers';

const {
  reducer,
  saga: newMerchantsSaga,
  actions: newMerchantsActions,
} = createDefaultAsyncHelpers({
  fetchFunc: getNewMerchants,
  cleanAction,
  actionName: 'POS_NEW_MERCHANTS__FETCH',
  initialData: undefined,
});

const actions = {
  fetchNewMerchants: newMerchantsActions.request,
};

function* saga() {
  yield safeTakeLatest(newMerchantsActions.request, newMerchantsSaga);
}

export { reducer, actions, saga };
