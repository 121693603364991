import React, { FC, useContext, useMemo } from 'react';

import { createMoneyFormatter, createNumberFormatter, MoneyFormatter, NumberFormatter } from './create-formatters';
import { useCurrentMarket } from './hooks';

const Context = React.createContext<{ moneyFormatter: MoneyFormatter; numberFormatter: NumberFormatter }>({
  moneyFormatter: () => '',
  numberFormatter: () => '',
});

export const useNumberFormatterContext = () => {
  return useContext(Context);
};

export const NumberFormatterProvider: FC = ({ children }) => {
  const market = useCurrentMarket();
  const moneyFormatter = useMemo(() => createMoneyFormatter(market), [market]);
  const numberFormatter = useMemo(() => createNumberFormatter(market), [market]);
  return <Context.Provider value={{ moneyFormatter, numberFormatter }}>{children}</Context.Provider>;
};
