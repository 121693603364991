import { store } from 'store';
import { authSelector } from 'store/auth/selectors';

import { areTokenAndAuthStateNotSynchronised } from './helpers/are-token-and-auth-state-not-synchronised';

export function areTokenAndLocalStatesOutOfSyncApiError(error: any) {
  if (error?.response?.status !== 403) {
    return false;
  }

  return !areTokenAndAuthStateNotSynchronised(authSelector(store.getState()));
}
