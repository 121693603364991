import { combineReducers } from 'redux';

import { reducer as newMerchantReducer, saga as newMerchantsSaga } from 'store/pos-new-merchants';

import { actions as analyticsActions, reducer as homeAnalyticsReducer, saga as homeAnalyticsSaga } from './analytics';
import {
  actions as newCampaignProposalsActions,
  reducer as newCampaignProposalsReducer,
  saga as newCampaignProposalsSaga,
} from './new-campaign-proposals';
import { actions as productsActions, reducer as productsReducer, saga as homeProductsSaga } from './products';
import { State } from './types';

export const actions = {
  analyticsActions,
  productsActions,
  newCampaignProposalsActions,
};

export const reducer = combineReducers<State>({
  analytics: homeAnalyticsReducer,
  newProducts: productsReducer,
  newMerchants: newMerchantReducer,
  newCampaignProposals: newCampaignProposalsReducer,
});

export function* saga() {
  yield* homeAnalyticsSaga();
  yield* homeProductsSaga();
  yield* newMerchantsSaga();
  yield* newCampaignProposalsSaga();
}
