import { SagaErrorTypeDefinition } from './types';

export const ignoreSaga404Error = async (
  e: any,
  next: () => undefined
): Promise<SagaErrorTypeDefinition | undefined> => {
  if (e?.response?.status === 404) {
    return {
      errorType: 'resource-not-exists',
    };
  }

  return next();
};
