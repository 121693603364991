import { safeTakeEvery, safeTakeLatest } from '@frontend/commons';
import { createReducer, createAction } from 'redux-act';

import { CollectionsAPI } from 'services/api/collections';
import { authActions } from 'store/auth';
import { createDefaultActions } from 'store/helpers/default-async-helpers';
import { createDefaultMultiReducer, createDefaultMultiSaga } from 'store/helpers/default-multi-async-helpers';

import { DefaultMultiError } from 'store/helpers/types';
import { ProductCollections } from 'types/model/product-collections';

import { collectionsActions } from '../collections/actions';
import { removeCollectionActions } from '../collections/remove-collection';

import { State } from './types';

type FetchProductPayload = {
  id: string;
  data: ProductCollections;
};
type FetchProductRequest = {
  id: string;
};

const defaultState = {
  data: {},
  pending: {},
  error: {},
};

const getProductCollectionsActions = createDefaultActions<FetchProductRequest, FetchProductPayload, DefaultMultiError>(
  'COLLECTION__FETCH_PRODUCT_COLLECTION'
);
const updateProductCollectionsAction = createAction<FetchProductRequest>('COLLECTION__UPDATE_PRODUCT_COLLECTION');

export const productsCollectionsActions = {
  getProductCollections: getProductCollectionsActions,
  updateProductCollections: updateProductCollectionsAction,
};

const getProductCollectionsReducer = createDefaultMultiReducer<FetchProductRequest, ProductCollections>(
  getProductCollectionsActions,
  authActions.authorizeViaProfileId.success
);

const customReducer = createReducer<typeof defaultState>({}, defaultState);

customReducer.on(updateProductCollectionsAction, (state, payload) => ({
  ...state,
  pending: {
    ...state.pending,
    [payload.id]: true,
  },
  error: {
    ...state.error,
    [payload.id]: null,
  },
}));

customReducer.on(collectionsActions.removeProductByCollectionItemId.success, (state, payload) => {
  const { collectionId } = payload[0] ?? {};
  const modifiedData = removeCollectionFromProducts(state.data, collectionId);

  return {
    ...state,
    data: modifiedData,
  };
});

customReducer.on(removeCollectionActions.success, (state, payload) => {
  const { collectionId } = payload;
  const modifiedData = removeCollectionFromProducts(state.data, collectionId);

  return {
    ...state,
    data: modifiedData,
  };
});

function removeCollectionFromProducts(data: Record<string, ProductCollections>, collectionId: string) {
  const modifiedDataEntries = Object.entries<ProductCollections>(data).map(
    ([productId, { posCollections, userCollections }]) =>
      [
        productId,
        {
          posCollections: posCollections.filter((x) => x.id !== collectionId),
          userCollections: userCollections.filter((x) => x.id !== collectionId),
        },
      ] as [string, ProductCollections]
  );

  return Object.fromEntries<ProductCollections>(modifiedDataEntries);
}

export const reducer = (state: State, action: any) =>
  getProductCollectionsReducer(customReducer(state, action), action);

const getProductCollectionsSaga = createDefaultMultiSaga(
  CollectionsAPI.getCollectionsByProductId,
  getProductCollectionsActions
);

export function* saga() {
  yield safeTakeEvery(getProductCollectionsActions.request, getProductCollectionsSaga);
  yield safeTakeLatest(updateProductCollectionsAction, getProductCollectionsSaga);
}
