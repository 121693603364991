import { createDefaultActions, createDefaultReducer } from '../../helpers';
import { AuthState, FullyAuthenticatedUserPayload } from '../types';

export const initState = undefined;

export const authenticateAction = createDefaultActions<FullyAuthenticatedUserPayload, AuthState, string>('AUTH');

export const authenticateReducer = createDefaultReducer<FullyAuthenticatedUserPayload, AuthState, undefined, string>(
  authenticateAction,
  initState
);
