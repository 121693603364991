import { createEmptyCollectionAvailability } from 'helpers/collection-availability';
import { CollectionsAPI } from 'services/api/collections';
import { CollectionAvailability } from 'services/api/collections/types';

const typeToApiMethodName = {
  private: CollectionsAPI.getPrivateCollectionsProductsAvailability,
  shared: CollectionsAPI.getSharedCollectionsProductsAvailability,
};

export async function fetchAllCollectionsProductsAvailability({
  ids,
  type,
}: {
  ids: string[];
  type: 'private' | 'shared';
}): Promise<CollectionAvailability[]> {
  if (!typeToApiMethodName[type]) {
    throw new Error(`Invalid type value of type: ${type}`);
  }

  const result = await typeToApiMethodName[type](ids.slice(0, 20));
  return addEmptyAvailabilitiesForMissingCollections(result, ids);
}

function addEmptyAvailabilitiesForMissingCollections(result: CollectionAvailability[], requestedIds: string[]) {
  const idsWithAvailabilities = new Set(result.map((x) => x.collectionId));
  const idsWithoutAvailabilities = requestedIds.filter((id) => !idsWithAvailabilities.has(id));
  const emptyAvailabilities = idsWithoutAvailabilities.map((id) => createEmptyCollectionAvailability(id));
  return result.concat(emptyAvailabilities);
}
