import { DateTimeFormatsAPI, TimezonesAPI } from 'services/api';
import { DateTimeFormatsResponse } from 'services/api/date-time-formats/types-get-date-time-formats';
import { TimeZonesResponse } from 'services/api/timezones/types-get-timezones';

export type TimezonesAndDateFormats = DateTimeFormatsResponse & TimeZonesResponse;

export async function fetchTimezonesAndDateFormats(): Promise<TimezonesAndDateFormats> {
  const [dateTimeFormatsResponse, timezonesResponse] = await Promise.all([
    DateTimeFormatsAPI.getDateTimeFormats(),
    TimezonesAPI.getTimezones(),
  ]);

  return {
    ...dateTimeFormatsResponse,
    ...timezonesResponse,
  };
}
