import { CustomizationImage } from 'services/api/product-customization/types';
import { CloudinaryImage } from 'types/model/images';

export const customizationImageConverter = {
  fromCloudinaryImage(cloudinaryImage: CloudinaryImage): CustomizationImage {
    return {
      name: cloudinaryImage.id,
      source: cloudinaryImage.original,
    };
  },
  toCloudinaryImage(customizationImage: CustomizationImage): CloudinaryImage {
    const { name, source } = customizationImage;
    return { id: name, '450x': source, '960x': source, original: source };
  },
};
