import { GetCampaignSingleRequest, PosCampaignsAPI } from 'services/api/pos/campaigns';

import { PosCampaignDetails } from './types';

export async function getCampaignDetails(request: GetCampaignSingleRequest): Promise<PosCampaignDetails> {
  const [campaign, proposal] = await Promise.all([
    PosCampaignsAPI.getCampaignSingle(request),
    PosCampaignsAPI.getProposalForCampaign({ campaignId: request.id }).catch(() => undefined),
  ]);

  const shouldFetchProductPreviews = campaign.commission && campaign.campaignConditions.productIds.length > 0;

  const productsPreview = shouldFetchProductPreviews
    ? await PosCampaignsAPI.fetchPosCampaignProductsPreview({
        discountType: campaign.actions.discount.discountType,
        discountValue: campaign.actions.discount.discountValue,
        productIds: campaign.campaignConditions.productIds,
        totalCommission: campaign.commission?.totalCommission,
      })
    : undefined;

  if (campaign.status === 'Completed') {
    const summary = await PosCampaignsAPI.getPosSingleCampaignSummary(request);

    return {
      campaign,
      products: productsPreview?.products,
      proposal,
      summary,
    };
  }

  return {
    campaign,
    products: productsPreview?.products,
    proposal,
    summary: undefined,
  };
}
