import { safeTakeLatest } from '@frontend/commons';
import { format } from 'date-fns';

import { chunk } from 'lodash';

import { combineReducers } from 'redux';
import { Action, createReducer } from 'redux-act';

import { put, select, call, StrictEffect, Effect } from 'redux-saga/effects';

import { DATE_ONLY_FORMAT } from 'constants/DATE';

import { CommissionsAPI, PosProductsAPI } from 'services/api';
import { ProductCommission } from 'services/api/common-types/types-commissions';
import {
  Product,
  ProductsSearchPayload,
  ProductsSearchResponse,
} from 'services/api/pos/products/types-search-for-products';
import { authActions, posMarketSelector } from 'store/auth';
import { createDefaultActions, createDefaultReducer } from 'store/helpers';
import { createMemoizedCollectionReducer } from 'store/helpers/memoized-collection-async-helpers';

import { ProductsState, State } from './types';

// Actions
const fetchHomeProducts = createDefaultActions<void, ProductsSearchResponse>('HOME_PRODUCTS__FETCH_PRODUCTS');
const fetchMoreHomeProducts = createDefaultActions<number, ProductsSearchResponse>(
  'HOME_PRODUCTS__FETCH_MORE_PRODUCTS'
);
const fetchHomeCommissions = createDefaultActions<string[], ProductCommission[]>('HOME_PRODUCTS__FETCH_COMMISSIONS');

export const actions = {
  fetchHomeProducts: fetchHomeProducts.request,
  fetchMoreHomeProducts: fetchMoreHomeProducts.request,
  fetchHomeCommissions: fetchHomeCommissions.request,
};

// Reducers
export const commissionsReducer = createMemoizedCollectionReducer<string[], ProductCommission>(
  fetchHomeCommissions,
  (x) => `${x.productId}_${x.commission}`,
  [],
  authActions.authorizeViaProfileId.success
);

const fetchHomeProductsInitialData: ProductsState = {
  data: undefined,
  pending: false,
  initialPending: false,
  pristine: true,
};

const fetchHomeProductsReducer = createDefaultReducer<void, ProductsSearchResponse, undefined>(
  fetchHomeProducts,
  undefined,
  authActions.authorizeViaProfileId.success
);

const fetchMoreHomeProductsReducer = createReducer<ProductsState>(
  {
    [fetchMoreHomeProducts.request.getType()]: (state) => ({
      data: state.data,
      error: undefined,
      pending: true,
      initialPending: state.data === fetchHomeProductsInitialData.data,
      pristine: false,
    }),
    [fetchMoreHomeProducts.success.getType()]: (state: ProductsState, payload: { products: Product[] }) => {
      const currentIds = new Set(state.data?.products.map((_) => _.id));
      const uniqueProducts = payload.products.filter((product) => !currentIds.has(product.id));

      // It's fetch more so data is already here
      const oldState = state.data as ProductsSearchResponse;
      const newData = {
        ...oldState,
        products: oldState.products.concat(uniqueProducts),
      };

      return {
        pending: false,
        data: newData,
        initialPending: false,
        pristine: false,
      };
    },
    [fetchMoreHomeProducts.failure.getType()]: (_, payload) => ({
      pending: false,
      data: undefined,
      error: payload,
      initialPending: false,
      pristine: false,
    }),
  },
  fetchHomeProductsInitialData
);

const pageNumberReducer = createReducer(
  {
    [fetchHomeProducts.request.getType()]: () => 1,
    [fetchMoreHomeProducts.success.getType()]: (state) => state + 1,
  },
  0
);

export const productsReducer = (state: ProductsState | undefined, action: any) =>
  fetchHomeProductsReducer(fetchMoreHomeProductsReducer(state, action), action);

export const reducer = combineReducers<State>({
  products: productsReducer,
  pageNumber: pageNumberReducer,
  commissions: commissionsReducer,
});

// Sagas
// This way of typing does not allow to type couple of yields at the same time
// any to the rescue
function* productsSaga(): Generator<StrictEffect, void, any> {
  const marketId = yield select(posMarketSelector);
  try {
    const params: ProductsSearchPayload = {
      filters: {
        firstPublishedDate: {
          from: format(new Date(), DATE_ONLY_FORMAT),
        },
      },
      limit: 20,
      marketId,
      order: {},
      skip: 0,
    };
    const data = yield call(PosProductsAPI.search, params);
    yield put(fetchHomeProducts.success(data));
  } catch (e) {
    yield put(fetchHomeProducts.failure(e));
    throw e;
  }
}

/** CommissionsAPI.getMany fails if too many products ids are in payload, so we need to split big payloads into chunks */
function* fetchSafelyCommissionSaga(action: Action<string[]>): Generator<StrictEffect, void, ProductCommission[]> {
  const chunksOfIds = chunk(action.payload, 60);
  for (let i = 0; i < chunksOfIds.length; i += 1) {
    const commissions = yield call(CommissionsAPI.getMany, chunksOfIds[i]);
    yield put(fetchHomeCommissions.success(commissions));
  }
}

function* fetchMoreHomeProductsSaga(action: Action<number>): Generator<Effect, void, any> {
  const marketId = yield select(posMarketSelector);

  try {
    const params: ProductsSearchPayload = {
      filters: {
        firstPublishedDate: {
          from: format(new Date(), DATE_ONLY_FORMAT),
        },
      },
      skip: action.payload,
      marketId,
      limit: 20,
      order: {},
    };
    const data = yield call(PosProductsAPI.search, params);
    yield put(fetchMoreHomeProducts.success(data));
  } catch (e) {
    yield put(fetchMoreHomeProducts.failure(e));
    throw e;
  }
}

export function* saga() {
  yield safeTakeLatest(fetchHomeProducts.request, productsSaga);
  yield safeTakeLatest(fetchMoreHomeProducts.request, fetchMoreHomeProductsSaga);
  yield safeTakeLatest(fetchHomeCommissions.request, fetchSafelyCommissionSaga);
}
