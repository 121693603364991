import { getDecodedAuthToken } from 'services/get-decoded-auth-token';
import { store } from 'store';
import { authSelector } from 'store/auth/selectors';

export function isAuthTokenSetButStateNone(error: any) {
  const isClientErrorApiRequest = error?.response?.status > 399 && error?.response?.status < 500;

  if (!isClientErrorApiRequest) {
    return false;
  }

  const authState = authSelector(store.getState());
  const authToken = getDecodedAuthToken();
  const isUserSignInAsMerchantOrPos = authToken.merchantId != null || authToken.posId != null;

  return authState == null && isUserSignInAsMerchantOrPos;
}
