import { post } from '../base';

import { AcceptInvitationRequest, AcceptInvitationResponse } from './types-accept-invitation';

export function acceptInvitation(invitationId: string): Promise<AcceptInvitationResponse> {
  const invitationRequest: AcceptInvitationRequest = {
    invitationId,
  };
  return post(`v5/invitations/${invitationId}/accept`, { json: invitationRequest }).json();
}
