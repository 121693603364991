import { ConfirmationModal } from '@frontend/commons';
import React, { FC, useCallback } from 'react';

import { authActions } from 'store/auth';
import { useActionWithDispatch } from 'store/helpers';

type Props = {
  onClose: () => void;
};

const CONTENT_TEXT = 'Your session has ended, please log out.';

export const SessionExpiredModal: FC<Props> = ({ onClose }) => {
  const logout = useActionWithDispatch(authActions.logout);
  const logoutSoftly = useActionWithDispatch(authActions.logoutSoftly);

  const handleLogout = useCallback(() => {
    logout();
    onClose();
  }, [logout, onClose]);

  const handleClose = useCallback(() => {
    onClose();
    logoutSoftly();
  }, [onClose, logoutSoftly]);

  return (
    <ConfirmationModal
      title="Session has expired"
      content={CONTENT_TEXT}
      onConfirm={handleLogout}
      onReject={handleClose}
      confirmButtonLabel="Logout"
      rejectButtonLabel="Close"
    />
  );
};
