import { put } from '../base';

import { Customization, CustomizationCreateUpdatePayload } from './types';

export function updateProductCustomization({
  id,
  customization,
}: {
  id: string;
  customization: CustomizationCreateUpdatePayload;
}) {
  return put(`v5/product-customizations/${id}`, { json: customization }).json<Customization>();
}
