import { useEffect } from 'react';

import { useActionWithDispatch, useSelector } from 'store';

import { actions, getMerchantSettings } from '../../store/merchant-settings';

export function useFetchMerchantSettings() {
  const merchantSettings = useSelector(getMerchantSettings);
  const dispatchFetchMerchantSettingsAction = useActionWithDispatch(actions.fetchMerchantSettingsAction);

  useEffect(() => {
    if (merchantSettings.pristine) {
      dispatchFetchMerchantSettingsAction();
    }
  }, [dispatchFetchMerchantSettingsAction, merchantSettings]);

  return merchantSettings;
}
