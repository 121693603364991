import { getAuthToken, SimpleSpinner as Spinner } from '@frontend/commons';
import React, { FC } from 'react';

import { Redirect, useLocation } from 'react-router-dom';

import { DEFAULT_ROUTES } from 'constants/DEFAULT_ROUTES';
import { Page404 } from 'pages/page404';
import { userTypeSelector } from 'store/auth';
import { useSelector } from 'store/helpers';

export const RedirectRoot: FC<{}> = () => {
  const location = useLocation();
  const userType = useSelector(userTypeSelector);
  const authToken = getAuthToken();

  if (!['', '/'].includes(location.pathname)) {
    return <Page404 />;
  }

  if (userType === 'unauthenticated' && authToken) {
    return <Spinner fadeIn="delayed" />;
  }
  const url = DEFAULT_ROUTES[userType];
  if (!url) {
    return <Page404 />;
  }

  return <Redirect to={url} />;
};
