import { PreviewCampaignProductsResponse } from 'services/api/common-types/types-campaign-product-preview';
import { PosCampaignsAPI } from 'services/api/pos/campaigns';
import { fetchPosCampaignProposal } from 'services/api/pos/campaigns/campaign-proposals/fetch-campaign-proposal';
import { FetchPosCampaignProposalRequest } from 'services/api/pos/campaigns/campaign-proposals/types-fetch-campaign-proposal';

import { PosCampaignProposalDetails } from './types';

export type FetchPosCampaignProposalActionRequest = FetchPosCampaignProposalRequest & {
  isFreshlyAccepted?: boolean;
};

export async function getPosProposalDetails(
  request: FetchPosCampaignProposalActionRequest
): Promise<PosCampaignProposalDetails> {
  const proposal = await fetchPosCampaignProposal(request);

  const productsPreview: PreviewCampaignProductsResponse = await PosCampaignsAPI.fetchPosCampaignProductsPreview({
    discountType: proposal.actions.discount.discountType,
    discountValue: proposal.actions.discount.discountValue,
    productIds: proposal.campaignConditions.productIds,
    totalCommission: proposal.commission.totalCommission,
  });

  return {
    proposal,
    products: productsPreview.products,
    commission: productsPreview.commission?.posPercentage,
    isFreshlyAccepted: !!request.isFreshlyAccepted,
  };
}
