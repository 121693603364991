import { setBasicAuthToken } from '@frontend/commons';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { Action } from 'redux-act';

import { call, put } from 'redux-saga/effects';

import { ABSOLUTE_LINK } from 'constants/VALIDATION_PATTERNS';
import { redirectToOldAppUserProfile } from 'helpers/redirect-to-old-app';
import { AuthAPI, UserAPI } from 'services/api';
import { CurrentProfileResponse } from 'services/api/user/profile/types-get-current';

import { ProfilesResponse } from 'services/api/user/profile/types-get-user-profiles';

import { getProfiles } from '../common-helpers';

import { FullyAuthenticatedUserPayload } from '../types';

import { authenticateAction } from './common';

export function* authViaCredentialsSaga(action: Action<FullyAuthenticatedUserPayload>) {
  try {
    const { password, email } = action.payload;
    // @ts-ignore
    const token = yield call(AuthAPI.authenticate, { password, email });
    yield setBasicAuthToken(token);

    const profiles: ProfilesResponse = yield call(UserAPI.getUserProfiles, token);
    const currentPosId = profiles.posProfiles[0]?.posId;
    const currentMerchantId = profiles.merchantProfiles[0]?.id;

    if (!currentMerchantId && !currentPosId) {
      redirectToOldAppUserProfile();
    }

    const payload = currentPosId ? { posId: currentPosId } : { merchantId: currentMerchantId };
    // @ts-ignore
    const tokenWithPosOrMerchantContext = yield call(AuthAPI.authorizePosOrMerchant, payload, token);
    const { posProfile, merchantProfile } = yield* getProfiles(
      currentPosId,
      currentMerchantId,
      tokenWithPosOrMerchantContext
    );
    const { redirectUri } = queryString.parse(window.location.search) as { redirectUri?: string };

    const profile: CurrentProfileResponse = yield call(UserAPI.getCurrentProfile, tokenWithPosOrMerchantContext);

    yield put(
      authenticateAction.success({
        profile,
        token: tokenWithPosOrMerchantContext,
        currentPosId,
        currentMerchantId,
        posProfile,
        merchantProfile,
      })
    );
    if (redirectUri && !ABSOLUTE_LINK.test(redirectUri)) {
      yield put(push(redirectUri));
    }
  } catch (e: any) {
    let error = 'Something went wrong, try again';
    if (e?.response?.status === 401) {
      error = 'Wrong email or password';
    }
    yield put(authenticateAction.failure(error));
  }
}
