import { safeTakeLatest } from '@frontend/commons';

import { CategoriesAPI } from 'services/api/categories';
import { createDefaultAsyncHelpers } from 'store/helpers';

const {
  saga: defaultAsyncSaga,
  actions: categoriesTreeActions,
  reducer,
} = createDefaultAsyncHelpers({
  actionName: 'FETCH_CATEGORIES_TREE',
  fetchFunc: CategoriesAPI.index,
  initialData: undefined,
});

function* saga() {
  yield safeTakeLatest(categoriesTreeActions.request, defaultAsyncSaga);
}

export { categoriesTreeActions, saga, reducer };
