import { chunk } from 'lodash';
import { AnyAction } from 'redux';
import { Action, createReducer } from 'redux-act';
import { takeLatest, select } from 'redux-saga/effects';

import { replaceOrAddElements } from 'helpers/array';
import { collectionsActions } from 'store/collections';
import { createMemoizedCollectionAsyncHelpers } from 'store/helpers';

import { collectionsProductsAvailabilityDataSelector } from './selectors';
import { fetchAllCollectionsProductsAvailability } from './services';
import { State, CollectionAvailability } from './types';

const {
  actions,
  initialState,
  reducer: collectionsProductsAvailabilityReducer,
  saga: collectionsProductsAvailabilitySaga,
} = createMemoizedCollectionAsyncHelpers({
  actionName: 'COLLECTIONS_STOCK_COUNT__FETCH',
  uniqueIdSelector: (x) => x.collectionId,
  fetchFunc: fetchAllCollectionsProductsAvailability,
  initialData: [],
});

const updateCollectionAvailabilitiesReducer = createReducer<State>({})
  .on(collectionsActions.removeProductByCollectionItemId.success, (state, payload) => {
    if (!state.data) {
      return state;
    }

    return {
      ...state,
      data: replaceOrAddElements(state.data, payload, (x) => x.collectionId),
    };
  })
  .on(collectionsActions.addProduct.success, (state, payload) => {
    if (!state.data) {
      return state;
    }

    return {
      ...state,
      data: replaceOrAddElements(state.data, payload, (x) => x.collectionId),
    };
  });

const CHUNK_SIZE = 20;

function* splitIntoChunksSaga(action: Action<{ ids: string[]; type: 'private' | 'shared'; forceUpdate?: boolean }>) {
  const {
    payload: { ids, forceUpdate },
  } = action;
  const collectionIdsToFetch = forceUpdate ? ids : yield* filterOutCollectionIdsWithFetchedAvailabilities(ids);

  const chunksOfIds = chunk(collectionIdsToFetch, CHUNK_SIZE);
  for (let i = 0; i < chunksOfIds.length; i += 1) {
    yield* collectionsProductsAvailabilitySaga({ ...action, payload: { ...action.payload, ids: chunksOfIds[i] } });
  }
}

export const collectionsProductsAvailabilityActions = actions;
export const reducer = (state: State, action: AnyAction) =>
  updateCollectionAvailabilitiesReducer(collectionsProductsAvailabilityReducer(state, action), action);
export const collectionsCollectionsProductsAvailabilityInitialState = initialState;

export function* saga() {
  yield takeLatest(actions.request, splitIntoChunksSaga);
}

function* filterOutCollectionIdsWithFetchedAvailabilities(ids: string[]) {
  const collectionsAvailabilities = collectionsProductsAvailabilityDataSelector(yield select()) || [];
  const collectionIdsWithAvailabilities = new Set(
    collectionsAvailabilities.map((x: CollectionAvailability) => x.collectionId)
  );
  return ids.filter((id) => !collectionIdsWithAvailabilities.has(id));
}
