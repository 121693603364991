import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import { reducer as tokenOutOfSyncReducer } from 'components/organisms/local-error-modals/token-out-of-sync-modal/store/duck';
import { reducer as merchantActivationTopBarReducer } from 'components/organisms/merchant-activation-top-bar/store';
import { getInvitationStatusReducer } from 'pages/auth/invitation/store/duck';
import { reducer as merchantAnalyticsReducer } from 'pages/merchant/analytics-dashboard/store';
import { reducer as campaignSingleReducer } from 'pages/merchant/campaign-single/store';
import { reducer as merchantCampaignsReducer } from 'pages/merchant/campaigns/store';
import { reducer as pageMerchantReducer } from 'pages/merchant/merchant-page/store/duck';
import { reducer as pageMerchantOrderReducer } from 'pages/merchant/order-details/duck';
import { reducer as pageMerchantOrdersReducer } from 'pages/merchant/orders/duck';

import {
  reducer as merchantSingleProduct,
  fetchProductReducer as fetchMerchantSingleProductReducer,
  updateProductReducer as updateMerchangeSingleProductReducer,
  createProductReducer as createMerchantSingleProductReducer,
} from 'pages/merchant/product-single/store';

import { reducer as merchantProductsReducer } from 'pages/merchant/products/duck';
import { reducer as merchantSalesReducer } from 'pages/merchant/sales';
import { reducer as posAnalyticsReducer } from 'pages/pos/analytics-dashboard/store';
import { reducer as brandsReducer } from 'pages/pos/brands/duck';
import { reducer as posCampaignSingleReducer } from 'pages/pos/campaign-single/store';
import { reducer as posCampaignsReducer } from 'pages/pos/campaigns/store';
import { reducer as posHomeReducer } from 'pages/pos/home/store';
import { reducer as pageInsertProductsReducer } from 'pages/pos/insert-products/store';
import { reducer as categoryMerchantsReducer } from 'pages/pos/merchant-gallery/category-merchants/store';
import { reducer as pageDiscoverMerchantsReducer } from 'pages/pos/merchant-gallery/discover-merchants/store';
import { reducer as userDiscoverySettingsReducer } from 'pages/pos/merchant-gallery/preferences/store';
import { reducer as pageDiscoverySearchResultsReducer } from 'pages/pos/merchant-gallery/search-results/store';
import { reducer as pagePosMerchantReducer } from 'pages/pos/merchant-gallery/single-merchant/store';
import { reducer as posSalesReducer } from 'pages/pos/sales/duck';
import { reducer as userSettingsReducer } from 'pages/user/settings/store/duck';
import { history } from 'routes/history';

import { reducer as collectionsProductsAvailabilityReducer } from 'store/collections-products-availability';
import { reducer as merchantSettingsReducer } from 'store/merchant-settings';

import { reducer as appReducer } from './app';
import { reducer as authReducer } from './auth';
import { reducer as categoryTreeReducer } from './category-tree';
import { reducer as collectionsReducer, removeCollectionReducer } from './collections';
import { reducer as marketsReducer } from './markets';
import { reducer as merchantPosesReducer } from './merchant-poses';
import { reducer as metadataReducer } from './metadata';
import { reducer as productsCollectionsReducer } from './products-collections';

import { GlobalState } from './types';
import { reducer as userProfilesReducer } from './user-profiles';

export const rootReducer = combineReducers<GlobalState>({
  app: appReducer,
  auth: authReducer,
  router: connectRouter(history),
  collections: collectionsReducer,
  collectionRemove: removeCollectionReducer,
  collectionsProductsAvailability: collectionsProductsAvailabilityReducer,
  productsCollections: productsCollectionsReducer,
  markets: marketsReducer,
  categoryTree: categoryTreeReducer,
  pages: combineReducers({
    insertProducts: pageInsertProductsReducer,
    brands: brandsReducer,
    posSales: posSalesReducer,
    posHome: posHomeReducer,
    posCampaigns: posCampaignsReducer,
    merchantPage: pageMerchantReducer,
    merchantSales: merchantSalesReducer,
    merchantProducts: merchantProductsReducer,
    merchantOrders: pageMerchantOrdersReducer,
    merchantOrder: pageMerchantOrderReducer,
    merchantProductSingle: fetchMerchantSingleProductReducer,
    merchantUpdateProductSingle: updateMerchangeSingleProductReducer,
    merchantAnalyticsDashboard: merchantAnalyticsReducer,
    posAnalyticsDashboard: posAnalyticsReducer,
    productFormResources: merchantSingleProduct,
    merchantCreateProductSingle: createMerchantSingleProductReducer,
    merchantCampaigns: merchantCampaignsReducer,
    userSettings: userSettingsReducer,
    posMerchantDiscoverySearch: pageDiscoverySearchResultsReducer,
    posDiscoverMerchants: pageDiscoverMerchantsReducer,
    posDiscoverySettings: userDiscoverySettingsReducer,
    posMerchant: pagePosMerchantReducer,
    posCategoryMerchants: categoryMerchantsReducer,
    invitation: getInvitationStatusReducer,
    merchantCampaignSingle: campaignSingleReducer,
    posCampaignSingle: posCampaignSingleReducer,
  }),
  organisms: combineReducers({
    merchantActivation: merchantActivationTopBarReducer,
    tokenOutOfSync: tokenOutOfSyncReducer,
  }),
  userProfiles: userProfilesReducer,
  metadata: metadataReducer,
  merchantPoses: merchantPosesReducer,
  merchantSettings: merchantSettingsReducer,
});
