import { updateLogo } from 'services/api/merchants/logo/update-logo';
import { MerchantPublicInformationUpdateRequest } from 'services/api/merchants/public-information/types';
import { updateMerchantPublicInformation } from 'services/api/merchants/public-information/update-public-information';

import { MerchantPublicInformationFormData } from '../../types';

export const updateMerchantPublicInfo = async (formData: MerchantPublicInformationFormData) => {
  const request = await getPayload(formData);

  await updateMerchantPublicInformation(request);

  return { updatedSettingsFetched: false };
};

const getPayload = async (
  formData: MerchantPublicInformationFormData
): Promise<MerchantPublicInformationUpdateRequest> => {
  let logoId = formData.logoUrl;

  if (formData.logoFile) {
    const logoFormData = new FormData();
    logoFormData.append('image', formData.logoFile);
    const { Name } = await updateLogo(logoFormData);
    logoId = Name;
  }

  let mainImageId = formData.mainImageUrl;

  if (formData.mainImageFile) {
    const mainImageFormData = new FormData();
    mainImageFormData.append('image', formData.mainImageFile);
    const { Name } = await updateLogo(mainImageFormData);
    mainImageId = Name;
  }

  return {
    story: formData.story,
    mediaLinks: formData.mediaLinks,
    keywords: [],
    mainImage: mainImageId,
    logo: logoId,
    contactInformation: formData.contactInformation,
  };
};
