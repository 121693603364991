import { createSelector } from 'reselect';

import { GlobalState } from 'store/types';

export const campaignProposalSelector = (proposalId: string) => (state: GlobalState) => ({
  data: state.pages.merchantCampaignSingle.campaignProposal.data[proposalId],
  isLoading: state.pages.merchantCampaignSingle.campaignProposal.pending[proposalId],
  error: state.pages.merchantCampaignSingle.campaignProposal.error[proposalId],
});

export const campaignSelector = (campaignId: string) => (state: GlobalState) => ({
  data: state.pages.merchantCampaignSingle.campaign.data[campaignId],
  isLoading: state.pages.merchantCampaignSingle.campaign.pending[campaignId],
});

export const campaignSummarySelector = (campaignId: string) => (state: GlobalState) =>
  state.pages.merchantCampaignSingle.campaign.data[campaignId]?.summary;

export const campaignSummaryProductsSelector = (campaignId: string) =>
  createSelector(campaignSummarySelector(campaignId), (summary) => {
    if (!summary) {
      return undefined;
    }
    return summary.productSales.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.productId]: curr,
      }),
      {}
    );
  });
