import { FC, useEffect } from 'react';

import './styles.module.scss';

export const MerchantFreshdeskWidget: FC = () => {
  useEffect(() => {
    if (!window.FreshworksWidget) {
      const widgetJsScript = document.createElement('script');
      widgetJsScript.src = 'https://euc-widget.freshworks.com/widgets/80000003549.js';
      widgetJsScript.async = true;
      document.body.appendChild(widgetJsScript);

      const widgetInitScript = document.createElement('script');
      widgetInitScript.innerText = `
    window.fwSettings={
        'widget_id':80000003549
        };
        !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
    `;
      document.body.appendChild(widgetInitScript);
      window.FreshworksWidget('show');
    } else {
      window.FreshworksWidget('boot');
    }

    return () => {
      window.FreshworksWidget('destroy');
    };
  }, []);

  return null;
};
