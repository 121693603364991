import { CommissionsPayload, PosCommissionsSummaryResponse } from 'services/api/common-types/types-commissions';

import { get } from '../../base';
import { commissionsPayloadToCommissionsSummaryPayload } from '../../helpers';

export function getPosSummary(params: CommissionsPayload): Promise<PosCommissionsSummaryResponse> {
  return get('v5/pos/commissions/summary', {
    searchParams: commissionsPayloadToCommissionsSummaryPayload(params),
  }).json();
}
