import { isResponseError } from '@frontend/commons/src/types/type-guards/is-response-error';
import { keyBy } from 'lodash';
import { createSelector } from 'reselect';

import { MerchantProfile } from 'services/api/user/profile/types-get-user-profiles';
import { ProfileType } from 'types/model/profile';

import { GlobalState } from '../types';

export const currentPosIdSelector = (state: GlobalState) => state.auth.data?.currentPosId;
export const profileSelector = (state: GlobalState) => state.auth.data?.profile;
export const authSelector = (state: GlobalState) => state.auth.data;
export const authTokenSelector = (state: GlobalState) => state.auth.data?.token;
export const isAuthPendingSelector = (state: GlobalState) => state.auth.pending;
export const posProfileSelector = (state: GlobalState) => state.auth.data?.posProfile;
export const posProfileShopNameSelector = (state: GlobalState) => state.auth.data?.posProfile?.displayName || '';
export const posProfilePictureIdSelector = createSelector(
  posProfileSelector,
  (profile) => profile?.profilePictureUrl.id
);
export const merchantProfileSelector = (state: GlobalState) => state.auth.data?.merchantProfile;
export const merchantProfilePictureIdSelector = createSelector(
  merchantProfileSelector,
  (profile) => profile?.publicImageName?.name
);
export const merchantProfileCompanyNameSelector = createSelector(
  merchantProfileSelector,
  (merchantProfile) => merchantProfile?.companyName || ''
);
export const merchantMarkets = (state: GlobalState) => merchantProfileSelector(state)?.markets ?? [];
export const posMarketSelector = (state: GlobalState) => state.auth.data?.posProfile?.marketId;
export const profileNameSelector = (state: GlobalState) => state.auth.data?.posProfile?.profileName;
export const authErrorSelector = (state: GlobalState) => state.auth.error;
export const getUserTimezone = (state: GlobalState) => state.auth.data?.profile.settings.locale.timeZone;
export const getUserTimeFormat = (state: GlobalState) => state.auth.data?.profile.settings.locale.timeFormat;
export const getUserDateFormat = (state: GlobalState) => state.auth.data?.profile.settings.locale.dateFormat;

export const userTypeSelector = createSelector(authSelector, (authState): ProfileType => {
  if (!authState) {
    return 'unauthenticated';
  }
  if (authState.currentPosId) {
    return 'pos';
  }
  if (authState.currentMerchantId) {
    return 'merchant';
  }

  return 'user';
});

export const getFallbackPosId = createSelector(posMarketSelector, (market) => `default-${market}`);

export const getMerchantsById = createSelector(
  (state: GlobalState) => state.userProfiles.fetchProfiles?.data?.merchantProfiles ?? [],
  (merchants: MerchantProfile[]) => {
    return keyBy(merchants, (merchant) => merchant.id);
  }
);

export const getResetPasswordError = createSelector(authErrorSelector, (error) => {
  if (!error) {
    return null;
  }

  return 'Something went wrong, try again';
});

export const getNewPasswordError = createSelector(authErrorSelector, (error) => {
  if (!error) {
    return null;
  }

  if (isResponseError(error) && error.response.status === 400) {
    return 'This reset link has been used already';
  }

  return 'Something went wrong, try again';
});
