/* eslint-disable prefer-promise-reject-errors */
/**
 * copy pasted from https://github.com/facebook/react/issues/5465#issuecomment-157888325
 * referenced also in : https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
 */

export interface CancelablePromise<T> {
  promise: Promise<T>;
  cancel: () => void;
}

export function makeCancelable<T>(promise: Promise<T>): CancelablePromise<T> {
  let hasCanceled = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
  };
}

export function silenceCancelable<T>(promise: Promise<T>): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    promise.then(resolve).catch((e) => {
      if (e && !e.isCanceled) {
        reject(e);
      }
    });
  });
}
