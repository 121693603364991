import { getAuthToken, safeTakeLatest } from '@frontend/commons';
import { push } from 'connected-react-router';
import { Action } from 'redux-act';
import { call, put } from 'redux-saga/effects';

import { InvitationsAPI } from 'services/api';
import { createDefaultActions, createDefaultReducer } from 'store/helpers';

import { AsyncReturnType } from 'types/async-return-type';

import { State, UserInvitationStatus } from './types';

export const initState = null;

const getInvitationStatusDefaultAction = createDefaultActions<string, State, string>('GET_INVITATION_STATUS');

export const getInvitationStatusAction = {
  request: getInvitationStatusDefaultAction.request,
  failure: getInvitationStatusDefaultAction.failure,
};

export const getInvitationStatusReducer = createDefaultReducer<string, State, null, string>(
  getInvitationStatusDefaultAction,
  initState
);

function* proceedWithInvitationStatus(action: Action<string>) {
  const invitationId = action.payload;
  const userToken = getAuthToken();

  const invitationStatus: AsyncReturnType<typeof processInvitation> = yield call(
    processInvitation,
    invitationId,
    userToken
  );

  const profileSwitcherWithInviteTokenUrl = `/profiles?invitationToken=${invitationId}`;
  switch (invitationStatus) {
    case 'invalid':
      yield put(getInvitationStatusAction.failure('Something went wrong'));
      break;
    case 'not-registered':
      yield put(push(`/auth/sign-up?invitationToken=${invitationId}`));
      break;
    case 'registered-and-logged':
    case 'not-registered-logged-in':
      yield put(push(profileSwitcherWithInviteTokenUrl));
      break;
    case 'registered-and-not-logged':
      yield put(push(`/auth/login?redirectUri=${profileSwitcherWithInviteTokenUrl}`));
      break;
  }
}

async function processInvitation(invitationId: string, userToken?: string): Promise<UserInvitationStatus> {
  try {
    const { invitationStatus, isUserRegistered } = await InvitationsAPI.getInvitationStatus(invitationId);

    if (invitationStatus !== 'Pending') {
      return 'invalid';
    }

    const isUserLoggedIn = !!userToken;

    if (isUserRegistered) {
      return isUserLoggedIn ? 'registered-and-logged' : 'registered-and-not-logged';
    }

    if (!isUserRegistered && isUserLoggedIn) {
      return 'not-registered-logged-in';
    }

    return 'not-registered';
  } catch (e) {
    return 'invalid';
  }
}

export function* saga() {
  yield safeTakeLatest(getInvitationStatusAction.request, proceedWithInvitationStatus);
}
