import { CommissionsAPI, PosCommissionsAPI } from 'services/api';
import {
  CommissionsPayload,
  CommissionsResponse,
  PosCommissionsSummaryResponse,
} from 'services/api/common-types/types-commissions';

export async function fetchAllCommissionsData(
  payload: CommissionsPayload
): Promise<{ items: CommissionsResponse; summary: PosCommissionsSummaryResponse }> {
  const indexPromise = CommissionsAPI.getPosCommissions(payload);
  const summaryPromise = PosCommissionsAPI.getPosSummary(payload);
  const [items, summary] = await Promise.all([indexPromise, summaryPromise]);

  return {
    items,
    summary,
  };
}
