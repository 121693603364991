import { safeTakeLatest } from '@frontend/commons';

import { CollectionsAPI } from 'services/api/collections';
import { createDefaultAsyncHelpers } from 'store/helpers';

import { scrollWindowToTop } from '../../../../store/helpers/helpers';
import { manageCollectionViewActions } from '../manage-collection-view';

const {
  actions: fetchCollectionActions,
  reducer: fetchCollectionReducer,
  saga: fetchCollectionLocalSaga,
} = createDefaultAsyncHelpers({
  fetchFunc: CollectionsAPI.getById,
  actionName: 'INSERT_PRODUCTS_MANAGE_COLLECTION--FETCH_COLLECTION',
  cleanAction: manageCollectionViewActions.registerClosedView,
  initialData: undefined,
});

fetchCollectionReducer.on(fetchCollectionActions.request, (state) => ({
  ...state,
  pending: true,
  data:
    state.data !== undefined
      ? {
          ...state.data,
          items: [],
        }
      : state.data,
}));

function* fetchCollectionSaga() {
  yield safeTakeLatest(fetchCollectionActions.request, fetchCollectionLocalSaga);
  yield safeTakeLatest(fetchCollectionActions.request, scrollWindowToTop);
}

export { fetchCollectionSaga, fetchCollectionReducer, fetchCollectionActions };
