import { safeTakeLatest } from '@frontend/commons';

import { createDefaultAsyncHelpers } from 'store/helpers';

import { fetchMerchantBestSellers } from './service';

const {
  saga: fetchMerchantBestSellersSaga,
  actions: fetchMerchantBestSellersActions,
  reducer,
} = createDefaultAsyncHelpers({
  fetchFunc: fetchMerchantBestSellers,
  actionName: 'MERCHANT_BEST_SELLERS__GET',
  initialData: undefined,
});

const actions = {
  fetchMerchantBestSellersAction: fetchMerchantBestSellersActions.request,
};

function* saga() {
  yield safeTakeLatest(fetchMerchantBestSellersActions.request, fetchMerchantBestSellersSaga);
}

export { saga, actions, reducer };
