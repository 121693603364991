import { useCallback } from 'react';
import { useDispatch, useSelector as useReduxSelector } from 'react-redux';
import { ActionCreator, EmptyActionCreator } from 'redux-act';

import { GlobalState } from 'store/types';

export function useActionWithDispatch(action: EmptyActionCreator): () => void;
export function useActionWithDispatch<P extends any>(action: ActionCreator<P>): (payload: P) => void;

export function useActionWithDispatch<P extends object>(action: ActionCreator<P>): (payload: P) => void {
  const dispatch = useDispatch();
  return useCallback<(() => void) | ((payload: P) => void)>(
    (...args: [P]) => {
      if (args.length > 0) {
        dispatch(action(args[0]));
      } else {
        dispatch((action as EmptyActionCreator)());
      }
    },
    [dispatch, action]
  );
}

export function useSelector<TSelected>(selector: (state: GlobalState) => TSelected): TSelected {
  return useReduxSelector<GlobalState, TSelected>(selector);
}
