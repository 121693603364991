import { TransformFnParams } from 'class-transformer';
import { SortingRule } from 'react-table';

export const sortingRuleTransformers = {
  toFlatString(params: TransformFnParams) {
    const { value } = params as { value: SortingRule<string>[]; obj: Record<string, unknown> };

    if (!Array.isArray(value)) {
      return undefined;
    }

    return value.map(({ id, desc }) => `${id}|${desc ? '1' : '0'}`);
  },

  createToTypedArray<T>(possibleSortingIds: T[]) {
    return function transformer(params: TransformFnParams) {
      const { value } = params;

      const arrayValue = Array.isArray(value) ? value : [value];

      if (arrayValue.every((x) => isSortingRule<T>(x, possibleSortingIds))) {
        return arrayValue;
      }

      const verifiedValue = arrayValue.map((x) => {
        if (typeof x !== 'string') {
          return false;
        }

        const [id, desc] = x.split('|');

        if (!possibleSortingIds.includes(id as unknown as T)) {
          return undefined;
        }

        if (!['1', '0'].includes(desc)) {
          return undefined;
        }

        return {
          id,
          desc: desc === '1',
        } as SortingRule<T>;
      });

      if (verifiedValue.length === 0) {
        return undefined;
      }

      return verifiedValue;
    };
  },
};

function isSortingRule<T>(maybeSortingRule: any, possibleValues: T[]): maybeSortingRule is SortingRule<T> {
  if (typeof maybeSortingRule !== 'object') {
    return false;
  }

  if (typeof maybeSortingRule.desc !== 'boolean') {
    return false;
  }

  if (typeof maybeSortingRule.id !== 'string' || !possibleValues.includes(maybeSortingRule.id)) {
    return false;
  }

  return true;
}
