import { safeTakeLatest } from '@frontend/commons';
import { replace, LOCATION_CHANGE, LocationChangePayload } from 'connected-react-router';
import memoizeOne from 'memoize-one';
import { parse } from 'query-string';
import { Action, createAction, createReducer } from 'redux-act';
import { debounce, put, select } from 'redux-saga/effects';

import { DEFAULT_ORDER } from 'constants/ORDER_OPTIONS';
import { QUERY_STRING_PARSE_CONFIG } from 'constants/QUERY_STRING_CONFIG';
import { generateQueryObject, generateQueryParams } from 'helpers/query-params-sync/helpers';
import { authActions } from 'store/auth';

import { resetFiltersAndResult } from '../common';
import { getMarketId } from '../helpers/helpers';
import { fetchProducts } from '../products/duck';

import { FiltersState } from './filters-state';
import { flatSearchParams, unflatSearchParams } from './helpers';
import { SearchParamsState } from './types';

export const changeSearchParamsAction = createAction<SearchParamsState>('INSERT_PRODUCTS__CHANGE_SEARCH_PARAMS');
export const initSearchParamsAction = createAction<void>('INSERT_PRODUCTS__INIT_SEARCH_PARAMS');
const updateSearchParamsAction = createAction<SearchParamsState>('INSERT_PRODUCTS__UPDATE_SEARCH_PARAMS');

export const searchParamsDefaultState: SearchParamsState = {
  filters: {
    categoryIds: [],
    brands: [],
    genders: [],
    merchants: [],
    materials: [],
    productTypes: [],
    sections: [],
    colors: [],
    keywords: [],
    onlyAvailable: true,
    onlyOnSale: false,
    priceFrom: undefined,
    priceTo: undefined,
    includeUncategorized: false,
    firstPublishedDate: {
      from: undefined,
      to: undefined,
    },
  },
  query: '',
  order: DEFAULT_ORDER.value,
};

const memoizedSearchParams = memoizeOne((search: string) => parse(search, QUERY_STRING_PARSE_CONFIG));

export const getQueryObjectFromQueryParams = () => {
  const searchParams = memoizedSearchParams(window.location.search);
  return unflatSearchParams(generateQueryObject(FiltersState, searchParams));
};

export const getQueryParamsFromQueryObject = (params: SearchParamsState) =>
  generateQueryParams(FiltersState, flatSearchParams(params));

export const searchParamsReducer = createReducer<SearchParamsState>(
  {
    [updateSearchParamsAction.toString()]: (_: SearchParamsState, payload: SearchParamsState) => payload,
    [authActions.authorizeViaProfileId.success.toString()]: () => getQueryObjectFromQueryParams(),
    [resetFiltersAndResult.toString()]: () => ({ ...searchParamsDefaultState }),
  },
  getQueryObjectFromQueryParams()
);

function* fetchProductsSaga(action: Action<SearchParamsState>) {
  const marketId = yield* getMarketId();

  yield put(fetchProducts.request(action.payload));
  const { search } = yield select((state) => state.router.location);
  const pathName = '/pos/inventory';
  const filtersParams = getQueryParamsFromQueryObject(action.payload);

  const marketParam = `marketId=${marketId}`;
  const newSearch = filtersParams ? `?${filtersParams}&${marketParam}` : `?${marketParam}`;
  if (search !== newSearch) {
    yield put(replace(pathName + newSearch));
  }
}

function* updateParamsSaga(action: Action<SearchParamsState>) {
  yield put(updateSearchParamsAction(action.payload));
}

function* initSearchParamsSaga() {
  const queryParams = getQueryObjectFromQueryParams();
  yield put(updateSearchParamsAction(queryParams));
}

function* syncSearchParamsSaga({ payload }: Action<LocationChangePayload>) {
  if (payload.location.search.length > 0) {
    const queryParams = getQueryObjectFromQueryParams();
    yield put(updateSearchParamsAction(queryParams));
  }
}

export function* searchParamsSaga() {
  yield safeTakeLatest(changeSearchParamsAction, updateParamsSaga);
  yield debounce(500, changeSearchParamsAction, fetchProductsSaga);
  yield safeTakeLatest(initSearchParamsAction, initSearchParamsSaga);
  yield safeTakeLatest(LOCATION_CHANGE, syncSearchParamsSaga);
}
