import { removeTempInitialToken } from '@frontend/commons';
import { Action, createAction } from 'redux-act';

import { call, put } from 'redux-saga/effects';

import { UserAPI, AuthAPI } from 'services/api';

import { CurrentProfileResponse } from 'services/api/user/profile/types-get-current';

import { ProfilesResponse } from 'services/api/user/profile/types-get-user-profiles';

import { getProfiles } from '../common-helpers';
import { AuthState } from '../types';

import { authenticateAction } from './common';

export const authorizeViaInitialTokenAction = createAction<string, AuthState>('AUTH_VIA_INITIAL_TOKEN');

export function* authViaInitialTokenSaga(action: Action<string>) {
  try {
    const initialToken = action.payload;

    const profiles: ProfilesResponse = yield call(UserAPI.getUserProfiles, initialToken);
    const currentPosId = profiles.posProfiles[0]?.posId;
    const currentMerchantId = profiles.merchantProfiles[0]?.id;
    const payload = currentPosId ? { posId: currentPosId } : { merchantId: currentMerchantId };
    // @ts-ignore
    const tokenWithPosOrMerchantContext = yield call(AuthAPI.authorizePosOrMerchant, payload, initialToken);
    const { posProfile, merchantProfile } = yield* getProfiles(
      currentPosId,
      currentMerchantId,
      tokenWithPosOrMerchantContext
    );

    const profile: CurrentProfileResponse = yield call(UserAPI.getCurrentProfile, tokenWithPosOrMerchantContext);

    yield put(
      authenticateAction.success({
        profile,
        token: tokenWithPosOrMerchantContext,
        currentPosId,
        currentMerchantId,
        posProfile,
        merchantProfile,
      })
    );
  } catch (e: any) {
    yield put(authenticateAction.failure(''));
    if (e?.response?.status > 399 && e?.response?.status < 500) {
      removeTempInitialToken();
      return;
    }
    throw e;
  }
}
