import queryString from 'query-string';

export const QUERY_STRING_PARSE_CONFIG: queryString.ParseOptions = {
  arrayFormat: 'bracket-separator',
  parseBooleans: true,
  parseNumbers: true,
};

export const QUERY_STRING_STRINGIFY_CONFIG: queryString.StringifyOptions = {
  arrayFormat: 'bracket-separator',
  skipNull: true,
  skipEmptyString: true,
};
