import { safeTakeLatest } from '@frontend/commons';

import { InvitationsAPI } from 'services/api';
import { UserAPI } from 'services/api/user';
import { createDefaultAsyncHelpers } from 'store/helpers';

async function processAcceptInvitation(invitationId: string) {
  await InvitationsAPI.acceptInvitation(invitationId);
  return UserAPI.getUserProfiles();
}

const {
  reducer: acceptInvitationReducer,
  saga: acceptInvitationDefaultSaga,
  actions: acceptInvitationActions,
} = createDefaultAsyncHelpers({
  fetchFunc: processAcceptInvitation,
  actionName: 'PROFILE_SWITCHER__ACCEPT_INVITATION',
  initialData: undefined,
});

function* acceptInvitationSaga() {
  yield safeTakeLatest(acceptInvitationActions.request, acceptInvitationDefaultSaga);
}

export { acceptInvitationReducer, acceptInvitationActions, acceptInvitationSaga };
