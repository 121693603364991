import { safeTakeLatest } from '@frontend/commons';

import { getMerchantProductStatistics } from 'pages/merchant/merchant-page/store/merchant-product-statitics/service';

import { createDefaultAsyncHelpers } from 'store/helpers';

const {
  saga: fetchMerchantProductStatisticsSaga,
  actions: fetchMerchantProductStatisticsActions,
  reducer,
} = createDefaultAsyncHelpers({
  fetchFunc: getMerchantProductStatistics,
  actionName: 'MERCHANT_PRODUCT_STATISTICS__GET',
  initialData: undefined,
});

const actions = {
  fetchMerchantProductStatisticsAction: fetchMerchantProductStatisticsActions.request,
};

function* saga() {
  yield safeTakeLatest(fetchMerchantProductStatisticsActions.request, fetchMerchantProductStatisticsSaga);
}

export { saga, actions, reducer };
