import { TransformFnParams } from 'class-transformer';

/**
 * Creates a converter that does two things:
 *  - changes 'foo,bar,zar' into array of ['foo', 'bar', 'zar']
 *  - filters values of the array only to be within specific set - `possibleValues` - param
 */
export function createArrayTransformer(possibleValues?: unknown[], cleanEmptyArray: boolean = false) {
  return function (params: TransformFnParams) {
    const { value } = params;
    if (typeof value !== 'string' && !Array.isArray(value)) {
      return [];
    }

    const arrayValue = typeof value === 'string' ? value.split(',') : value;
    if (cleanEmptyArray && !arrayValue.length) {
      return undefined;
    }
    if (possibleValues) {
      return arrayValue.filter((singleArrayValue) => possibleValues.includes(singleArrayValue));
    }
    return arrayValue;
  };
}
