import { createSelector } from 'reselect';

import { categoryTreeCategoriesSelector } from 'store/category-tree/selectors';
import { GlobalState } from 'store/types';
import { FilterGroups } from 'types/filters';

import { KNOWN_SEARCH_FILTERS_ORDERED_BOTTOM, KNOWN_SEARCH_FILTERS_ORDERED_TOP } from './helpers/FILTERS';
import {
  extractKnownSearchFilters,
  extractUnknownSearchFilters,
  generateDepartmentsSearchFilter,
} from './helpers/filters-creators';
import { AvailableMultiSelectFilter } from './types';

const availableFiltersSelector = (state: GlobalState): FilterGroups =>
  state.pages.insertProducts.products.data?.filters ?? [];

export const filtersInitialPendingSelector = (state: GlobalState): boolean =>
  state.categoryTree.initialPending || state.pages.insertProducts.products.initialPending;

const firstPublishedDateFilter: AvailableMultiSelectFilter = {
  available: [],
  count: 0,
  filterName: 'firstPublishedDate',
  searchableLabel: 'first published date',
  label: 'First Published Date',
  name: 'firstPublishedDate',
  value: '',
};

export const availableMultiselectFiltersSelector = createSelector(
  availableFiltersSelector,
  categoryTreeCategoriesSelector,
  (allFilters, categoryTree): AvailableMultiSelectFilter[] => {
    if (!categoryTree) {
      return [];
    }

    const departmentFilters = generateDepartmentsSearchFilter(categoryTree, allFilters);
    const knownFiltersTop = extractKnownSearchFilters(allFilters, KNOWN_SEARCH_FILTERS_ORDERED_TOP).filter(
      (item) => !['departments', 'categories', 'subcategories'].includes(item.name)
    );
    const unknownFilters = extractUnknownSearchFilters(allFilters);
    const knownFiltersBottom = extractKnownSearchFilters(allFilters, KNOWN_SEARCH_FILTERS_ORDERED_BOTTOM).filter(
      (item) => item.name !== 'keywords'
    );

    return [
      ...departmentFilters,
      ...knownFiltersTop,
      ...unknownFilters,
      ...knownFiltersBottom,
      firstPublishedDateFilter,
    ] as AvailableMultiSelectFilter[];
  }
);

export const availableCountedFiltersSelector = createSelector(availableFiltersSelector, (allFilters): FilterGroups => {
  return allFilters.reduce((acc, filterGroup) => {
    const newAvailable = filterGroup.available.filter((a) => !!a.count);

    return [
      ...acc,
      {
        ...filterGroup,
        available: newAvailable,
      },
    ];
  }, [] as FilterGroups);
});
