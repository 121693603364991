import { createSelector } from 'reselect';

import { GlobalState } from 'store/types';

export const profilePosIdsSelector = (state: GlobalState) => state.auth.data?.profile.posIds ?? [];
export const userProfilesSelector = (state: GlobalState) => state.userProfiles;

const getMerchantProfiles = (state: GlobalState) =>
  userProfilesSelector(state).fetchProfiles?.data?.merchantProfiles ?? [];
const getPosProfiles = (state: GlobalState) => userProfilesSelector(state).fetchProfiles?.data?.posProfiles ?? [];
const marketsSelector = (state: GlobalState) => state.markets?.data ?? [];

export const posesDataSelector = createSelector(
  profilePosIdsSelector,
  getPosProfiles,
  marketsSelector,
  (posIds, profiles, markets) =>
    profiles.length > 0
      ? profiles.map((profile) => ({
          ...profile,
          profilePictureId: profile.profilePictureUrl.id,
          wallpaperImageId: profile.wallpaperImage.id,
          marketName: markets.find((m) => m.id === profile.marketId)?.name,
          merchantIntegrationType: undefined,
        }))
      : posIds.map((posId) => ({
          posId,
          displayName: '',
          profilePictureId: undefined,
          wallpaperImageId: undefined,
          marketName: undefined,
          merchantIntegrationType: undefined,
        }))
);

export const merchantProfilesSelector = createSelector(getMerchantProfiles, marketsSelector, (profiles, markets) =>
  profiles.map((profile) => ({
    merchantId: profile.id,
    displayName: profile.companyName,
    profilePictureId: (profile?.images || [])[0]?.id,
    wallpaperImageId: profile?.publicImageName?.name,
    marketName: markets.find((m) => m.id === profile.markets[0])?.name,
    merchantIntegrationType: profile.integrationType ?? undefined,
  }))
);
