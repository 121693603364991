import { createAction, createReducer, Action, ActionCreator } from 'redux-act';
import { put, call } from 'redux-saga/effects';

import { AsyncReturnType } from 'types/async-return-type';

import { processSagaErrorHandlers } from './process-saga-error-handlers';
import { DefaultActions, DefaultError, DefaultAsyncState, SagaCustomErrorHandler } from './types';

const types = {
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

export const createDefaultActions = <RequestPayload, SuccessPayload, FailurePayload = DefaultError>(
  name: string
): DefaultActions<RequestPayload, SuccessPayload, FailurePayload> => ({
  request: createAction<RequestPayload>(`${name}_${types.REQUEST}`),
  success: createAction<SuccessPayload>(`${name}_${types.SUCCESS}`),
  failure: createAction<FailurePayload>(`${name}_${types.FAILURE}`),
});

export const createDefaultSaga = <RequestPayload, SuccessPayload, FailurePayload = DefaultError>(
  func: (arg: RequestPayload) => Promise<SuccessPayload>,
  actions: DefaultActions<RequestPayload, SuccessPayload, FailurePayload>,
  sagaErrorHandlers?: SagaCustomErrorHandler<FailurePayload>[]
) => {
  return function* (action: Action<RequestPayload>) {
    try {
      const data: SuccessPayload = yield call(func, action.payload);
      yield put(actions.success(data));
    } catch (e: any) {
      if (sagaErrorHandlers) {
        const { isHandled, failurePayload }: AsyncReturnType<typeof processSagaErrorHandlers> =
          yield processSagaErrorHandlers(e, sagaErrorHandlers);
        if (isHandled) {
          yield put(actions.failure(failurePayload));
          return;
        }
      }

      yield put(actions.failure(e));
      throw e;
    }
  };
};

export const getInitialState = <SuccessPayload, InitialData, FailurePayload = DefaultError>(
  data: InitialData
): DefaultAsyncState<SuccessPayload, InitialData, FailurePayload> => ({
  data,
  pending: false,
  error: undefined,
  initialPending: false,
  pristine: true,
});

export const createDefaultReducer = <
  RequestPayload,
  SuccessPayload,
  InitialData = SuccessPayload,
  FailurePayload = DefaultError
>(
  actions: DefaultActions<RequestPayload, SuccessPayload, FailurePayload>,
  initialData: InitialData,
  cleanAction?: ActionCreator<any>
) =>
  createReducer<DefaultAsyncState<SuccessPayload, InitialData, FailurePayload>>(
    {
      [actions.request.toString()]: (state) => ({
        data: state.data,
        error: undefined,
        pending: true,
        initialPending: state.data === initialData,
        pristine: false,
      }),
      [actions.success.toString()]: (_, payload) => ({
        pending: false,
        error: undefined,
        data: payload,
        initialPending: false,
        pristine: false,
      }),
      [actions.failure.toString()]: (_, payload) => ({
        pending: false,
        data: initialData,
        error: payload,
        initialPending: false,
        pristine: false,
      }),
      ...(cleanAction
        ? {
            [cleanAction.toString()]: () => getInitialState(initialData),
          }
        : {}),
    },
    getInitialState<SuccessPayload, InitialData, FailurePayload>(initialData)
  );

export function createDefaultAsyncHelpers<
  SuccessPayload,
  RequestPayload = void,
  InitialData = SuccessPayload,
  FailurePayload = DefaultError
>({
  actionName,
  initialData,
  fetchFunc,
  cleanAction,
  sagaErrorHandlers,
}: {
  actionName: string;
  initialData: InitialData;
  fetchFunc: ((arg: RequestPayload) => Promise<SuccessPayload>) | (() => Promise<SuccessPayload>);
  cleanAction?: ActionCreator<any>;
  sagaErrorHandlers?: SagaCustomErrorHandler<FailurePayload>[];
}) {
  const actions = createDefaultActions<RequestPayload, SuccessPayload, FailurePayload>(actionName);
  const reducer = createDefaultReducer<RequestPayload, SuccessPayload, InitialData, FailurePayload>(
    actions,
    initialData,
    cleanAction
  );
  const saga = createDefaultSaga<RequestPayload, SuccessPayload, FailurePayload>(fetchFunc, actions, sagaErrorHandlers);
  const initialState = getInitialState<SuccessPayload, InitialData, FailurePayload>(initialData);

  return {
    actions,
    reducer,
    saga,
    initialState,
  };
}

export const createSagalessDefaultAsyncHelpers = <
  RequestPayload,
  SuccessPayload,
  InitialData = SuccessPayload,
  FailurePayload = DefaultError
>({
  actionName,
  initialData,
  cleanAction,
}: {
  actionName: string;
  initialData: InitialData;
  cleanAction?: ActionCreator<any>;
  sagaErrorHandlers?: SagaCustomErrorHandler<FailurePayload>[];
}) => {
  const actions = createDefaultActions<RequestPayload, SuccessPayload, FailurePayload>(actionName);
  const reducer = createDefaultReducer<RequestPayload, SuccessPayload, InitialData, FailurePayload>(
    actions,
    initialData,
    cleanAction
  );
  const initialState = getInitialState<SuccessPayload, InitialData, FailurePayload>(initialData);

  return {
    actions,
    reducer,
    initialState,
  };
};
