import clsx from 'clsx';
import React, { FC } from 'react';

import { ComponentPropsType } from '../../../types/component-props-type';

import { SimpleSpinner, SpinnerSize } from '../../atoms/simple-spinner';

import styles from './spinner-styles.module.scss';

type Props = {
  loading: boolean;
} & ComponentPropsType<typeof SimpleSpinner>;

const spinnerContainerSizeToClass: Record<SpinnerSize, string> = {
  '12px': styles.spinnerContainerSize12px,
  '50px': styles.spinnerContainerSize50px,
  '24px': styles.spinnerContainerSize24px,
};

export const Spinner: FC<Props> = (props) => {
  const { size = '50px', children, loading, fadeIn = 'instant' } = props;

  return (
    <div className={clsx(styles.spinnerContainer, spinnerContainerSizeToClass[size])}>
      {children}
      {loading && (
        <div
          className={clsx(styles.overlay, {
            [styles.overlayDelayedFadeIn]: fadeIn === 'delayed',
            [styles.instantFadeIn]: fadeIn === 'instant',
          })}
        />
      )}
      {loading && <SimpleSpinner className={styles.spinner} fadeIn={fadeIn} overlay={false} size={size} />}
    </div>
  );
};
