import { AnyAction } from 'redux';
import { createReducer } from 'redux-act';

import { Collection } from 'services/api/collections/types';
import { authActions } from 'store/auth';
import { createDefaultReducer, getInitialState } from 'store/helpers';

import { collectionsActions } from './actions';
import { sharedCollectionPersistOrder } from './ordering';
import { CollectionsState, State } from './types';

const fetchCollectionsBaseReducer = createDefaultReducer(
  collectionsActions.fetchCollections,
  undefined,
  authActions.authorizeViaProfileId.success
);

const fetchCollectionsExtraReducer = createReducer<State>({}, getInitialState<CollectionsState, undefined>(undefined));
fetchCollectionsExtraReducer.on(collectionsActions.createCollection.request, (state) => ({
  ...state,
  pending: true,
}));
fetchCollectionsExtraReducer.on(collectionsActions.createCollection.success, (state) => ({
  ...state,
  pending: false,
}));
fetchCollectionsExtraReducer.on(collectionsActions.createCollection.failure, (state, payload) => ({
  ...state,
  pending: false,
  error: payload.error,
}));
fetchCollectionsExtraReducer.on(collectionsActions.update.success, (state) => {
  // api endpoint that updates collection name does not return new collection
  return {
    ...state,
    pending: false,
    error: undefined,
  };
});
fetchCollectionsExtraReducer.on(collectionsActions.setCollectionsInStore.success, (state) => ({
  ...state,
  pending: false,
}));

fetchCollectionsExtraReducer.on(sharedCollectionPersistOrder.reorderAndPersistSuccessAction, (state, payload) => {
  if (!state.data) {
    return state;
  }

  const storeCollections = state.data.store.collections;
  const newStoreCollections = payload.ids.reduce<Collection[]>((acc, id) => {
    const foundStoreCollection = storeCollections.find((collection: Collection) => collection.id === id);
    if (foundStoreCollection) {
      acc.push(foundStoreCollection);
    }
    return acc;
  }, []);

  return {
    ...state,
    data: {
      ...state.data,
      store: {
        ...state.data.store,
        collections: newStoreCollections,
      },
    },
  };
});

fetchCollectionsExtraReducer.on(collectionsActions.removeProductByCollectionItemId.request, (state) => ({
  ...state,
  pending: true,
}));
fetchCollectionsExtraReducer.on(collectionsActions.removeProductByCollectionItemId.success, (state) => ({
  ...state,
  pending: false,
}));

export const reducer = (state: State, action: AnyAction): State =>
  fetchCollectionsExtraReducer(fetchCollectionsBaseReducer(state, action), action);
