import { Type, Transform } from 'class-transformer';

import { createArrayTransformer, dateTransformers } from 'helpers/query-params-sync';

import { SearchParamsState } from './types';

export class FiltersState {
  @Transform(createArrayTransformer(undefined, true), { toClassOnly: true })
  categoryIds: SearchParamsState['filters']['categoryIds'] = [];

  @Transform(createArrayTransformer(undefined, true), { toClassOnly: true })
  brands: SearchParamsState['filters']['brands'] = [];

  @Transform(createArrayTransformer(undefined, true), { toClassOnly: true })
  colors: SearchParamsState['filters']['colors'] = [];

  @Transform(createArrayTransformer(undefined, true), { toClassOnly: true })
  genders: SearchParamsState['filters']['genders'] = [];

  @Transform(createArrayTransformer(undefined, true), { toClassOnly: true })
  productTypes: SearchParamsState['filters']['productTypes'] = [];

  @Transform(createArrayTransformer(undefined, true), { toClassOnly: true })
  sections: SearchParamsState['filters']['sections'] = [];

  @Transform(createArrayTransformer(undefined, true), { toClassOnly: true })
  merchants: SearchParamsState['filters']['merchants'] = [];

  @Transform(createArrayTransformer(undefined, true), { toClassOnly: true })
  materials: SearchParamsState['filters']['materials'] = [];

  @Transform(createArrayTransformer(undefined, true), { toClassOnly: true })
  keywords: SearchParamsState['filters']['keywords'] = [];

  @Type(() => Boolean)
  onlyOnSale: SearchParamsState['filters']['onlyOnSale'] = false;

  @Type(() => Boolean)
  includeUncategorized: SearchParamsState['filters']['includeUncategorized'] = false;

  @Type(() => Boolean)
  onlyAvailable: SearchParamsState['filters']['onlyAvailable'] = true;

  @Type(() => String)
  priceFrom: SearchParamsState['filters']['priceFrom'] = '';

  @Type(() => String)
  priceTo: SearchParamsState['filters']['priceTo'] = '';

  @Transform(dateTransformers.toString, { toPlainOnly: true })
  @Transform(dateTransformers.toDate, { toClassOnly: true })
  publishedDateFrom: SearchParamsState['filters']['firstPublishedDate']['from'] = undefined;

  @Transform(dateTransformers.toString, { toPlainOnly: true })
  @Transform(dateTransformers.toDate, { toClassOnly: true })
  publishedDateTo: SearchParamsState['filters']['firstPublishedDate']['to'] = undefined;

  @Type(() => String)
  query: SearchParamsState['query'] = '';

  @Type(() => String)
  orderDir: SearchParamsState['order']['direction'] = 'ASC';

  @Type(() => String)
  orderName: SearchParamsState['order']['name'] = 'relevance';
}
