const tipserOriginEnvToShortEnvMap = {
  production: 'prod',
  development: 'dev',
  staging: 'stage',
};

export function getSentryEnv() {
  return process.env.REACT_APP_SENTRY_ENV;
}

export function getSentryBuildId() {
  return process.env.REACT_APP_VERSION;
}

export function getApiUrl() {
  return process.env.REACT_APP_API_URL as string;
}

export function getPortalApiUrl() {
  return process.env.REACT_APP_PORTAL_API_URL as string;
}

export function getShortTipserOriginEnv() {
  return tipserOriginEnvToShortEnvMap[process.env.REACT_APP_TIPSER_ORIGIN_ENV as string];
}

export function getCloudinaryCloudName() {
  return process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
}
