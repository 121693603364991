import { get } from '../base';

import { IndexResponse, CategoryPropertiesResponse } from './types';

export const CategoriesAPI = {
  index(includeUncategorized = false) {
    const searchPart = includeUncategorized ? '?includeUncategorized=true' : '';
    return get(`v5/categories${searchPart}`).json<IndexResponse>();
  },
  properties(marketId: string): Promise<CategoryPropertiesResponse> {
    return get(`v5/categories/properties?MarketId=${marketId}`).json();
  },
};
