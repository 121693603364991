import { safeTakeEvery } from '@frontend/commons';

import { ignoreSaga404Error } from 'store/helpers';
import { createDefaultMultiAsyncHelpers } from 'store/helpers/default-multi-async-helpers';

import { getCampaignProposalDetails } from './fetch-campaign-proposal-service';

export const {
  actions: fetchCampaignProposalActions,
  reducer,
  saga: fetchCampaignProposalSaga,
} = createDefaultMultiAsyncHelpers('MERCHANT_CAMPAIGN_PROPOSAL__FETCH', getCampaignProposalDetails, [
  ignoreSaga404Error,
]);

export function* saga() {
  yield safeTakeEvery(fetchCampaignProposalActions.request, fetchCampaignProposalSaga);
}
