import { safeTakeLatest } from '@frontend/commons';

import { createAction } from 'redux-act';

import { getMerchantsDiscovery } from 'services/api/pos/merchants-discovery/get-merchants-discovery';
import { createDefaultAsyncHelpers } from 'store/helpers';

export const resetMerchantDiscovery = createAction('MERCHANT_DISCOVERY__RESET');

const {
  reducer,
  actions: fetchMerchantsDiscoveryActions,
  saga: fetchMerchantsDiscoverySaga,
} = createDefaultAsyncHelpers({
  actionName: 'MERCHANT_DISCOVERY__FETCH',
  fetchFunc: getMerchantsDiscovery,
  initialData: null,
  cleanAction: resetMerchantDiscovery,
});

const actions = {
  fetchMerchantsDiscovery: fetchMerchantsDiscoveryActions.request,
  resetMerchantDiscovery,
};

function* saga() {
  yield safeTakeLatest(fetchMerchantsDiscoveryActions.request, fetchMerchantsDiscoverySaga);
}

export { saga, actions, reducer };
