import React, { FC } from 'react';

import { AuthLink } from 'components/atoms/auth-link';
import { Content404 } from 'components/molecules/404-content';
import { AuthPage } from 'components/templates/auth-page';
import { is404DecorationOn } from 'helpers/feature-flags';

import travoltaUrl from './assets/travolta-crunched.gif';
import styles from './page404-styles.module.scss';

export const Page404: FC = () => {
  return (
    <>
      <AuthPage photoClassName={styles.photo}>
        <div className={styles.page404Container}>
          <Content404 />
          <p>
            <AuthLink to="/login">Click here to log in into Tipser Portal</AuthLink>
          </p>
        </div>
      </AuthPage>
      {is404DecorationOn() && <img className={styles.travolta} src={travoltaUrl} alt="" />}
    </>
  );
};
