import { safeTakeLatest } from '@frontend/commons';

import { createDefaultAsyncHelpers } from 'store/helpers';

import { fetchAllPosMerchantData } from './service';

const {
  reducer,
  actions: fetchPosMerchantActions,
  saga: fetchPosMerchantSaga,
} = createDefaultAsyncHelpers({
  actionName: 'MERCHANT_DISCOVERY_POS_MERCHANT_DATA__FETCH',
  fetchFunc: fetchAllPosMerchantData,
  initialData: null,
});

const actions = {
  fetchPosMerchantAction: fetchPosMerchantActions.request,
};

function* saga() {
  yield safeTakeLatest(fetchPosMerchantActions.request, fetchPosMerchantSaga);
}

export { saga, actions, reducer };
