import { useCallback, useEffect, RefObject, useRef, useState } from 'react';

export function useToggle(method: Function, value: boolean) {
  return useCallback(() => {
    method(!value);
  }, [method, value]);
}

export function useOutsideClickListener(
  containerRef: RefObject<HTMLElement>,
  callback: () => void,
  startToWatch = true,
  useCapture = true
) {
  const handleOutsideClick = useCallback(
    (e) => {
      if (!containerRef.current?.contains(e.target) || containerRef.current === e.target) {
        callback();
      }
    },
    [containerRef, callback]
  );

  useEffect(() => {
    if (startToWatch) {
      document.addEventListener('click', handleOutsideClick, useCapture);
    } else {
      document.removeEventListener('click', handleOutsideClick, useCapture);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick, useCapture);
    };
  }, [startToWatch, useCapture, handleOutsideClick]);
}

export function useTriggerFalse(method: Function) {
  return useCallback(() => {
    method(false);
  }, [method]);
}

export function useTriggerTrue(method: Function) {
  return useCallback(() => {
    method(true);
  }, [method]);
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export function useSelectedOption<Suggestion>(options: Suggestion[], shouldSelectedFirstAtInit = false) {
  const [selectedOption, setSelectedOption] = useState<Suggestion | null>(null);

  const moveSelection = useCallback(
    (mod: number) => {
      if (!options) {
        return;
      }
      const currentIndex = options.findIndex((option) => option === selectedOption);
      const newIndex = Math.min(Math.max(0, currentIndex + mod), options.length - 1);
      setSelectedOption(options[newIndex]);
    },
    [setSelectedOption, selectedOption, options]
  );

  const moveSelectionUp = useCallback(() => moveSelection(-1), [moveSelection]);
  const moveSelectionDown = useCallback(() => moveSelection(1), [moveSelection]);

  const resetSelection = useCallback(() => {
    setSelectedOption(options?.[0]);
  }, [setSelectedOption, options]);

  useEffect(() => {
    if (options && shouldSelectedFirstAtInit) {
      setSelectedOption((prevSelectedOption) => {
        if (!prevSelectedOption || !options.some((option) => option === prevSelectedOption)) {
          return options[0];
        }

        return prevSelectedOption;
      });
    }
  }, [options, setSelectedOption, shouldSelectedFirstAtInit]);

  return {
    currentlySelected: selectedOption,
    onSelectUp: moveSelectionUp,
    onSelectDown: moveSelectionDown,
    resetSelection,
  };
}
