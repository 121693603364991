import { TransformFnParams } from 'class-transformer';
import { format, parse } from 'date-fns';

import { DATE_ONLY_FORMAT } from 'constants/DATE';

export const dateTransformers = {
  toString(params: TransformFnParams) {
    const { value } = params;

    if (!(value instanceof Date)) {
      return undefined;
    }
    return format(value, DATE_ONLY_FORMAT);
  },

  toDate(params: TransformFnParams) {
    const { value } = params;

    if (value instanceof Date) {
      return value;
    }

    if (typeof value !== 'string') {
      return undefined;
    }

    try {
      return parse(value, DATE_ONLY_FORMAT, new Date());
    } catch (e) {
      return undefined;
    }
  },
};
