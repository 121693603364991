import { addDays, format } from 'date-fns';
import queryString from 'query-string';
import { select, call } from 'redux-saga/effects';

import { DATE_ONLY_FORMAT } from 'constants/DATE';
import { ProductsSearchPayload } from 'services/api/pos/products/types-search-for-products';
import { posMarketSelector } from 'store/auth';
import { marketsSelector } from 'store/markets/selectors';

import { SearchParamsState } from '../search-params/types';

/**
 * It gets market id from following places:
 * - market query string parameters (transformed into marketId)
 * - marketId query string parameter
 * - marketId for current pos
 *
 * Order is relevant -> first valid market id is accepted.
 */
export function* getMarketId() {
  const availableMarkets = marketsSelector(yield select());
  const userMarket = posMarketSelector(yield select());
  const { market: marketFromQS, marketId: marketIdFromQS } = queryString.parse(window.location.search);
  const parsedMarketFromQS = Array.isArray(marketFromQS) ? marketFromQS[0] : marketFromQS;
  const parsedMarketIdFromQS = Array.isArray(marketIdFromQS) ? marketIdFromQS[0] : marketIdFromQS;

  const parsedMarketNameOrMarkedId = parsedMarketFromQS || parsedMarketIdFromQS;

  if (typeof parsedMarketNameOrMarkedId === 'string' && parsedMarketNameOrMarkedId !== '') {
    const foundMarket = availableMarkets.find(
      (marketItem) =>
        marketItem.id === parsedMarketNameOrMarkedId ||
        marketItem.name?.toLocaleLowerCase() === parsedMarketNameOrMarkedId.toLowerCase()
    );

    if (foundMarket != null) {
      return foundMarket.id;
    }
  }

  return userMarket;
}

function transformPriceRangeFromStringToNumber(priceFromString?: string, priceToString?: string) {
  const priceFrom = Number(priceFromString) || undefined;
  const priceTo = Number(priceToString) || undefined;
  return { priceFrom, priceTo };
}

function formatDateToApi(date?: Date) {
  if (!date) {
    return undefined;
  }

  return format(date, DATE_ONLY_FORMAT);
}

export const productsSearchPayloadConverter = {
  fromParams: ({
    params,
    marketId,
    skip = 0,
  }: {
    params: SearchParamsState;
    marketId: string;
    skip?: number;
  }): ProductsSearchPayload => {
    const { query, filters, ...restParams } = params;
    const { priceFrom, priceTo, firstPublishedDate, ...restFilters } = filters;
    const publishedToInclusive = firstPublishedDate.to ? addDays(firstPublishedDate.to, 1) : undefined;

    return {
      ...restParams,
      filters: {
        ...restFilters,
        ...transformPriceRangeFromStringToNumber(priceFrom, priceTo),
        firstPublishedDate: {
          to: formatDateToApi(publishedToInclusive),
          from: formatDateToApi(firstPublishedDate.from),
        },
      },
      textSearch: {
        query,
        fields: [
          { name: 'attributes' },
          { name: 'brandName' },
          { name: 'description' },
          { name: 'merchantCategories' },
          { name: 'merchantName' },
          { name: 'merchantProductKey' },
          { name: 'merchantProductMaster' },
          { name: 'SKU' },
          { name: 'title' },
        ],
      },
      marketId,
      skip,
      limit: 40,
    };
  },
};

export function* scrollWindowToTop() {
  yield call(() =>
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  );
}
