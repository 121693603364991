import { CollectionsAPI } from 'services/api/collections';

export async function removeCollection({
  collectionId,
  isPrivate,
}: Parameters<typeof CollectionsAPI.removeCollection>[0]): Promise<{ collectionId: string; isPrivate: boolean }> {
  await CollectionsAPI.removeCollection({ collectionId, isPrivate });

  return {
    collectionId,
    isPrivate,
  };
}
