import { ProductFetchErrorPayload } from '../product-customization/types';

export const handleProductFetchError = async (e: any, next: () => undefined) => {
  if (e?.response?.status === 404) {
    const productIdMatch: [string] | undefined = e.request.url.match(/([0-9a-h])+$/);
    const [productId] = productIdMatch || [''];

    return {
      productId,
      errorType: 'product-not-exists' as const,
    } as ProductFetchErrorPayload;
  }

  return next();
};
