import { patch } from '../base';

export async function updateCollection({
  collectionId,
  name,
  isPrivate,
}: {
  collectionId: string;
  name: string;
  isPrivate: boolean;
}): Promise<void> {
  const updateContext = isPrivate ? 'users' : 'pos';
  await patch(`v5/${updateContext}/collections/${collectionId}`, { json: { name } });
}
