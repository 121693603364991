import { getCurrencyByMarket, getLocaleByMarket } from '@tipser/markets';

export type MoneyFormatter = (value: number | string | undefined | null) => string;
export type NumberFormatter = (value: number | string | undefined | null, fractionDigits: number) => string;

export function createMoneyFormatter(market: string): MoneyFormatter {
  const locale = getLocaleByMarket(market);
  const currency = getCurrencyByMarket(market);
  return (value: number | string | undefined | null) =>
    (value || 0).toLocaleString(locale, { style: 'currency', currency });
}

export function createNumberFormatter(market: string): NumberFormatter {
  const locale = getLocaleByMarket(market);
  return (value: number | string | undefined | null, fractionDigits: number) =>
    (value || 0).toLocaleString(locale, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    });
}
