import { ClockType } from '@frontend/shared';
import { createSelector } from 'reselect';

import { USER_CUSTOMER_DATA } from 'constants/USER_CLAIMS';

import { useSelector } from 'store/helpers';

import {
  currentPosIdSelector,
  getFallbackPosId,
  getUserDateFormat,
  getUserTimeFormat,
  posMarketSelector,
  posProfileSelector,
  profileSelector,
} from './selectors';

const AM_PM_INDICATOR = 'aa';

export const useCurrentPosId = () => useSelector(currentPosIdSelector);
export const useFallbackPosId = () => useSelector(getFallbackPosId);
export const usePosProfile = () => useSelector(posProfileSelector);
export const usePosId = () => useSelector(posProfileSelector)?.posId;
export const usePosMarket = () => useSelector(posMarketSelector);
export const useCurrentUserProfile = () => useSelector(profileSelector);

export const useUserDateFnsFormat = () => useSelector(getUserDateFormat);

const userTimeFormatSelector = createSelector(getUserTimeFormat, (formatPattern) =>
  formatPattern?.replace('tt', AM_PM_INDICATOR)
);

export const useUserTimeFnsFormat = () => useSelector(userTimeFormatSelector);

const userDateTimeSelector = createSelector(
  getUserDateFormat,
  userTimeFormatSelector,
  (dateFormat, timeFormat) => `${dateFormat} ${timeFormat}`
);

export const useUserDateTimeFnsFormat = () => useSelector(userDateTimeSelector);

const userClockSelector = createSelector(userTimeFormatSelector, (time) => {
  const is12h = time == null ? false : time.indexOf(AM_PM_INDICATOR) > 0;
  return is12h ? 12 : 24;
});

export const useClockType: () => ClockType = () => useSelector(userClockSelector);

export function useHasMerchantCustomerDataViewerClaim() {
  const hasMerchantCustomerDataViewerClaimSelector = createSelector(
    profileSelector,
    (profile) => !!profile?.claims.includes(USER_CUSTOMER_DATA)
  );

  return useSelector(hasMerchantCustomerDataViewerClaimSelector);
}
