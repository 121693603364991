import { PosMerchantResponse } from 'services/api/pos/merchants';
import { getPosMerchant } from 'services/api/pos/merchants/get-merchant';
import { getPosMerchantBestSellers } from 'services/api/pos/merchants/get-merchant-best-sellers';
import { getPosMerchantLatestProducts } from 'services/api/pos/merchants/get-merchant-latest-products';
import { getPosMerchantProductStatistics } from 'services/api/pos/merchants/get-product-statistics';

import { MerchantProductStatisticsResponse } from 'services/api/pos/merchants/types-product-statistics';
import { LatestProductsResponse } from 'types/latest-products';
import { MerchantBestSellersResponse } from 'types/merchant-best-sellers';

export async function fetchAllPosMerchantData(merchantId: string): Promise<{
  merchant: PosMerchantResponse;
  products: MerchantProductStatisticsResponse;
  bestSellers: MerchantBestSellersResponse;
  latestProducts: LatestProductsResponse;
}> {
  const merchantPromise = getPosMerchant(merchantId);
  const productsPromise = getPosMerchantProductStatistics(merchantId);
  const bestSellersPromise = getPosMerchantBestSellers({ id: merchantId, count: 20 });
  const latestProductsPromise = getPosMerchantLatestProducts({ id: merchantId, count: 20 });
  const [merchant, products, bestSellers, latestProducts] = await Promise.all([
    merchantPromise,
    productsPromise,
    bestSellersPromise,
    latestProductsPromise,
  ]);
  return { merchant, products, bestSellers, latestProducts };
}
