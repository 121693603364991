import { OldCollection } from 'services/api/collections/types';
import { createDefaultActions } from 'store/helpers';

import { AddProductAndFetchStockCountResponse, RemoveProductAndFetchStockCountResponse } from './services';
import {
  CollectionsState,
  RemoveProductByCollectionItemIdFromCollectionPayload,
  RemoveProductByProductIdFromCollectionPayload,
  AddProductsToCollectionPayload,
  UpdateCollectionPayload,
  SetStoreCollectionPayload,
  CreateCollectionRequest,
  BatchProductToCollectionPayload,
} from './types';

const createCollectionAction = createDefaultActions<CreateCollectionRequest, void>('COLLECTION__CREATE');
const fetchCollectionsActions = createDefaultActions<void, CollectionsState>('COLLECTIONS__FETCH');
const removeProductByCollectionItemIdFromCollectionActions = createDefaultActions<
  RemoveProductByCollectionItemIdFromCollectionPayload,
  RemoveProductAndFetchStockCountResponse
>('COLLECTIONS__REMOVE_PRODUCT_BY_COLLECTION_ITEM_ID');
const removeProductByProductIdFromCollectionActions = createDefaultActions<
  RemoveProductByProductIdFromCollectionPayload,
  RemoveProductAndFetchStockCountResponse
>('COLLECTIONS__REMOVE_PRODUCT_BY_PRODUCT_ID');
const addProductToCollectionActions =
  createDefaultActions<AddProductsToCollectionPayload, AddProductAndFetchStockCountResponse>(
    'COLLECTIONS__ADD_PRODUCT'
  );
const batchOnProductToCollectionActions = createDefaultActions<BatchProductToCollectionPayload, void>(
  'COLLECTIONS__BATCH_ADD/REMOVE_PRODUCT'
);

const updateCollectionActions = createDefaultActions<
  UpdateCollectionPayload,
  { data: OldCollection[]; isPrivate: boolean }
>('COLLECTIONS__UPDATE_COLLECTION');
const setCollectionsInStoreActions = createDefaultActions<SetStoreCollectionPayload, void>(
  'COLLECTIONS__SET_STORE_COLLECTIONS'
);

export const collectionsActions = {
  createCollection: createCollectionAction,
  fetchCollections: fetchCollectionsActions,
  removeProductByCollectionItemId: removeProductByCollectionItemIdFromCollectionActions,
  removeProductbyProductId: removeProductByProductIdFromCollectionActions,
  addProduct: addProductToCollectionActions,
  batchOnProduct: batchOnProductToCollectionActions,
  update: updateCollectionActions,
  setCollectionsInStore: setCollectionsInStoreActions,
};
