import { all } from 'redux-saga/effects';

import { saga as organismTokenOutOfSyncSaga } from 'components/organisms/local-error-modals/token-out-of-sync-modal/store/duck';
import { saga as organismMerchantActivationTopBarSaga } from 'components/organisms/merchant-activation-top-bar/store';

import { saga as pageInvitationSaga } from 'pages/auth/invitation/store/duck';
import { saga as pageMerchantAnalyticsSaga } from 'pages/merchant/analytics-dashboard/store';
import { saga as campaignProposalSaga } from 'pages/merchant/campaign-single/store';
import { saga as pageMerchantCampaignsSaga } from 'pages/merchant/campaigns/store';
import { saga as pageMerchantSaga } from 'pages/merchant/merchant-page/store/duck';
import { saga as pageMerchantOrderSaga } from 'pages/merchant/order-details/duck';
import { saga as pageMerchantOrdersSaga } from 'pages/merchant/orders/duck';
import { saga as pageMerchantBrandsSaga } from 'pages/merchant/product-single/store';
import { saga as pageMerchantProductsSaga } from 'pages/merchant/products/duck';
import { saga as pageMerchantSalesSaga } from 'pages/merchant/sales/duck';

import { saga as pagePosAnalyticsSaga } from 'pages/pos/analytics-dashboard/store';
import { saga as pageBrandsSaga } from 'pages/pos/brands/duck';
import { saga as pagePosCampaignSingleSaga } from 'pages/pos/campaign-single/store';
import { saga as pagePosCampaignsSaga } from 'pages/pos/campaigns/store';
import { saga as pagePosHomeSaga } from 'pages/pos/home/store';
import { saga as pageInsertProductsSaga } from 'pages/pos/insert-products/store';
import { saga as pagePosCategoryMerchantsSaga } from 'pages/pos/merchant-gallery/category-merchants/store';
import { saga as pagePosDiscoverMerchantsSaga } from 'pages/pos/merchant-gallery/discover-merchants/store';
import { saga as pagePosDiscoverySettingsSaga } from 'pages/pos/merchant-gallery/preferences/store';
import { saga as pagePosDiscoverySearchResultsSaga } from 'pages/pos/merchant-gallery/search-results/store';
import { saga as pageSingleMerchantSaga } from 'pages/pos/merchant-gallery/single-merchant/store';
import { saga as pagePosSalesSaga } from 'pages/pos/sales/duck';
import { saga as pageProfileSwitcherSaga } from 'pages/profile-switcher/store/duck';
import { saga as pageUserSettingsSaga } from 'pages/user/settings/store/duck';

import { saga as authSaga } from 'store/auth/duck';
import { saga as categoryTreeSaga } from 'store/category-tree';
import { removeCollectionSaga, saga as collectionsSaga } from 'store/collections';
import { saga as collectionsProductsAvailabilitySaga } from 'store/collections-products-availability';
import { saga as initSaga } from 'store/init/duck';
import { saga as marketsSaga } from 'store/markets';
import { saga as merchantPosesSaga } from 'store/merchant-poses';
import { saga as pageMerchantSettingsSaga } from 'store/merchant-settings';
import { saga as metadataSaga } from 'store/metadata';
import { saga as productsCollectionsSaga } from 'store/products-collections/duck';
import { saga as userProfilesSaga } from 'store/user-profiles';

export function* rootSaga() {
  yield all([
    // Global Sagas
    initSaga(),
    authSaga(),
    collectionsSaga(),
    removeCollectionSaga(),
    collectionsProductsAvailabilitySaga(),
    productsCollectionsSaga(),
    marketsSaga(),
    userProfilesSaga(),
    metadataSaga(),
    categoryTreeSaga(),

    // Local Sagas - Page
    pageBrandsSaga(),
    pageInsertProductsSaga(),
    pageMerchantSettingsSaga(),
    pageMerchantProductsSaga(),
    pageMerchantBrandsSaga(),
    pageMerchantSalesSaga(),
    pageMerchantOrdersSaga(),
    pageMerchantOrderSaga(),
    pageMerchantSaga(),
    pageProfileSwitcherSaga(),
    pageMerchantAnalyticsSaga(),
    pagePosSalesSaga(),
    pagePosAnalyticsSaga(),
    pagePosHomeSaga(),
    pagePosCampaignsSaga(),
    pageMerchantCampaignsSaga(),
    pageUserSettingsSaga(),
    pageInvitationSaga(),
    pagePosCampaignSingleSaga(),
    pagePosDiscoverySearchResultsSaga(),
    pageSingleMerchantSaga(),
    pagePosDiscoverySettingsSaga(),
    pagePosDiscoverMerchantsSaga(),
    pagePosCategoryMerchantsSaga(),

    // Local Saga - Components
    organismMerchantActivationTopBarSaga(),
    organismTokenOutOfSyncSaga(),
    merchantPosesSaga(),
    campaignProposalSaga(),
  ]);
}
