import { useCallback } from 'react';

let onErrorCallbacks: ((error: any) => void)[] = [];

export function registerHandlerOnCallbackError(callback: (error: any) => void) {
  onErrorCallbacks.push(callback);
}

export function removeHandlerOnCallbackError(callback: (error: any) => void) {
  onErrorCallbacks = onErrorCallbacks.filter((x) => x !== callback);
}

function callOnErrorCallbacks(error: any) {
  onErrorCallbacks.map((onErrorCallback) => onErrorCallback(error));
}

async function runCallbackSafely<U extends any[]>(
  callbackToWrap: (...args: U) => Promise<void>,
  onErrorCallback?: () => void,
  ...arg: U
) {
  try {
    await callbackToWrap(...arg);
  } catch (e) {
    console.error(e);
    onErrorCallback?.();
    callOnErrorCallbacks(e);
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function useSafeCallback_DEPRECATED(
  {
    callback,
    onErrorCallback,
  }: {
    callback: () => Promise<void>;
    onErrorCallback?: () => void;
  },
  deps: any
) {
  const safeCallback = useCallback(() => runCallbackSafely(callback, onErrorCallback), [callback, onErrorCallback]);
  return useCallback(safeCallback, [...deps, safeCallback]);
}

export function useSafeCallback<U extends any[]>(callback: (...args: U) => Promise<void>) {
  return useCallback((...args: U) => runCallbackSafely(callback, undefined, ...args), [callback]);
}
