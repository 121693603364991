import cookies from 'js-cookie';
import { capitalize } from 'lodash';

import { getShortTipserOriginEnv } from './env-helpers';

const AUTH_TOKEN = 'authToken';
const SESSION_COOKIE_KEY = 'tipserToken';
const PROFILE_ID_COOKIE_KEY = 'tipserProfileId';
const PROFILE_TYPE_COOKIE_KEY = 'tipserProfileType';
const CONSENT_COOKIE_KEY = 'tipserConsentCookie';
const YEAR_IN_DAYS = 365;

export function setAuthToken(token: string) {
  cookies.set(SESSION_COOKIE_KEY, token, { expires: YEAR_IN_DAYS });
}

export function setProfile(userType: 'pos' | 'merchant', profileId: string) {
  cookies.set(PROFILE_TYPE_COOKIE_KEY, userType, { expires: YEAR_IN_DAYS });
  cookies.set(PROFILE_ID_COOKIE_KEY, profileId, { expires: YEAR_IN_DAYS });
}

export function getAuthToken() {
  return cookies.get(SESSION_COOKIE_KEY);
}

export function setBasicAuthToken(token: string) {
  cookies.set(AUTH_TOKEN, token);
}

export function getBasicAuthToken() {
  return cookies.get(AUTH_TOKEN);
}

export function removeBasicAuthToken() {
  cookies.remove(AUTH_TOKEN);
}

export function setTempInitialToken(token: string) {
  const envName = capitalize(getShortTipserOriginEnv());
  const cookieName = `tempGlobal${envName}TipserToken`;
  cookies.set(cookieName, token, { domain: 'tipser.com', expires: 1 });
}

export function getTempInitialToken() {
  const envName = capitalize(getShortTipserOriginEnv());
  const cookieName = `tempGlobal${envName}TipserToken`;
  return cookies.get(cookieName);
}

export function getProfile(): null | { profileId: string; profileType: string } {
  const profileId = cookies.get(PROFILE_ID_COOKIE_KEY);
  const profileType = cookies.get(PROFILE_TYPE_COOKIE_KEY);
  if (!profileId || !profileType) {
    return null;
  }
  return { profileId, profileType };
}

export function removeAuthToken() {
  cookies.remove(SESSION_COOKIE_KEY);
  cookies.remove(SESSION_COOKIE_KEY, { domain: 'tipser.com' }); // we have to remove old user token because it can be problematic
}

export function removeProfile() {
  cookies.remove(PROFILE_ID_COOKIE_KEY);
  cookies.remove(PROFILE_TYPE_COOKIE_KEY);
}

export function removeTempInitialToken() {
  const envName = capitalize(getShortTipserOriginEnv());
  const cookieName = `tempGlobal${envName}TipserToken`;
  return cookies.remove(cookieName, { domain: 'tipser.com' });
}

export function getConsentCookie() {
  return cookies.get(CONSENT_COOKIE_KEY);
}

export function setConsentCookie() {
  return cookies.set(CONSENT_COOKIE_KEY, 'consented', { expires: YEAR_IN_DAYS });
}

export function removeAllAuthTokens() {
  removeAuthToken();
  removeBasicAuthToken();
  removeTempInitialToken();
  removeProfile();
}
