import { destroy } from '../base';

export function removeProduct({
  collectionId,
  collectionItemId,
  isPrivate,
}: {
  collectionId: string;
  collectionItemId: string;
  isPrivate: boolean;
}): Promise<void> {
  const updateContext = isPrivate ? 'users' : 'pos';
  return destroy(`v5/${updateContext}/collections/${collectionId}/items/${collectionItemId}`).json();
}
