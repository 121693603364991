import { FiltersState } from './filters-state';
import { SearchParamsState } from './types';

export function flatSearchParams(searchParams: SearchParamsState) {
  const {
    filters,
    query,
    order: { name: orderName, direction: orderDir },
  } = searchParams;
  const { firstPublishedDate, ...restOfFilters } = filters;

  return {
    ...restOfFilters,
    orderName,
    orderDir,
    publishedDateFrom: firstPublishedDate.from,
    publishedDateTo: firstPublishedDate.to,
    query,
  };
}

export function unflatSearchParams(searchParams: FiltersState): SearchParamsState {
  const { query, orderDir, orderName, publishedDateFrom, publishedDateTo, ...filters } = searchParams;

  return {
    filters: {
      ...filters,
      firstPublishedDate: {
        from: publishedDateFrom,
        to: publishedDateTo,
      },
    },
    order: { name: orderName, direction: orderDir },
    query,
  };
}
