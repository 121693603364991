import { stringify } from 'query-string';

import { get } from 'services/api/base-portal-api';

import { GetNewMerchantResponse } from './types';

export function getNewMerchants({
  firstActivationDate,
  pageSize,
  pageIndex,
  filterByUserMerchantDiscoveryPreferences = false,
}: {
  firstActivationDate: Date | null;
  pageSize: number;
  pageIndex: number;
  filterByUserMerchantDiscoveryPreferences?: boolean;
}): Promise<GetNewMerchantResponse> {
  const params = {
    firstActivationDateFrom: firstActivationDate?.toISOString(),
    pageSize,
    pageIndex,
    filterByUserMerchantDiscoveryPreferences,
  };
  const queryString = stringify(params);
  return get(`v1/pos/merchants/new?${queryString}`).json();
}
