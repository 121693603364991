import { safeTakeLatest } from '@frontend/commons';

import { cleanAction } from 'store/clean-action';
import { createDefaultAsyncHelpers } from 'store/helpers';
import { AsyncReturnType } from 'types/async-return-type';

import { createOrUpdateProductCustomization } from './create-or-update-service';
import { ProductFetchErrorPayload } from './types';

const {
  saga,
  reducer: createOrUpdateProductCustomizationReducer,
  actions,
} = createDefaultAsyncHelpers<
  AsyncReturnType<typeof createOrUpdateProductCustomization>,
  Parameters<typeof createOrUpdateProductCustomization>[0],
  undefined,
  ProductFetchErrorPayload
>({
  actionName: 'INSERT_PRODUCTS__UPDATE_PRODUCT_CUSTOMIZATION',
  fetchFunc: createOrUpdateProductCustomization,
  cleanAction,
  initialData: undefined,
});

function* createOrUpdateProductCustomizationSaga() {
  yield safeTakeLatest(actions.request, saga);
}

const createOrUpdateProductCustomizationAction = actions.request;
const createOrUpdateProductCustomizationActions = actions;

export {
  createOrUpdateProductCustomizationAction,
  createOrUpdateProductCustomizationActions,
  createOrUpdateProductCustomizationReducer,
  createOrUpdateProductCustomizationSaga,
};
