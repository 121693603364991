import React, { FC } from 'react';

import { useNumberFormatterContext } from './provider';

type Props = {
  children?: number | string;
};

export const CurrencyFormatter: FC<Props> = ({ children }) => {
  const { moneyFormatter } = useNumberFormatterContext();
  return <>{moneyFormatter(children)}</>;
};
