import { getManyMerchantProducts } from 'services/api/merchants/products/get-many';

export async function fetchActivationData() {
  const [products] = await Promise.all([
    getManyMerchantProducts({
      pageIndex: 0,
      pageSize: 1,
    }),
  ]);

  return products;
}
