import { getUserProfiles } from 'services/api/user/profile/get-user-profiles';
import { getDecodedAuthToken } from 'services/get-decoded-auth-token';

export async function isTokenUpToDate() {
  try {
    const userProfiles = await getUserProfiles();
    const decodedToken = getDecodedAuthToken();

    if (decodedToken.merchantId) {
      return userProfiles.merchantProfiles.map((x) => x.id).includes(decodedToken.merchantId);
    }
    if (decodedToken.posId) {
      return userProfiles.posProfiles.map((x) => x.posId).includes(decodedToken.posId);
    }

    return false;
  } catch (e: any) {
    if ([401, 403].includes(e?.response?.status)) {
      return false;
    }

    throw e;
  }
}
