import { ConfirmationModal } from '@frontend/commons';
import { TextWithEllipsis } from '@frontend/shared';
import React, { FC, useCallback } from 'react';

import { HARD_SPACE } from 'constants/CHARACTERS';
import { capitalizeFirstLetter } from 'helpers/string';
import { getDecodedAuthToken } from 'services/get-decoded-auth-token';

import { useActionWithDispatch } from 'store';
import { authorizeViaProfileIdAction } from 'store/auth/sub-ducks/authorize-via-profile-id';

import { useEffectFetchLocalState, useMerchantOrPosDataFromLocalState } from './store/hooks';

type Props = {
  onClose: () => void;
};

export const TokenOutOfSyncModal: FC<Props> = ({ onClose }) => {
  useEffectFetchLocalState();
  const { posId: tokenPosId, merchantId: tokenMerchantId } = getDecodedAuthToken();

  const {
    pos: posFromToken,
    merchant: merchantFromToken,
    pending,
  } = useMerchantOrPosDataFromLocalState(tokenPosId, tokenMerchantId);

  const tokenProfileTypeName = getProfileNameType({ posId: tokenPosId, merchantId: tokenMerchantId });
  const tokenProfileName = posFromToken ? posFromToken.displayName : merchantFromToken?.companyName ?? '??';
  const dispatchLoginAsProfile = useActionWithDispatch(authorizeViaProfileIdAction.request);

  const handleLogInAsTokenProfile = useCallback(() => {
    dispatchLoginAsProfile({ posId: tokenPosId, merchantId: tokenMerchantId });
    onClose();
  }, [dispatchLoginAsProfile, tokenPosId, tokenMerchantId, onClose]);

  return (
    <ConfirmationModal
      title="Profile has changed"
      content={
        <>
          Your current profile is now <b>{tokenProfileName}</b> on {capitalizeFirstLetter(tokenProfileTypeName)}
          {HARD_SPACE}
          Portal. Switch to it to continue.
        </>
      }
      onReject={onClose}
      onConfirm={handleLogInAsTokenProfile}
      rejectButtonLabel="Close"
      confirmButtonLabel={
        <TextWithEllipsis>
          Switch to <b>{tokenProfileName}</b> profile
        </TextWithEllipsis>
      }
      pending={pending}
    />
  );
};

function getProfileNameType({ posId, merchantId }: { posId?: string; merchantId?: string }) {
  if (posId) {
    return 'Publisher';
  }

  if (merchantId) {
    return 'merchant';
  }

  return '??';
}
