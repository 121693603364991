import { safeTakeLatest } from '@frontend/commons';

import { MerchantAnalyticsAPI } from 'services/api';
import { MerchantDashboardPayload, MerchantDashboardResponse } from 'services/api/analytics/merchant/types-get';

import { createDefaultActions, createDefaultReducer, createDefaultSaga } from 'store/helpers';

import { AnalyticsState } from './types';

// Actions
const getAllAnalyticsData = createDefaultActions<MerchantDashboardPayload, MerchantDashboardResponse>(
  'MERCHANT_ANALYTICS__ALL_FETCH'
);
const getSingleAnalyticsData = createDefaultActions<MerchantDashboardPayload, MerchantDashboardResponse>(
  'MERCHANT_ANALYTICS__FETCH_SINGLE_CHART'
);

export const actions = {
  getAllAnalyticsData,
  getSingleAnalyticsData,
};

// Reducer
const initState: AnalyticsState = {
  charts: [],
  metrics: [],
};

export const reducer = createDefaultReducer(getAllAnalyticsData, initState);

reducer.on(getSingleAnalyticsData.request, (state) => ({
  ...state,
  pending: true,
}));

reducer.on(getSingleAnalyticsData.success, (state, payload) => ({
  ...state,
  pending: false,
  data: {
    charts: {
      ...state.data.charts,
      ...payload?.charts,
    },
    metrics: {
      ...state.data.metrics,
      ...payload?.metrics,
    },
  },
}));

const fetchAllAnalyticsData = createDefaultSaga(MerchantAnalyticsAPI.getMerchantAnalyticsData, getAllAnalyticsData);

const fetchSingleAnalyticsData = createDefaultSaga(
  MerchantAnalyticsAPI.getMerchantAnalyticsData,
  getSingleAnalyticsData
);

export function* saga() {
  yield safeTakeLatest(getAllAnalyticsData.request, fetchAllAnalyticsData);
  yield safeTakeLatest(getSingleAnalyticsData.request, fetchSingleAnalyticsData);
}
