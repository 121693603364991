import queryString from 'query-string';

import { get } from '../../base';

import { OrdersPayload, OrdersResponse } from './types-orders';

export function getOrders(payload: OrdersPayload) {
  const queryParams = queryString.stringify(payload, { arrayFormat: 'comma' });
  return get(`alfred/v1/merchant/orders?${queryParams}`).json<OrdersResponse>();
}
