import { GoogleTagManager, ErrorModal, ErrorBoundary } from '@frontend/commons';

import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';

import { AuthTokenSyncGuard } from 'components/organisms/auth-token-sync-guard';
import { NumberFormatterProvider } from 'components/organisms/number-fromatter';
import { Routes } from 'routes';
import { store } from 'store';

export const App: React.FC = () => {
  return (
    <>
      <React.StrictMode>
        <GoogleTagManager />
        <Provider store={store}>
          <DndProvider backend={HTML5Backend}>
            <ErrorBoundary>
              <NumberFormatterProvider>
                <Routes />
              </NumberFormatterProvider>
            </ErrorBoundary>
            <ErrorModal />
            <AuthTokenSyncGuard />
          </DndProvider>
        </Provider>
      </React.StrictMode>
    </>
  );
};
