import { safeTakeLatest } from '@frontend/commons';

import { Action, createAction } from 'redux-act';

import { select } from 'redux-saga/effects';

import { CollectionsAPI } from 'services/api/collections';
import { authActions } from 'store/auth';
import { createDefaultAsyncHelpers } from 'store/helpers';

import { currentCollectionSelector } from '../collection/selectors';

const {
  actions: saveReorderedCollectionActions,
  reducer: saveReorderedCollectionReducer,
  saga: saveReorderedCollectionLocalSaga,
} = createDefaultAsyncHelpers({
  actionName: 'INSERT_PRODUCTS_MANAGE_COLLECTION--SAVE_REORDERED_COLLECTION',
  fetchFunc: CollectionsAPI.reorder,
  cleanAction: authActions.authorizeViaProfileId.success,
  initialData: undefined,
});

function* prepareAndCallSaveReorderedCollectionLocalSaga(action: Action<void>) {
  const collection = currentCollectionSelector(yield select());
  if (!collection || !collection.data) {
    return;
  }

  const { items, isPrivate, id: collectionId } = collection.data;
  const itemsIds = items.map(({ id }) => id);

  const actionWithPayload = {
    ...action,
    payload: {
      collectionId,
      isPrivate,
      items: itemsIds,
    },
  } as Parameters<typeof saveReorderedCollectionLocalSaga>[0];

  yield saveReorderedCollectionLocalSaga(actionWithPayload);
}

const saveReorderedCollectionActionRequest = createAction(
  'INSERT_PRODUCTS_MANAGE_COLLECTION--SAVE_REORDERED_COLLECTION--REQUEST'
);
const saveReorderedCollectionActionSuccess = saveReorderedCollectionActions.success;

function* saveReorderedCollectionItemsSaga() {
  yield safeTakeLatest(saveReorderedCollectionActionRequest, prepareAndCallSaveReorderedCollectionLocalSaga);
}

export {
  saveReorderedCollectionReducer,
  saveReorderedCollectionActionSuccess,
  saveReorderedCollectionActionRequest,
  saveReorderedCollectionItemsSaga,
};
