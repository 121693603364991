import { DIACRITICAL_MARKS } from 'constants/VALIDATION_PATTERNS';

export function pluralize(one: string, many: string, amount: number) {
  return amount > 1 || amount === 0 ? many : one;
}

export function singularize(word: string) {
  const dictionary = {
    ies: 'y',
    s: '',
  };
  const pluralEdns = Object.keys(dictionary);

  const wordStart = word.substr(0, word.length - 3);
  const wordEnd = word.substr(word.length - 3, word.length);
  const checkResult = pluralEdns.map((dKey) => word.endsWith(dKey));
  const checkResultIndex = checkResult.indexOf(true);

  if (checkResultIndex < 0) {
    return word;
  }
  const pluralEndToReplace = pluralEdns[checkResultIndex];
  return wordStart + wordEnd.replace(pluralEndToReplace, dictionary[pluralEndToReplace]);
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function normalizedDiacriticalMarks(string: string) {
  return string.normalize('NFD').replace(DIACRITICAL_MARKS, '');
}
