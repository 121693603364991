import { AuthenticationToken } from '@frontend/shared';

import { put } from '../base';

/*
 * Only either posId or merchantId can be used. If both merchantId and posId are defined, the API will return bad request.
 */
export function authorizePosOrMerchant(
  params: { merchantId?: string; posId?: string },
  authToken?: string
): Promise<AuthenticationToken> {
  const headers = authToken ? { authorization: `Bearer ${authToken}` } : undefined;
  return put('v4/auth', { json: params, headers }).json<AuthenticationToken>();
}
