import { safeTakeLatest } from '@frontend/commons';

import { PosAnalyticsAPI } from 'services/api';
import { cleanAction } from 'store/clean-action';
import { createDefaultAsyncHelpers } from 'store/helpers';

import { AnalyticsState } from './types';

const initHomeAnalyticsState: AnalyticsState = {
  charts: [],
  metrics: [],
};

const {
  actions: getAllHomeAnalyticsData,
  reducer: homeAnalyticsReducer,
  saga: fetchAllHomeAnalyticsData,
} = createDefaultAsyncHelpers({
  cleanAction,
  fetchFunc: PosAnalyticsAPI.getPosAnalyticsData,
  actionName: 'HONE_ANALYTICS__ALL_FETCH',
  initialData: initHomeAnalyticsState,
});

// Actions
export const actions = {
  getAllHomeAnalyticsData: getAllHomeAnalyticsData.request,
};

// Reducer
export const reducer = homeAnalyticsReducer;

// Saga
export function* saga() {
  yield safeTakeLatest(getAllHomeAnalyticsData.request, fetchAllHomeAnalyticsData);
}
