import { safeTakeEvery, safeTakeLatest } from '@frontend/commons';
import { keyBy } from 'lodash';
import { Action } from 'redux-act';
import { call, put, delay, select, all } from 'redux-saga/effects';

import { CollectionsAPI } from 'services/api/collections';
import { Collection } from 'services/api/collections/types';
import { currentPosIdSelector, profileNameSelector } from 'store/auth/selectors';
import { createDefaultSaga } from 'store/helpers';

import { productsCollectionsActions } from 'store/products-collections';

import { collectionsActions } from './actions';
import { sharedCollectionPersistOrder } from './ordering';
import { removeCollectionActions } from './remove-collection';
import {
  addProductAndFetchStockCount,
  removeProductAndFetchStockCount,
  removeProductsAndFetchStockCount,
  RemoveProductAndFetchStockCountResponse,
} from './services';
import {
  CollectionsState,
  RemoveProductByCollectionItemIdFromCollectionPayload,
  RemoveProductByProductIdFromCollectionPayload,
  CreateCollectionRequest,
  AddProductsToCollectionPayload,
  UpdateCollectionPayload,
  SetStoreCollectionPayload,
  BatchProductToCollectionPayload,
} from './types';

const removeProductFromCollectionSaga = function* (
  action: Action<RemoveProductByCollectionItemIdFromCollectionPayload>
) {
  try {
    const { collectionId, collectionItemId, isPrivate } = action.payload;

    const data: RemoveProductAndFetchStockCountResponse = yield removeProductAndFetchStockCount({
      collectionItemId,
      isPrivate,
      collectionId,
    });
    yield put(collectionsActions.removeProductByCollectionItemId.success(data));

    yield put(collectionsActions.fetchCollections.request());
  } catch (e) {
    yield put(collectionsActions.removeProductByCollectionItemId.failure(e));
    throw e;
  }
};

const removeProductByProductIdFromCollectionSaga = function* (
  action: Action<RemoveProductByProductIdFromCollectionPayload>
) {
  try {
    const { collectionId, productId, isPrivate } = action.payload;

    const data: RemoveProductAndFetchStockCountResponse = yield removeProductsAndFetchStockCount({
      productsIds: [productId],
      collectionItemsIds: [],
      isPrivate,
      collectionId,
    });
    yield put(collectionsActions.removeProductByCollectionItemId.success(data));

    // @ts-ignore
    const profileName = yield select(profileNameSelector);
    yield put(productsCollectionsActions.updateProductCollections({ id: productId }));
    yield put(collectionsActions.fetchCollections.request(profileName));
  } catch (e) {
    yield put(collectionsActions.removeProductByCollectionItemId.failure(e));
    throw e;
  }
};

export function* createCollectionsSaga(action: Action<CreateCollectionRequest>) {
  try {
    const {
      data: { productIds, name },
      isPrivate,
    } = action.payload;
    const { id: collectionId } = yield call(CollectionsAPI.create, { name, isPrivate });
    if (productIds.length > 0) {
      yield put(
        collectionsActions.addProduct.request({
          collectionId,
          productIds,
          isPrivate,
        })
      );
    }
    yield put(collectionsActions.createCollection.success());
    yield all(
      productIds.map((productId) => put(productsCollectionsActions.updateProductCollections({ id: productId })))
    );
    yield put(collectionsActions.fetchCollections.request());
  } catch (e) {
    yield put(collectionsActions.createCollection.failure(e));
    throw e;
  }
}

const addProductToCollectionSaga = function* (action: Action<AddProductsToCollectionPayload>) {
  try {
    // @ts-ignore
    const data = yield call(addProductAndFetchStockCount, action.payload);
    yield put(collectionsActions.addProduct.success(data));
    yield all(
      action.payload.productIds.map((productId) =>
        put(productsCollectionsActions.updateProductCollections({ id: productId }))
      )
    );
  } catch (e) {
    yield put(collectionsActions.addProduct.failure(e));
    throw e;
  }
};

const batchOnProductCollectionSaga = function* (action: Action<BatchProductToCollectionPayload>) {
  const queueItems = action.payload;

  try {
    yield all(
      queueItems
        .filter((item) => item.type === 'remove')
        .map((item) => {
          const { collectionId, productId, isPrivate } = item;
          return put(collectionsActions.removeProductbyProductId.request({ collectionId, productId, isPrivate }));
        })
    );

    yield all(
      queueItems
        .filter((item) => item.type === 'add')
        .map((item) => {
          const { collectionId, productId, isPrivate } = item;
          return put(collectionsActions.addProduct.request({ collectionId, productIds: [productId], isPrivate }));
        })
    );

    yield put(collectionsActions.batchOnProduct.success());
  } catch (e) {
    yield put(collectionsActions.batchOnProduct.failure(e));
    throw e;
  }
};

const updateCollectionSaga = function* (action: Action<UpdateCollectionPayload>) {
  try {
    // @ts-ignore
    const data = yield call(CollectionsAPI.updateCollection, action.payload);
    yield put(collectionsActions.update.success({ data, isPrivate: action.payload.isPrivate }));
  } catch (e) {
    yield put(collectionsActions.update.failure(e));
    throw e;
  }
};

const setStoreCollectionsSaga = createDefaultSaga(
  CollectionsAPI.setStoreCollections,
  collectionsActions.setCollectionsInStore
);

function* debouncedStoreCollectionsSaga(action: Action<SetStoreCollectionPayload>) {
  yield delay(500);
  yield call(setStoreCollectionsSaga, action);
}

const fetchCollectionsSaga = function* () {
  try {
    // @ts-ignore
    const posId = yield select(currentPosIdSelector);
    // @ts-ignore
    const data = yield call(CollectionsAPI.getAllCollections, posId);

    const keyIdValueDataByStoreCollection = keyBy(data.store.collections, 'id');
    const mergedSharedCollections = data.shared.collections?.map((collection: Collection) => ({
      ...collection,
      isPrivate: false,
      isStoreCollection: !!keyIdValueDataByStoreCollection[collection.id],
    }));

    const adaptedPrivateCollections = data.private.collections?.map((collection: Collection) => ({
      ...collection,
      isPrivate: true,
    }));

    const storeCollections = data.store.collections?.map((collection: Collection) => ({
      ...collection,
      isStoreCollection: true,
    }));

    const mergedData: CollectionsState = {
      ...data,
      private: {
        ...data.private,
        collections: adaptedPrivateCollections,
      },
      shared: {
        ...data.shared,
        collections: mergedSharedCollections,
      },
      store: {
        ...data.store,
        collections: storeCollections,
      },
    };
    yield put(collectionsActions.fetchCollections.success(mergedData));
  } catch (e) {
    yield put(collectionsActions.fetchCollections.failure(e));
    throw e;
  }
};

export function* saga() {
  yield safeTakeLatest(
    [collectionsActions.batchOnProduct.success, collectionsActions.setCollectionsInStore.success],
    fetchCollectionsSaga
  );
  yield safeTakeLatest(removeCollectionActions.success, fetchCollectionsSaga);
  yield safeTakeLatest(collectionsActions.update.success, fetchCollectionsSaga);
  yield safeTakeLatest(collectionsActions.fetchCollections.request, fetchCollectionsSaga);
  yield safeTakeLatest(collectionsActions.createCollection.request, createCollectionsSaga);
  yield safeTakeEvery(collectionsActions.addProduct.request, addProductToCollectionSaga);
  yield safeTakeEvery(collectionsActions.removeProductByCollectionItemId.request, removeProductFromCollectionSaga);
  yield safeTakeEvery(collectionsActions.removeProductbyProductId.request, removeProductByProductIdFromCollectionSaga);
  yield safeTakeLatest(collectionsActions.batchOnProduct.request, batchOnProductCollectionSaga);
  yield safeTakeLatest(collectionsActions.update.request, updateCollectionSaga);
  yield safeTakeLatest(collectionsActions.setCollectionsInStore.request, debouncedStoreCollectionsSaga);
  yield sharedCollectionPersistOrder.saga();
}
