import React, { FC, ReactNode, ComponentType } from 'react';

import { getDisplayName } from 'recompose';

import { Modal } from '../modal';

type Props = {
  onClose: () => void;
  opened: boolean;
  size?: 'small' | 'medium' | 'large';
};

export function withModal<T>(
  Component: ComponentType<T & { closeModal?: () => void }>,
  header?: ReactNode
): FC<T & Props> {
  const wrapper: FC<T & Props> = ({ opened, onClose, size = 'large', ...rest }) => {
    const componentProps = rest as T;

    return (
      <Modal opened={opened} size={size} onClose={onClose} header={header}>
        <Component {...componentProps} closeModal={onClose} />
      </Modal>
    );
  };
  wrapper.displayName = `WithModal(${getDisplayName(Component)})`;
  return wrapper;
}
