import { FunctionComponent } from 'react';

import { areTokenAndLocalStatesOutOfSyncApiError, isAuthTokenSetButStateNone } from 'services/error-categorization';

import { SessionExpiredModal } from './session-expired-modal';
import { TokenOutOfSyncModal } from './token-out-of-sync-modal';

export function isSessionRelatedModal(error: any): boolean {
  return !!getSessionRelatedModalId(error);
}

export function getSessionRelatedModal(error: any): FunctionComponent<{ onClose: () => void }> {
  const id = getSessionRelatedModalId(error);

  if (id == null) {
    throw new Error(`No session related modal with id "${id}"`);
  }

  return sessionRelatedModalIdToComponent[id];
}

function getSessionRelatedModalId(error: any): undefined | 'session-expired' | 'token-out-of-sync' {
  if (isAuthTokenSetButStateNone(error)) {
    return 'session-expired';
  }

  if (areTokenAndLocalStatesOutOfSyncApiError(error)) {
    return 'token-out-of-sync';
  }

  const isUnauthenticatedErrorApiRequest = error?.response?.status === 401;

  if (isUnauthenticatedErrorApiRequest) {
    return 'session-expired';
  }

  return undefined;
}

const sessionRelatedModalIdToComponent = {
  'session-expired': SessionExpiredModal,
  'token-out-of-sync': TokenOutOfSyncModal,
};
