import { FC, useEffect } from 'react';

const isTipserProduction = window.location.host === 'app.tipser.com';

export const GoogleTagManager: FC = () => {
  useEffect(() => {
    if (!isTipserProduction) {
      return;
    }

    /* eslint-disable */
    // Google Tag Manager snippet
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      const f = d.getElementsByTagName(s)[0];
      const j = d.createElement(s);
      const dl = l != 'dataLayer' ? `&l=${l}` : '';
      // @ts-ignore
      j.async = true;
      // @ts-ignore
      j.src = j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
      // @ts-ignore
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-TSW4R3R');
    /* eslint-enable */
  }, []);

  return null;
};
