import { getAuthToken, getTempInitialToken, safeTakeLatest } from '@frontend/commons';

import { createAction } from 'redux-act';
import { put } from 'redux-saga/effects';

import { authActions } from '../auth';

const initiate = createAction('INIT');

export const initActions = {
  initiate,
};

function* onInitSaga() {
  const tipserToken = getAuthToken();
  const initialToken = getTempInitialToken();
  if (tipserToken) {
    yield put(authActions.authenticateViaToken.request(tipserToken));
    return;
  }

  if (initialToken) {
    yield put(authActions.authorizeViaInitialToken(initialToken));
  }
}

export function* saga() {
  yield safeTakeLatest(initiate, onInitSaga);
}
