import { SimpleSpinner as Spinner } from '@frontend/commons';
import React, { FC, memo, useCallback } from 'react';

import { ButtonLikeLink } from 'components/atoms/button-like-link';
import { TopBar } from 'components/molecules/top-bar';
import { HARD_SPACE } from 'constants/CHARACTERS';

import { useEffectFetchMerchantSettings, useMerchantSettings, useSetMerchantReadyAction } from './hooks';

let isClosed = false; // stored here to preserve information within single browser session, until user refreshes the page. It's not ideal, but super straightforward and encapsulated within the component.

export const MerchantActivationTopBar: FC = memo(() => {
  useEffectFetchMerchantSettings();
  const { hasProducts, isReady, isVisible, dataLoaded, isManual } = useMerchantSettings();

  const {
    dispatchSetReadyAction,
    pending: isSetReadyPending,
    finished: isSetReadyFinished,
  } = useSetMerchantReadyAction();

  const handleOnReadyClick = useCallback(() => {
    if (!isSetReadyPending) {
      dispatchSetReadyAction();
    }
  }, [dispatchSetReadyAction, isSetReadyPending]);

  const handleClose = useCallback(() => {
    isClosed = true;
  }, []);

  if (!dataLoaded || isVisible || !hasProducts || isClosed) {
    return null;
  }

  if (isReady || isSetReadyFinished) {
    return (
      <TopBar closable onClose={handleClose}>
        Your merchant account is currently under review. Contact
        {HARD_SPACE}
        <a href="mailto:merchant@tipser.com">merchant@tipser.com</a>
        {HARD_SPACE}
        if you have any questions.
      </TopBar>
    );
  }

  return (
    <TopBar closable onClose={handleClose}>
      <span>
        {!isManual && (
          <>
            Your account is inactive -{HARD_SPACE}
            <ButtonLikeLink onClick={handleOnReadyClick}>click here</ButtonLikeLink> to active your account.
          </>
        )}
        {isManual && (
          <>
            Your merchant account needs to be approved by Tipser before it&apos;s visible for our connected users. Have
            you uploaded your products and ready to go live?
            {HARD_SPACE}
            <ButtonLikeLink onClick={handleOnReadyClick}>Yes, I&apos;m ready!</ButtonLikeLink>
          </>
        )}
        {isSetReadyPending && <Spinner size="12px" inline fadeIn="instant" />}
      </span>
    </TopBar>
  );
});

MerchantActivationTopBar.displayName = 'MerchantActivationTopBar';
