import { uniq, uniqBy, uniqWith, isEqual } from 'lodash';

import { Whatever } from '../../types/whatever';

export function move<T>(array: Array<T>, from: number, to: number): Array<T> {
  if (from === to) {
    return array;
  }
  if (from > to) {
    return [...array.slice(0, to), array[from], ...array.slice(to, from), ...array.slice(from + 1, array.length)];
  }
  return [...array.slice(0, from), ...array.slice(from + 1, to + 1), array[from], ...array.slice(to + 1, array.length)];
}

export function replaceOrAddElements<T>(
  collection: T[],
  elementsToReplace: T[],
  uniqueIdSelector: (arg: T) => Whatever
) {
  const result = [...collection];
  elementsToReplace.forEach((elementToReplace) => {
    const index = result.findIndex((x) => uniqueIdSelector(x) === uniqueIdSelector(elementToReplace));
    if (index === -1) {
      result.push(elementToReplace);
    } else {
      result[index] = elementToReplace;
    }
  });
  return result;
}

export function findModifyAndReplace<T>(
  collection: T[],
  findCallback: (arg: T) => boolean,
  replaceCallback: (item: T) => T
) {
  const foundItem = collection.find(findCallback);

  if (!foundItem) {
    return collection;
  }

  const newItem = replaceCallback(foundItem);
  const index = collection.findIndex((item) => item === foundItem);
  return [...collection.slice(0, index), newItem, ...collection.slice(index + 1)];
}

export function combineArrays<T>(collections: T[][], limitOfCombinations = 5): T[][] {
  const readyCollection = collections.filter((c) => !!c.length);
  if (!readyCollection.length) {
    return [];
  }

  let results;
  if (readyCollection.length === 1) {
    results = readyCollection[0].map((c) => [c]);
  } else {
    results = readyCollection.reduce((acc, current) =>
      // @ts-ignore
      acc.reduce((deepAcc, deepCurrent) => deepAcc.concat(current.map<T[]>((c) => [].concat(deepCurrent, c))), [])
    ) as unknown as T[][]; // There is an issue with typing this part
  }

  if (results.length > limitOfCombinations) {
    results.length = limitOfCombinations;
  }

  return results;
}

export function uniqueCombineArray<T>(collections: T[][], key: string, limitOfCombinations = 5): T[][] {
  const readyCollection = collections.filter((c) => !!c.length);
  if (!readyCollection.length) {
    return [];
  }

  let results;
  if (readyCollection.length === 1) {
    results = readyCollection[0].map((c) => [c]);
  } else {
    results = uniqWith(
      readyCollection.reduce((acc, current) =>
        acc.reduce((deepAcc, deepCurrent) => {
          // @ts-ignore
          return deepAcc.concat(current.map<T[]>((c) => uniqBy([].concat(deepCurrent, c), key)));
        }, [])
      ) as unknown as T[][],
      isEqual
    ); // There is an issue with typing this part
  }

  if (results.length > limitOfCombinations) {
    results.length = limitOfCombinations;
  }

  return results;
}

export function hasMoreThanOneUniqueValue<T>(array: T[], getter: (item: T) => unknown): boolean {
  return uniq(array.map(getter)).length > 1;
}
