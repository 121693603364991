import { getDefaultMarket } from '@tipser/markets';

import { userTypeSelector } from 'store/auth';
import { merchantProfileSelector, posProfileSelector } from 'store/auth/selectors';
import { useSelector } from 'store/helpers';
import { useMarkets } from 'store/markets/hooks';
import { ProfileType } from 'types/model/profile';

export function useCurrentMarket() {
  const userType = useSelector(userTypeSelector);
  return useCurrentMarketForLoggedUser(userType) || getDefaultMarket();
}

function useCurrentMarketForLoggedUser(userType: ProfileType) {
  const markets = useMarkets();
  const merchantMarketId = useSelector(merchantProfileSelector)?.markets[0];
  const posProfileMarketId = useSelector(posProfileSelector)?.marketId;

  if (userType === 'merchant') {
    return markets.find((market) => market.id === merchantMarketId)?.name;
  }
  if (userType === 'pos') {
    return markets.find((market) => market.id === posProfileMarketId)?.name;
  }

  return undefined;
}
