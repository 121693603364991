import { combineReducers } from 'redux';

import { saga as campaignSaga, reducer as campaignReducer, fetchCampaignAction } from './campaign';
import { fetchPosMerchantAction, saga as posMerchantSaga, reducer as posMerchantReducer } from './merchants';
import {
  fetchCampaignProposalAction,
  saga as posCampaignProposalSaga,
  reducer as posCampaignProposalReducer,
} from './proposal';

import { State } from './types';

export const actions = {
  fetchCampaignProposalAction,
  fetchPosMerchantAction,
  fetchCampaignAction,
};

export const reducer = combineReducers<State>({
  proposal: posCampaignProposalReducer,
  merchant: posMerchantReducer,
  campaign: campaignReducer,
});

export function* saga() {
  yield* posCampaignProposalSaga();
  yield* posMerchantSaga();
  yield* campaignSaga();
}

export { posCampaignSelector } from './campaign';
export { posCampaignProposalMerchantSelector } from './merchants';
