import { LOCATION_CHANGE, LocationChangePayload } from 'connected-react-router';
import { createReducer } from 'redux-act';

import { AppState } from './types';

const initState: AppState = {
  previousLocation: null,
};

export const reducer = createReducer(
  {
    [LOCATION_CHANGE]: (state, previousLocation: LocationChangePayload) => {
      if (previousLocation.action !== 'REPLACE') {
        return {
          previousLocation,
        };
      }
      return state;
    },
  },
  initState
);
