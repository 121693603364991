import { keyBy } from 'lodash';
import { createSelector } from 'reselect';

import { GlobalState } from 'store/types';

export const getHomeProducts = (state: GlobalState) => state.pages.posHome.newProducts.products.data?.products;
export const getHomeProductsTotal = (state: GlobalState) => state.pages.posHome.newProducts.products.data?.total || 0;
export const getHomeProductsInitialPending = (state: GlobalState) =>
  state.pages.posHome.newProducts.products.initialPending;
export const getProductsPageNumber = (state: GlobalState) => state.pages.posHome.newProducts.pageNumber;

export const getCommissions = (state: GlobalState) => state.pages.posHome.newProducts.commissions?.data ?? [];
export const commissionsByProductId = createSelector(getCommissions, (commissions) => keyBy(commissions, 'productId'));
