import { CommissionsPayload, MerchantCommissionsSummaryResponse } from 'services/api/common-types/types-commissions';

import { get } from '../../base';
import { commissionsPayloadToCommissionsSummaryPayload } from '../../helpers';

export function getMerchantSummary(params: CommissionsPayload): Promise<MerchantCommissionsSummaryResponse> {
  return get('v5/merchants/commissions/summary', {
    searchParams: commissionsPayloadToCommissionsSummaryPayload(params),
  }).json();
}
