import {
  getMerchantProduct,
  updateMerchantProduct,
  createMerchantProduct,
} from 'services/api/merchants/products/index';

import { ProductCreate } from 'services/api/merchants/products/types-product-create';
import { ProductToAPI } from 'services/api/merchants/products/types-product-update';

export function getSingleProduct({ productId }: { productId: string }) {
  return getMerchantProduct({ productId });
}

export function updateSingleProduct({ productId, body }: { productId: string; body: ProductToAPI }) {
  return updateMerchantProduct({ productId, body });
}

export function createSingleProduct({ body }: { body: ProductCreate }) {
  return createMerchantProduct({ body });
}
