import { SimpleSpinner as Spinner } from '@frontend/commons';
import { usePrevious } from '@frontend/commons/src/helpers/hooks';
import React, { FC, lazy, useMemo } from 'react';
import { Switch, Route, useRouteMatch, Redirect, useLocation } from 'react-router-dom';

import { withProps } from 'recompose';

import { MerchantFreshdeskWidget } from 'components/organisms/merchant-freshdesk-widget';
import { MerchantTopBars } from 'components/organisms/merchant-top-bars';
import { PageWithSidebarContextProvider } from 'components/templates/page-with-sidebar';
import { createDefaultModule } from 'helpers/module';
import { removeTrailingSlash } from 'helpers/url-helpers';
import { useHasMerchantCustomerDataViewerClaim } from 'store/auth/hooks';

import { useFetchMerchantSettings } from './hooks';
const Home = lazy(() =>
  import(/* webpackChunkName: "page-merchant-home" */ './home').then(createDefaultModule((m) => m.Home))
);
const Faq = lazy(() =>
  import(/* webpackChunkName: "page-merchant-faq" */ './faq').then(createDefaultModule((m) => m.Faq))
);
const IncreaseSales = lazy(() =>
  import(/* webpackChunkName: "page-merchant-increase-sales" */ './increase-sales').then(
    createDefaultModule((m) => m.IncreaseSales)
  )
);
const MerchantPage = lazy(() =>
  import(/* webpackChunkName: "page-merchant-increase-sales" */ './merchant-page').then(
    createDefaultModule((m) => m.MerchantPage)
  )
);
const OrderDetails = lazy(() =>
  import(/* webpackChunkName: "page-merchant-order-details" */ './order-details').then(
    createDefaultModule((m) => m.OrderDetails)
  )
);
const Orders = lazy(() =>
  import(/* webpackChunkName: "page-merchant-orders" */ './orders').then(createDefaultModule((m) => m.Orders))
);
const Page404 = lazy(() =>
  import(/* webpackChunkName: "page-merchant-page404" */ './page404').then(createDefaultModule((m) => m.Page404))
);
const Products = lazy(() =>
  import(/* webpackChunkName: "page-merchant-products" */ './products').then(createDefaultModule((m) => m.Products))
);
const Analytics = lazy(() =>
  import(/* webpackChunkName: "page-merchant-merchant-analytics" */ './analytics-dashboard').then(
    createDefaultModule((m) => m.Analytics)
  )
);
const Sales = lazy(() =>
  import(/* webpackChunkName: "page-merchant-sales" */ './sales/sales').then(createDefaultModule((m) => m.Sales))
);
const Settings = lazy(() =>
  import(/* webpackChunkName: "page-merchant-settings" */ './settings/settings').then(
    createDefaultModule((m) => m.Settings)
  )
);

const Campaigns = lazy(() =>
  import(/* webpackChunkName: "page-merchant-campaigns" */ './campaigns').then(createDefaultModule((m) => m.Campaigns))
);

const CreateCampaign = lazy(() =>
  import(/* webpackChunkName: "page-merchant-create-campaign" */ './campaign-single').then(
    createDefaultModule((m) => m.CreateCampaign)
  )
);

const CampaignProposalDetails = lazy(() =>
  import(/* webpackChunkName: "page-merchant-proposal-details" */ './campaign-single/campaign-proposal-details').then(
    createDefaultModule((m) => m.CampaignProposalDetails)
  )
);

const EditCampaignProposal = lazy(() =>
  import(/* webpackChunkName: "page-merchant-edit-campaign" */ './campaign-single/edit-campaign-proposal').then(
    createDefaultModule((m) => m.EditCampaignProposal)
  )
);

const CampaignDetails = lazy(() =>
  import(/* webpackChunkName: "page-merchant-campaign-details" */ './campaign-single/campaign-details').then(
    createDefaultModule((m) => m.CampaignDetails)
  )
);

export const Merchant: FC = () => {
  const { data: merchantSettingsData, initialPending } = useFetchMerchantSettings();
  const { url } = useRouteMatch();
  const location = useLocation();
  const hasMerchantCustomerDataViewerClaim = useHasMerchantCustomerDataViewerClaim();
  const previousLocation = usePrevious(location.pathname + location.search);
  const OrderDetailsWithPrevLocation = withProps({ previousLocation })(OrderDetails);
  const isNullIntegrationType = useMemo(
    () => merchantSettingsData.merchant?.integrationType === null,
    [merchantSettingsData]
  );
  const isAffiliateMerchant = useMemo(
    () => !(['None', null] as unknown[]).includes(merchantSettingsData.merchant?.affiliateNetwork),
    [merchantSettingsData]
  );

  const defaultMerchantRoute = merchantSettingsData?.merchant?.integrationType === null ? 'home' : 'analytics';

  if (initialPending) {
    return (
      <PageWithSidebarContextProvider renderExtraContent={() => <MerchantTopBars />}>
        <Spinner />
      </PageWithSidebarContextProvider>
    );
  }

  return (
    <PageWithSidebarContextProvider renderExtraContent={() => <MerchantTopBars />}>
      <MerchantFreshdeskWidget />
      <Switch>
        {isNullIntegrationType && <Route path={`${url}/home`} component={Home} />}
        <Route path={`${url}/analytics`} component={Analytics} />
        <Route path={`${url}/sales`} component={Sales} />
        <Route path={`${url}/increase-sales`} component={IncreaseSales} />
        <Route path={`${url}/merchant-page`} component={MerchantPage} />
        <Route path={`${url}/faq`} component={Faq} />
        {hasMerchantCustomerDataViewerClaim && [
          <Route key="order-details" path={`${url}/orders/:orderId`} component={OrderDetailsWithPrevLocation} />,
          <Route key="orders" path={`${url}/orders`} component={Orders} />,
        ]}
        <Route path={`${url}/products`} component={Products} />

        {!isAffiliateMerchant && [
          <Route key="campaign-create" path={`${url}/campaigns/create`} component={CreateCampaign} />,
          <Route
            key="campaign-proposals-edit"
            path={`${url}/campaign-proposals/:id/edit`}
            component={EditCampaignProposal}
          />,
          <Route key="campaign-proposals" path={`${url}/campaign-proposals/:id`} component={CampaignProposalDetails} />,
          <Route key="campaign-details" path={`${url}/campaigns/:id`} component={CampaignDetails} />,
          <Route key="campaigns" path={`${url}/campaigns`} component={Campaigns} />,
        ]}
        <Route path={`${url}/settings`} component={Settings} />
        <Route
          path={`${url}/`}
          exact
          render={() => <Redirect to={`${removeTrailingSlash(url)}/${defaultMerchantRoute}`} />}
        />
        {/* 👆 Remember to change Redirect to Home when will be ready 👆 */}
        <Route component={Page404} />
      </Switch>
    </PageWithSidebarContextProvider>
  );
};
