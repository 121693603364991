import { put } from '../base';

export function addProducts({
  collectionId,
  productIds,
  isPrivate,
}: {
  collectionId: string;
  productIds: Array<string>;
  isPrivate: boolean;
}): Promise<any> {
  const updateContext = isPrivate ? 'users' : 'pos';
  return put(`v5/${updateContext}/collections/${collectionId}/items`, { json: { productIds } }).json();
}
