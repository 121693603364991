import { put } from '../base';

export async function setStoreCollections({
  shopName,
  collectionsIds,
}: {
  shopName: string;
  collectionsIds: string[];
}) {
  await put(`v4/shops/byName/${shopName}/collections`, { json: { collectionsIds } }).then(() => undefined);
}
