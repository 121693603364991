import { safeTakeLatest } from '@frontend/commons';

import { UserAPI } from 'services/api/user';
import { ProfilesResponse } from 'services/api/user/profile/types-get-user-profiles';
import { cleanAction } from 'store/clean-action';

import { createDefaultActions, createDefaultReducer, createDefaultSaga } from '../../helpers';
import { acceptInvitationActions } from '../accept-invitation/accept-invitation-duck';

export const fetchUserProfilesActions = createDefaultActions<string | void, ProfilesResponse>(
  'PROFILE_SWITCHER__FETCH_USER_PROFILES'
);

const fetchUserProfilesSaga = createDefaultSaga(UserAPI.getUserProfiles, fetchUserProfilesActions);

export const fetchProfilesReducer = createDefaultReducer(fetchUserProfilesActions, undefined, cleanAction);

fetchProfilesReducer.on(acceptInvitationActions.success, (state, payload) => {
  return { ...state, data: payload };
});

export function* fetchProfilesSaga() {
  yield safeTakeLatest(fetchUserProfilesActions.request, fetchUserProfilesSaga);
}
