import { getProfile, removeProfile } from '@frontend/commons';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import { Action } from 'redux-act';

import { call, put } from 'redux-saga/effects';

import { ABSOLUTE_LINK } from 'constants/VALIDATION_PATTERNS';
import { UserAPI } from 'services/api';
import { CurrentProfileResponse } from 'services/api/user/profile/types-get-current';
import { createDefaultActions, createDefaultReducer } from 'store/helpers';

import { logout } from '../common-actions';
import { getProfiles } from '../common-helpers';
import { AuthState, FullyAuthenticatedUserViaTokenPayload } from '../types';

import { initState } from './common';

export const authenticateViaTokenAction =
  createDefaultActions<FullyAuthenticatedUserViaTokenPayload, AuthState>('AUTH_VIA_TOKEN');

export const authenticateViaTokenReducer = createDefaultReducer<
  FullyAuthenticatedUserViaTokenPayload,
  AuthState,
  undefined
>(authenticateViaTokenAction, initState);

export function* authenticateViaTokenSaga(action: Action<string>) {
  try {
    const token = action.payload;
    const profile: CurrentProfileResponse = yield call(UserAPI.getCurrentProfile, token);

    const { profileId, profileType } = getProfileTypeAndId(profile);
    const currentPosId = profileType === 'pos' ? profileId : undefined;
    const currentMerchantId = profileType === 'merchant' ? profileId : undefined;
    const { posProfile, merchantProfile } = yield* getProfiles(currentPosId, currentMerchantId, token);
    const { redirectUri } = queryString.parse(window.location.search) as { redirectUri?: string };

    yield put(
      authenticateViaTokenAction.success({
        profile,
        token,
        currentPosId,
        currentMerchantId,
        posProfile,
        merchantProfile,
      })
    );
    if (redirectUri && !ABSOLUTE_LINK.test(redirectUri)) {
      yield put(push(redirectUri));
    }
  } catch (e: any) {
    yield put(authenticateViaTokenAction.failure(undefined));
    if (e?.response?.status > 399 && e?.response?.status < 500) {
      yield put(logout());
      return;
    }
    throw e;
  }
}

function getProfileTypeAndId(profile: CurrentProfileResponse): {
  profileId?: string;
  profileType?: 'unknown' | 'pos' | 'merchant';
} {
  const profileFromCookie = getProfile();
  const isCorrectPos =
    profileFromCookie?.profileType === 'pos' && profile.posIds.includes(profileFromCookie?.profileId);
  const isCorrectMerchant =
    profileFromCookie?.profileType === 'merchant' && profile.merchantIds.includes(profileFromCookie?.profileId);

  if (isCorrectPos || isCorrectMerchant) {
    return profileFromCookie as ReturnType<typeof getProfileTypeAndId>; // ekhm that is not the best type;
  }

  if (profileFromCookie) {
    removeProfile();
  }

  if (profile.posIds[0]) {
    return { profileType: 'pos', profileId: profile.posIds[0] };
  }

  if (profile.merchantIds[0]) {
    return { profileType: 'merchant', profileId: profile.merchantIds[0] };
  }

  return {
    profileType: 'unknown',
    profileId: undefined,
  };
}
