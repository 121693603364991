import { combineReducers } from 'redux';

import { reducer as campaignReducer, saga as campaignSaga, fetchCampaignActions } from './campaign';
import {
  reducer as campaignProposalReducer,
  saga as campaignProposalSaga,
  fetchCampaignProposalActions,
} from './proposal';
import { State } from './types';

export { campaignProposalSelector, campaignSelector } from './selectors';

export const actions = {
  fetchCampaignActions,
  fetchCampaignProposalActions,
};

export const reducer = combineReducers<State>({
  campaignProposal: campaignProposalReducer,
  campaign: campaignReducer,
});

export function* saga() {
  yield* campaignProposalSaga();
  yield* campaignSaga();
}
