import { MerchantCommissionsAPI } from 'services/api';
import {
  CommissionsPayload,
  CommissionsResponse,
  MerchantCommissionsSummaryResponse,
} from 'services/api/common-types/types-commissions';

export async function fetchAllCommissionsData(
  payload: CommissionsPayload
): Promise<{ items: CommissionsResponse; summary: MerchantCommissionsSummaryResponse }> {
  const indexPromise = MerchantCommissionsAPI.getMerchantCommissions(payload);
  const summaryPromise = MerchantCommissionsAPI.getMerchantSummary(payload);
  const [items, summary] = await Promise.all([indexPromise, summaryPromise]);
  return {
    items,
    summary,
  };
}
