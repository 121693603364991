import { MarketName } from '@tipser/markets';

import { get } from '../base';

import { MarketsResponse } from './types-get-markets';

export const getMarkets = async (): Promise<MarketsResponse> => {
  const json = await get('v5/markets').json<MarketsResponse>();
  return json.map((market) => ({ ...market, name: market.name?.toLocaleLowerCase() as MarketName }));
};
