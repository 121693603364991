import React, { FC } from 'react';

import { ConfirmationModal } from '../../../molecules/confirmation-modal';

type Props = {
  closeModal: () => void;
};

const CONTENT_TEXT =
  'We apologise for the inconvenience. The team has been notified about the problem and we are working on the fix.';

export const SimpleErrorModal: FC<Props> = ({ closeModal }) => {
  return (
    <>
      <ConfirmationModal
        title="Some error occurred :("
        content={CONTENT_TEXT}
        onReject={closeModal}
        rejectButtonLabel="Close"
        hideConfirmButton
      />
    </>
  );
};
