import { safeTakeLatest } from '@frontend/commons';
import { push } from 'connected-react-router';
import { Action, createAction } from 'redux-act';
import { put, take, TakeEffect } from 'redux-saga/effects';

import { authActions } from 'store/auth';
import { hasOwnProperty } from 'types/has-own-property';

export const profileSwitch =
  createAction<{ backUrl: string; posId?: string; merchantId?: string }>('PROFILE_SWITCHER__SWITCH');

function* profileSwitchSaga(action: Action<{ backUrl: string; posId?: string; merchantId?: string }>) {
  const { posId, backUrl, merchantId } = action.payload;
  yield put(authActions.authorizeViaProfileId.request({ posId, merchantId }));

  const actionTook: TakeEffect = yield take([
    authActions.authorizeViaProfileId.success,
    authActions.authorizeViaProfileId.failure,
  ]);

  // todo: probably remove after fixing T3-10876
  // 403 should be handled in generic way.
  // 403 error on `authActions.authorizeViaProfileId` is silenced and user logged out. Workaround for another bug.
  // See `authViaProfileIdSaga` for more details why.
  // 403 error from `authorizeViaProfileId` is silenced, thus we need to take over that 403 here, and throw again, so it can
  // be taken over by global handler. Otherwise user will see white screen with loading.
  // If this 403 error will be taken over, we should see "switch to another account" information.
  const isFailureAction = actionTook.type === authActions.authorizeViaProfileId.failure(undefined).type;
  const { payload } = actionTook;
  // @ts-ignore
  const is403ApiError = isPayloadWithResponse(payload) && payload.response.status === 403;
  if (isFailureAction && is403ApiError) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw actionTook.payload;
  } else {
    yield put(push(backUrl));
  }
}

export function* saga() {
  yield safeTakeLatest(profileSwitch, profileSwitchSaga);
}

function isPayloadWithResponse<T extends { response: { status: number } }>(
  payload: T
): payload is T & { response: { status: number } } {
  if (!hasOwnProperty(payload, 'response')) {
    return false;
  }
  return isResponseWithStatus(payload.response);
}

function isResponseWithStatus<T extends {}>(arg: T): arg is T & { status: number } {
  if (!hasOwnProperty(arg, 'status')) {
    return false;
  }

  return typeof arg.status === 'number';
}
