import { getDecodedAuthToken } from 'services/get-decoded-auth-token';
import { FullyAuthenticatedUserResponse } from 'store/auth/types';

export function areTokenAndAuthStateNotSynchronised(authState?: FullyAuthenticatedUserResponse): boolean | undefined {
  const { currentPosId, currentMerchantId } = authState ?? {};

  const { posId: tokenPosId, merchantId: tokenMerchantId } = getDecodedAuthToken();

  if (tokenPosId == null && tokenMerchantId == null) {
    return false;
  }

  if (tokenPosId != null && tokenPosId !== currentPosId) {
    return false;
  }

  if (tokenMerchantId != null && tokenMerchantId !== currentMerchantId) {
    return false;
  }

  return true;
}
