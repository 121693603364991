import { safeTakeLatest } from '@frontend/commons';

import { ignoreSaga404Error } from 'store/helpers';

import { createDefaultMultiAsyncHelpers } from 'store/helpers/default-multi-async-helpers';

import { getPosProposalDetails } from './fetch-proposal-service/fetch-pos-proposal.service';

const {
  actions,
  reducer,
  saga: fetchAsyncSaga,
} = createDefaultMultiAsyncHelpers('POS_CAMPAIGN_PROPOSAL__FETCH', getPosProposalDetails, [ignoreSaga404Error]);

const fetchCampaignProposalAction = actions.request;

export { reducer, fetchCampaignProposalAction };

export function* saga() {
  yield safeTakeLatest(fetchCampaignProposalAction, fetchAsyncSaga);
}
