import React, { FC } from 'react';

import { Text } from 'components/atoms/text';

import styles from './page-title-styles.module.scss';

export const PageTitle: FC = ({ children }) => {
  return (
    <Text as="h1" className={styles.pageTitle} type="prominent" weight="medium">
      {children}
    </Text>
  );
};
