import React, { FC } from 'react';

import { useNumberFormatterContext } from './provider';

type PropTypes = {
  children?: number;
  fractionDigits?: number;
};

export const NumberFormatter: FC<PropTypes> = ({ children, fractionDigits = 2 }) => {
  const { numberFormatter } = useNumberFormatterContext();
  return <>{numberFormatter(children, fractionDigits)}</>;
};
