const alphabeticalAscending = createOption('Name A-Z', 'name', 'ASC');
const alphabeticalDescending = createOption('Name Z-A', 'name', 'DESC');
const priceAscending = createOption('Lowest Price', 'price', 'ASC');
const priceDescending = createOption('Highest Price', 'price', 'DESC');
const latestArrival = createOption('Latest Arrival', 'created', 'DESC');
const relevance = createOption('Relevance', 'relevance', 'ASC');

export const DEFAULT_ORDER = relevance;

type OrderName = 'created' | 'name' | 'price' | 'relevance';
type OrderDirection = 'ASC' | 'DESC';

export type Order = {
  name: OrderName;
  direction: OrderDirection;
};

export const ORDER_OPTIONS = [
  alphabeticalAscending,
  alphabeticalDescending,
  priceAscending,
  priceDescending,
  latestArrival,
  relevance,
];

function createOption(label: string, valueName: OrderName, orderDirection: OrderDirection) {
  return {
    label,
    value: {
      name: valueName,
      direction: orderDirection,
    },
  };
}
