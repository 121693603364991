import { FunctionComponent } from 'react';

export type ErrorModalRegistryEntry = {
  handlesErrorNicely: boolean | ((error: any) => boolean);
  ErrorModalComponent?: FunctionComponent<{ onClose: () => void }>;
  errorModalComponentFactory?: (error: any) => FunctionComponent<{ onClose: () => void }>;
  shouldOpenModal: (error: any) => boolean;
};

const errorModalRegistry: ErrorModalRegistryEntry[] = [];

export { errorModalRegistry };
