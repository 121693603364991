import clsx from 'clsx';
import React, { ComponentClass, FC } from 'react';
import withProps from 'recompose/withProps';

import styles from 'assets/stylesheets/typography.module.scss';

type Props = {
  as?: string | React.ComponentType<{ className: string }>;
  className?: string;
  weight?: 'normal' | 'medium' | 'bold';
  type: 'prominent' | 'primary' | 'secondary';
  lineHeight?: 'medium';
};

export const Text: FC<Props> = (props) => {
  const { as: Component = 'span', className: incomingClassName, children, type, weight = 'normal', lineHeight } = props;

  const className = clsx(
    {
      [styles.primaryText]: type === 'primary',
      [styles.secondaryText]: type === 'secondary',
      [styles.prominentText]: type === 'prominent',
      [styles.normalText]: weight === 'normal',
      [styles.mediumText]: weight === 'medium',
      [styles.boldText]: weight === 'bold',
      [styles.mediumLineHeight]: lineHeight === 'medium',
    },
    incomingClassName
  );

  return <Component className={className}>{children}</Component>;
};

export const SecondaryText: ComponentClass<Omit<Props, 'type'>> = withProps({ type: 'secondary' })(Text);
export const PrimaryText: ComponentClass<Omit<Props, 'type'>> = withProps({ type: 'primary' })(Text);
export const ProminentText: ComponentClass<Omit<Props, 'type'>> = withProps({ type: 'prominent' })(Text);
