import clsx from 'clsx';
import React, { FC } from 'react';

import { ReactComponent as TipserByBoltIcon } from 'assets/icons/bolt/tipser-by-bolt-submark-negative.svg';

import { HARD_SPACE } from 'constants/CHARACTERS';

import styles from './styles.module.scss';

type PropTypes = {
  photoClassName?: string;
  photoVisible?: boolean;
  contentClassName?: string;
  wrapperClassName?: string;
};

export const AuthPage: FC<PropTypes> = ({
  children,
  photoClassName,
  photoVisible,
  contentClassName,
  wrapperClassName,
}) => {
  return (
    <div className={styles.container}>
      <div className={clsx(styles.wrapper, wrapperClassName)}>
        {photoVisible && <div className={clsx(styles.photo, photoClassName)} />}
        <div className={clsx(styles.content, contentClassName)}>{children}</div>
      </div>
      <div className={styles.footer}>
        <div>
          Tipser is now part of Bolt,
          {HARD_SPACE}
          <a href="https://tipser.co/post/tipser-joins-bolt" target="_blank" rel="noreferrer">
            learn more here
          </a>
          .
        </div>
        <TipserByBoltIcon className={styles.tipserByBoltIcon} />
      </div>
    </div>
  );
};
