import { safeTakeLatest } from '@frontend/commons';

import { MetadataAPI } from 'services/api';
import { MetadataResponse } from 'services/api/pos/products/types-update-metadata';

import { createDefaultActions, createDefaultReducer, createDefaultSaga } from '../helpers';

export const fetchMetadataActions = createDefaultActions<void, MetadataResponse>('METADATA__FETCH');

const fetchMetadataSaga = createDefaultSaga(MetadataAPI.index, fetchMetadataActions);

export const reducer = createDefaultReducer(fetchMetadataActions, undefined);

export function* saga() {
  yield safeTakeLatest(fetchMetadataActions.request, fetchMetadataSaga);
}
