import { safeTakeLatest } from '@frontend/commons';

import { CategoriesAPI } from 'services/api/categories';
import { createDefaultAsyncHelpers } from 'store/helpers';

const {
  saga: fetchCategoryPropertiesSaga,
  actions: fetchCategoryPropertiesActions,
  reducer: fetchCategoryPropertiesReducer,
} = createDefaultAsyncHelpers({
  actionName: 'INSERT_PRODUCTS__FETCH_CATEGORY_PROPERTIES',
  fetchFunc: CategoriesAPI.properties,
  initialData: undefined,
});

function* categoryPropertiesSaga() {
  yield safeTakeLatest(fetchCategoryPropertiesActions.request, fetchCategoryPropertiesSaga);
}

export { fetchCategoryPropertiesActions, categoryPropertiesSaga, fetchCategoryPropertiesReducer };
