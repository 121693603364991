import { SagaCustomErrorHandler } from './types';

export async function processSagaErrorHandlers<TFailurePayload>(
  error: TFailurePayload,
  sagaErrorHandlers: SagaCustomErrorHandler<TFailurePayload>[]
): Promise<{ isHandled: boolean; failurePayload?: any }> {
  if (!(sagaErrorHandlers?.length > 0)) {
    return { isHandled: false };
  }

  for (let i = 0; i < sagaErrorHandlers.length; i = +1) {
    let goToNext = false;
    const nextFn = (): undefined => {
      goToNext = true;
      return undefined;
    };
    // eslint-disable-next-line no-await-in-loop
    const result = await sagaErrorHandlers[i](error, nextFn);

    if (!goToNext) {
      return { isHandled: true, failurePayload: result };
    }
  }
  return { isHandled: false };
}
