import { ProductSingleDetailed } from 'services/api/pos/products/types-get-one-product';
import { createProductCustomization, updateProductCustomization } from 'services/api/product-customization';
import { Customization } from 'services/api/product-customization/types';

type CustomizationMaybeNew = Omit<Customization, 'id'> & { id?: string };

export async function createOrUpdateProductCustomization({
  maybeNewCustomization,
  productWithoutCustomization,
}: {
  maybeNewCustomization: CustomizationMaybeNew;
  productWithoutCustomization: ProductSingleDetailed;
}) {
  if (maybeNewCustomization.id) {
    const { id, ...customization } = maybeNewCustomization;
    const customizationResponse = await updateProductCustomization({ id, customization });
    return { customization: customizationResponse, productWithoutCustomization };
  }

  const customizationResponse = await createProductCustomization({ customization: maybeNewCustomization });
  return {
    customization: customizationResponse,
    productWithoutCustomization,
  };
}
