import { safeTakeLatest } from '@frontend/commons';

import { OrdersAPI } from 'services/api/merchants/orders';
import { Order } from 'services/api/merchants/orders/types-orders';
import { createDefaultActions, createDefaultReducer, createDefaultSaga } from 'store/helpers';

export const fetchOrderActions = createDefaultActions<string, Order>('ORDERS__FETCH_ORDER');

export const reducer = createDefaultReducer(fetchOrderActions, undefined);

export const fetchOrderSaga = createDefaultSaga(OrdersAPI.getOrderById, fetchOrderActions);

export function* saga() {
  yield safeTakeLatest(fetchOrderActions.request, fetchOrderSaga);
}
