import { getOrders } from './get-all';
import { getOrderById } from './get-one';
import { getReport } from './get-report';
import { shipping } from './shipping';
import { updateStatus } from './update-status';

export const OrdersAPI = {
  getReport,
  updateStatus,
  shipping,
  getOrderById,
  getOrders,
};
