import { useTriggerFalse, useTriggerTrue } from '@frontend/commons';
import queryString from 'query-string';
import { ChangeEvent, useCallback, useEffect, useRef, useState, SyntheticEvent, useMemo } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useDebouncedCallback as useDebouncedCallbackOriginal } from 'use-debounce';

import { makeCancelable, silenceCancelable, CancelablePromise } from './cancellable-promise';

export function useKeyDownListener(callback: (e: KeyboardEvent) => void, startToWatch = true) {
  useEffect(() => {
    if (startToWatch) {
      document.addEventListener('keydown', callback);
    } else {
      document.removeEventListener('keydown', callback);
    }

    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, [callback, startToWatch]);
}

export function useDocumentKeyDownListener(callback: (e: KeyboardEvent) => void, key: string, startToWatch = true) {
  const handleCallback = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === key) {
        callback(e);
      }
    },
    [callback, key]
  );

  useKeyDownListener(handleCallback, startToWatch);
}

let debugId = 0;

/**
 * useful for debugging changed values in hooks
 */
export function usePreviousRenderValue<T>(value: T, debug = false, debugLabel = ''): T | undefined {
  const ref = useRef<T>();
  if (debug) {
    /* eslint-disable no-console */
    const sameValue = ref.current === value;
    debugId += 1;
    if (debugLabel !== '') {
      console.group(
        `debug value: %c${debugLabel} %c${sameValue ? 'same' : 'has changed'} %cid=${debugId}`,
        'color: blue',
        `color: ${sameValue ? 'green' : 'orange'}`,
        'color: grey'
      );
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      sameValue ? console.log(value) : console.log(ref.current, ' -->> ', value);
      console.groupEnd();
    } else {
      console.log(
        `%c${sameValue ? 'same' : 'has changed'} %cid=${debugId}`,
        `color: ${sameValue ? 'green' : 'orange'}`,
        'color: grey'
      );
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      sameValue ? console.log(value) : console.log(ref.current, ' -->> ', value);
    }
    /* eslint-enable no-console */
  }

  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

// Taken from https://usehooks.com/useLocalStorage/
export function useLocalStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value: T) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };
  return [storedValue, setValue];
}

export function useCheckedCheckboxCallback<T extends (...args: any[]) => any>(callback: T) {
  return useCallback((event: ChangeEvent<HTMLInputElement>) => callback(event.target.checked), [callback]);
}

export function useBooleanTriggers(method: Function) {
  const setTrue = useTriggerTrue(method);
  const setFalse = useTriggerFalse(method);
  return [setTrue, setFalse];
}

/**
 * The result type is explicitly defined, because consumers get proper tuple type instead of array of possible values
 * e.g. without it consumer of useBooleanState gets "boolean | ()=>void" instead of just ()=>void;
 */
export function useBooleanState(initialState: boolean): [boolean, () => void, () => void] {
  const [state, setState] = useState(initialState);
  const [setTrue, setFalse] = useBooleanTriggers(setState);
  return [state, setTrue, setFalse];
}

export function useToggle(method: Function, value: boolean) {
  return useCallback(() => {
    method(!value);
  }, [method, value]);
}

/**
 * It does thing few things:
 * - returns `cancellablePromise` function, that wraps promises in cancellable pattern
 * - uses `useEffect` to cancel all pending promises.
 *
 * use example:
 *
 * ```tsx
 * const IAmReactComponent : FC<{ resourceId: string }> = ({ resourceId }) => {
 *   const { cancellablePromise } = useCancellablePromise;
 *   const [data, setData] = setState();
 *
 *   useEffect(()=>{
 *     cancellablePromise(resourceAPI.fetchOne(resourceId))
 *       .then(data => setData(data)); // `.then` callback won't be called if promise finishes after component is unmounted
 *   },[])
 *
 *   return // (...)
 * }
 * ```
 *
 *
 * @param {boolean} shouldBeSilent - if set to `true` cancelled promise that is still pending won't throw errors when failed
 */
export function useCancellablePromise(shouldBeSilent = false): {
  cancellablePromise: <T>(p: Promise<T>) => Promise<T>;
  cancelPromise: () => void;
} {
  const promises = useRef<CancelablePromise<any>[]>();

  const cancelPromise = useCallback(() => {
    promises.current?.forEach((p) => p.cancel());
    promises.current = [];
  }, []);

  useEffect(() => {
    promises.current = promises.current || [];

    return () => cancelPromise();
  }, [cancelPromise]);

  const cancellablePromise = useCallback(
    function cancellablePromise<T>(p: Promise<T>) {
      const cPromise = makeCancelable(p);
      promises.current?.push(cPromise);

      if (shouldBeSilent) {
        return silenceCancelable(cPromise.promise);
      }
      return cPromise.promise;
    },
    [promises, shouldBeSilent]
  );

  return { cancellablePromise, cancelPromise };
}

export function useInputValue(callback: (arg: string) => void) {
  return useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => {
      callback(e.currentTarget.value);
    },
    [callback]
  );
}

export function useQueryString() {
  const { search } = useLocation();
  return useMemo(() => {
    if (!search) {
      return '';
    }
    return search.startsWith('?') ? search : `?${search}`;
  }, [search]);
}

export function useParsedQueryString(): queryString.ParsedQuery {
  const qs = useQueryString();
  return queryString.parse(qs);
}

export function useDebouncedCallbackWithCondition(
  callback: (...args: any) => void,
  conditionFn: (value: any) => boolean = () => true,
  isLeading = false,
  delay = 250
) {
  const [debouncedCallback] = useDebouncedCallbackOriginal(callback, delay, { leading: isLeading });

  return useCallback(
    (value: any) => {
      if (conditionFn && conditionFn(value)) {
        debouncedCallback(value);
      }
    },
    [conditionFn, debouncedCallback]
  );
}

export const useDebouncedInputCallback = (callback: (...args: any[]) => void, delay = 400) => {
  const [debouncedCallback] = useDebouncedCallbackOriginal(callback, delay);

  return useCallback((e) => debouncedCallback(e.currentTarget.value), [debouncedCallback]);
};

export const useTimeout = (callback: () => void, timeout = 0): (() => void) => {
  const timeoutIdRef = useRef<NodeJS.Timeout>();
  const cancel = useCallback(() => {
    const timeoutId = timeoutIdRef.current;
    if (timeoutId) {
      timeoutIdRef.current = undefined;
      clearTimeout(timeoutId);
    }
  }, [timeoutIdRef]);

  useEffect(() => {
    timeoutIdRef.current = setTimeout(callback, timeout);
    return cancel;
  }, [callback, timeout, cancel]);

  return cancel;
};

export function useSelectedOption<Suggestion>(options: Suggestion[], shouldSelectedFirstAtInit = false) {
  const [selectedOption, setSelectedOption] = useState<Suggestion | null>(null);

  const moveSelection = useCallback(
    (mod: number) => {
      if (!options) {
        return;
      }
      const currentIndex = options.findIndex((option) => option === selectedOption);
      const newIndex = Math.min(Math.max(0, currentIndex + mod), options.length - 1);
      setSelectedOption(options[newIndex]);
    },
    [setSelectedOption, selectedOption, options]
  );

  const moveSelectionUp = useCallback(() => moveSelection(-1), [moveSelection]);
  const moveSelectionDown = useCallback(() => moveSelection(1), [moveSelection]);

  const resetSelection = useCallback(() => {
    setSelectedOption(options?.[0]);
  }, [setSelectedOption, options]);

  useEffect(() => {
    if (options && shouldSelectedFirstAtInit) {
      setSelectedOption((prevSelectedOption) => {
        if (!prevSelectedOption || !options.some((option) => option === prevSelectedOption)) {
          return options[0];
        }

        return prevSelectedOption;
      });
    }
  }, [options, setSelectedOption, shouldSelectedFirstAtInit]);

  return {
    currentlySelected: selectedOption,
    onSelectUp: moveSelectionUp,
    onSelectDown: moveSelectionDown,
    resetSelection,
  };
}

export const useNavigateTo = <T>(path: string, state?: T) => {
  const history = useHistory();
  return useCallback(() => {
    history.push(path, state);
  }, [history, path, state]);
};

export function useVariable<T>(initialValue: T): [T, (newVal: T) => void] {
  const refVal = useRef<T>(initialValue);
  const setValue = useCallback((newValue: T) => {
    refVal.current = newValue;
  }, []);
  return [refVal.current, setValue];
}

export function useNavigateToParentRoute() {
  const { url } = useRouteMatch();

  const parentUrl = useMemo(() => {
    const splittedUrl = url.split('/');
    return splittedUrl.filter((_, i) => i !== splittedUrl.length - 1).join('/');
  }, [url]);

  return useNavigateTo(parentUrl);
}
