import { CollectionAvailability } from 'services/api/collections/types';

export function createEmptyCollectionAvailability(collectionId: string) {
  return {
    collectionId,
    productsUnavailable: 0,
    productsLowInStock: 0,
    productsOutOfStock: 0,
  } as CollectionAvailability;
}
