import { setProfile } from '@frontend/commons';
import { Action } from 'redux-act';
import { call, put } from 'redux-saga/effects';

import { AuthAPI } from 'services/api/auth';

import { UserAPI } from 'services/api/user';
import { CurrentProfileResponse } from 'services/api/user/profile/types-get-current';
import { cleanAction } from 'store/clean-action';
import { createDefaultActions, createDefaultReducer } from 'store/helpers';

import { logoutSoftly } from '../common-actions';
import { getProfiles } from '../common-helpers';
import { AuthState } from '../types';

import { initState } from './common';

export const authorizeViaProfileIdAction =
  createDefaultActions<{ posId?: string; merchantId?: string }, AuthState>('AUTH_VIA_PROFILE_ID');

export const authorizeViaProfileIdReducer = createDefaultReducer<
  { posId?: string; merchantId?: string },
  AuthState,
  undefined
>(authorizeViaProfileIdAction, initState);

export function* authViaProfileIdSaga(
  action: Action<{
    posId?: string;
    merchantId?: string;
  }>
) {
  const { posId, merchantId } = action.payload;

  try {
    // @ts-ignore
    const tokenWithNewProfile = yield call(AuthAPI.authorizePosOrMerchant, { posId, merchantId });
    saveProfileTypeAndId(posId, merchantId);
    const { posProfile, merchantProfile } = yield* getProfiles(posId, merchantId, tokenWithNewProfile);
    const profile: CurrentProfileResponse = yield call(UserAPI.getCurrentProfile, tokenWithNewProfile);

    yield put(
      authorizeViaProfileIdAction.success({
        token: tokenWithNewProfile,
        profile,
        currentPosId: posId,
        currentMerchantId: merchantId,
        posProfile,
        merchantProfile,
      })
    );
    yield put(cleanAction());
  } catch (e: any) {
    yield put(authorizeViaProfileIdAction.failure(e));
    // todo: probably remove after fixing T3-10876
    // 403 should be handled in generic way
    // normally we show error to the user, but since 403 on auth means that something is really wrong,
    // we logout user -> next time user makes API call, gets 401 which is already handled generically with Session Expire modal
    // Without this IF and soft logout we had situation like blank screen, and refresh did not help
    if (e?.response?.status === 403) {
      yield logoutSoftly();
      return;
    }
    throw e;
  }
}

export function saveProfileTypeAndId(posId?: string, merchantId?: string) {
  if (posId) {
    setProfile('pos', posId);
  } else if (merchantId) {
    setProfile('merchant', merchantId);
  }
}
