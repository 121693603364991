import { getAuthToken } from '../helpers/session-cookie';

export function safelyGetLoggedUserId(): { id: string; profileId?: string; profileType?: string } {
  const authToken = getAuthToken();
  const data = typeof authToken === 'string' ? parseJwt(authToken) : undefined;
  const { merchantId, posId, userId: id } = data ?? {};

  if (!id) {
    return {
      id: 'n/a',
    };
  }

  if (merchantId) {
    return {
      id,
      profileId: merchantId,
      profileType: 'merchant',
    };
  }
  if (posId) {
    return {
      id,
      profileId: posId,
      profileType: 'pos',
    };
  }

  return {
    id,
  };
}

// todo replace that with jwt-decode
function parseJwt(token: string) {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (e) {
    console.error(e);
    return {};
  }
}
