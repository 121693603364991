import { createAction, createReducer } from 'redux-act';

import { move } from 'helpers/array';
import { SingleCollection } from 'services/api/collections/types';
import { getInitialState } from 'store/helpers';

import { cancelReorderAction } from '../rearrange/cancel-reorder.duck';

import { SingleCollectionState } from './types';

const reorderTwoCollectionItemsAction = createAction<{ from: number; to: number }>(
  'INSERT_PRODUCTS_MANAGE_COLLECTION--REORDER_TWO_COLLECTION_ITEMS'
);

const rearrangeCollectionItemsReducer = createReducer<SingleCollectionState>(
  {
    [reorderTwoCollectionItemsAction.getType()]: (state, payload) => {
      if (!state.data) {
        return state;
      }

      const items = move(state?.data?.items || [], payload.from, payload.to);
      return {
        ...state,
        data: {
          ...state.data,
          items,
        },
      };
    },
    [cancelReorderAction.end.getType()]: (state) => {
      if (!state.data) {
        return state;
      }

      return {
        ...state,
        data: {
          ...state.data,
          items: [],
        },
      };
    },
  },
  getInitialState<SingleCollection, undefined>(undefined)
);

export { reorderTwoCollectionItemsAction, rearrangeCollectionItemsReducer };
