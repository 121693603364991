import { createSelector } from 'reselect';

import { GlobalState } from '../types';

export const categoryTreeDataSelector = (state: GlobalState) => state.categoryTree.data;
export const categoryTreeCategoriesSelector = (state: GlobalState) => state.categoryTree.data?.categories;
export const categoryTreeInitialPendingSelector = (state: GlobalState) => state.categoryTree.initialPending;
export const categoryTreePendingSelector = (state: GlobalState) => state.categoryTree.pending;

export const currentCategorySelector = (categoryId: string) =>
  createSelector(categoryTreeCategoriesSelector, (categories) => categories?.find((cat) => cat.id === categoryId));

export const categoryTreeChildrenCategoriesSelector = (categoryId: string) =>
  createSelector(categoryTreeCategoriesSelector, (categories) =>
    categories?.filter((cat) => cat.parentId === categoryId)
  );

export const categoryTreeCategoriesAndDepartmentsWithNoCategoriesSelector = createSelector(
  categoryTreeCategoriesSelector,
  (categoriesTree) => {
    const departments = (categoriesTree ?? []).filter((cat) => !cat.parentId);
    const categories = (categoriesTree ?? []).filter(
      (cat) => cat.parentId && departments.some((dep) => dep.id === cat.parentId)
    );
    const departmentsWithoutCategory = departments.filter((dep) => !categories.some((cat) => cat.parentId === dep.id));

    return [...departmentsWithoutCategory, ...categories];
  }
);
