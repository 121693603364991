// tipser-elements and shared css should be loaded before anything else to ensure proper order of css on both prod and dev build
import '@tipser/tipser-elements/dist/all.css';
import '@frontend/shared/dist/bundle.css';

import {
  initSentry,
  registerBoundaryErrorHandler,
  registerCallbackErrorHandler,
  registerSagaErrorHandler,
} from '@frontend/commons';

import React from 'react';
import ReactDOM from 'react-dom';

import 'assets/stylesheets/global.scss';
import 'types/global';
import 'store';

import { App } from './components/organisms/app';
import { initializeLocalErrorModals } from './components/organisms/local-error-modals';

initSentry('https://b61b86fe9cc248a6a8ad33303efbcf07@o122433.ingest.sentry.io/5421889');

registerBoundaryErrorHandler();
registerCallbackErrorHandler();
registerSagaErrorHandler();

initializeLocalErrorModals();

ReactDOM.render(<App />, document.getElementById('root'));
