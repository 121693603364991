import { createAction, createReducer } from 'redux-act';

import { cancelReorderAction } from './cancel-reorder.duck';
import { saveReorderedCollectionActionSuccess } from './save-new-order.duck';

const isRearrangeOnReducer = createReducer({}, false);

const rearrangeStartAction = createAction('INSERT_PRODUCTS_MANAGE_COLLECTION--REARRANGE-START');

isRearrangeOnReducer.on(saveReorderedCollectionActionSuccess, () => false);
isRearrangeOnReducer.on(cancelReorderAction.end, () => false);
isRearrangeOnReducer.on(rearrangeStartAction, () => true);

export { isRearrangeOnReducer, rearrangeStartAction };
