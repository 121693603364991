import { getAuthToken, explicitlyShowErrorModal, useSafeCallback_DEPRECATED } from '@frontend/commons';
import { FC, useCallback, useEffect } from 'react';

import { SessionExpiredModal, TokenOutOfSyncModal } from 'components/organisms/local-error-modals';
import { areTokenAndAuthStateNotSynchronised } from 'services/error-categorization';

import { useSelector } from 'store';
import { authSelector } from 'store/auth/selectors';

import { isTokenUpToDate } from './is-token-up-to-date';

type Props = {};

let hidden: string;
let visibilityChange: string;

function initVisibilityAPI() {
  // Set the name of the hidden property and the change event for visibility
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }
}

export const AuthTokenSyncGuard: FC<Props> = () => {
  const authState = useSelector(authSelector);
  const safelyShowCorrectErrorModal = useSafeCallback_DEPRECATED(
    {
      callback: async () => {
        const isUpToDate = await isTokenUpToDate();

        if (isUpToDate) {
          explicitlyShowErrorModal(TokenOutOfSyncModal);
        } else {
          explicitlyShowErrorModal(SessionExpiredModal);
        }
      },
    },
    []
  );

  const expireSessionIfTokenIsAuthOfSync = useCallback(() => {
    if (!authState || !getAuthToken()) {
      // if user is not logged in - ignore
      return;
    }
    const isSynced = areTokenAndAuthStateNotSynchronised(authState);

    if (isSynced === false) {
      safelyShowCorrectErrorModal();
    }
  }, [authState, safelyShowCorrectErrorModal]);

  useEffect(() => {
    if (typeof document.addEventListener === 'undefined' || hidden === undefined) {
      console.warn('Curreny browser does not support Page Visibility API.');
      // @ts-ignore
      return;
    }

    document.addEventListener(visibilityChange, expireSessionIfTokenIsAuthOfSync, false);

    expireSessionIfTokenIsAuthOfSync();

    return () => document.removeEventListener(visibilityChange, expireSessionIfTokenIsAuthOfSync);
  }, [authState, expireSessionIfTokenIsAuthOfSync]);
  return null;
};

initVisibilityAPI();
