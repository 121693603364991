import { Button, CrossIcon, useCloseOnEsc } from '@frontend/shared';
import clsx from 'clsx';
import React, { FC, useEffect, useCallback, useRef, ReactNode, useState } from 'react';
import { createPortal } from 'react-dom';

import { useOutsideClickListener, useTriggerFalse, useTriggerTrue } from '../../../helpers/hooks';

import styles from './styles.module.scss';

type Props = {
  onClose: () => void;
  opened: boolean;
  size: 'small' | 'medium' | 'large';
  header?: ReactNode;
  footer?: ReactNode;
};

export const Modal: FC<Props> = (props) => {
  const { onClose, opened, size = 'medium', header, children, footer } = props;
  const modalRef = useRef(null);

  const handleEscapeClose = useCallback(() => onClose(), [onClose]);

  useCloseOnEsc(handleEscapeClose, opened);
  useOutsideClickListener(modalRef, onClose, opened);

  useEffect(() => {
    if (opened) {
      document.getElementsByTagName('body')[0].classList.add(styles.bodyOverflowHidden);
    }

    if (!opened) {
      document.getElementsByTagName('body')[0].classList.remove(styles.bodyOverflowHidden);
    }

    return () => {
      document.getElementsByTagName('body')[0].classList.remove(styles.bodyOverflowHidden);
    };
  }, [opened]);

  return opened
    ? createPortal(
        <div className={styles.overlay}>
          <div className={clsx(styles.modal, styles[size])} ref={modalRef}>
            <div className={styles.topBar}>
              <Button variant="icon" color="transparent" onClick={onClose}>
                <CrossIcon height="16" width="16" color="#000" />
              </Button>
            </div>
            {header && <div className={styles.header}>{header}</div>}
            <div className={styles.body}>{children}</div>
            {footer && <div className={styles.footer}>{footer}</div>}
          </div>
        </div>,
        document.getElementsByTagName('body')[0]
      )
    : null;
};

export function useHandleModal() {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = useTriggerTrue(setIsOpen);
  const closeModal = useTriggerFalse(setIsOpen);
  return {
    isOpen,
    openModal,
    closeModal,
  };
}
