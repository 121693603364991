import { PureComponent } from 'react';

import { onBoundaryError } from '../../../error-handling/boundary-error-handler/boundary-error-handler';

export class DidCatch extends PureComponent<{}, { isError: boolean }> {
  constructor(props: any) {
    super(props);

    this.state = {
      isError: false,
    };
  }

  componentDidCatch(error: any) {
    onBoundaryError(error);
  }

  static getDerivedStateFromError() {
    return { isError: true };
  }

  render() {
    if (this.state.isError) {
      return null;
    }

    return this.props.children;
  }
}
