import { safeTakeLatest } from '@frontend/commons';

import { CampaignsPayload } from 'services/api/merchants/types';
import { PosCampaignsAPI } from 'services/api/pos/campaigns';

import { cleanAction } from 'store/clean-action';
import { createDefaultAsyncHelpers } from 'store/helpers';

const request: CampaignsPayload = {
  states: ['Pending'],
  query: '',
  pageIndex: 0,
  pageSize: 1,
};

const {
  reducer,
  saga: newCampaignProposalsSaga,
  actions: newCampaignProposals,
} = createDefaultAsyncHelpers({
  fetchFunc: () => PosCampaignsAPI.getCampaigns(request),
  cleanAction,
  actionName: 'HOME_NEW_CAMPAIGN_PROPOSALS__FETCH',
  initialData: undefined,
});

const actions = {
  fetchNewCampaignProposals: newCampaignProposals.request,
};

function* saga() {
  yield safeTakeLatest(newCampaignProposals.request, newCampaignProposalsSaga);
}

export { reducer, actions, saga };
