import { safeTakeLatest } from '@frontend/commons';
import { combineReducers } from 'redux';

import { put } from 'redux-saga/effects';

import { UserAPI } from 'services/api';
import { TimeZonesResponse } from 'services/api/timezones/types-get-timezones';
import { fetchProfileActions } from 'store/auth/sub-ducks/profile-duck';
import { createDefaultAsyncHelpers } from 'store/helpers';

import { fetchTimezonesAndDateFormats } from './service';

const defaultState: {
  timeFormats: string[];
  dateFormats: string[];
  timeZones: TimeZonesResponse['timeZones'];
} = {
  dateFormats: [],
  timeFormats: [],
  timeZones: [],
};

const {
  actions: fetchActions,
  reducer: fetchReducer,
  saga: fetchSaga,
} = createDefaultAsyncHelpers({
  actionName: 'FETCH_DATE_TIME_FORMATS_AND_TIMEZONES',
  fetchFunc: fetchTimezonesAndDateFormats,
  initialData: defaultState,
});

const {
  actions: updateActions,
  reducer: updateReducer,
  saga: updateSaga,
} = createDefaultAsyncHelpers({
  actionName: 'UPDATE_USER_SETTINGS',
  fetchFunc: UserAPI.updateUserSettings,
  initialData: undefined,
});

const reducer = combineReducers({
  extraData: fetchReducer,
  updateSettingsData: updateReducer,
});

function* refreshProfileSaga() {
  yield put(fetchProfileActions.request());
}

function* saga() {
  yield safeTakeLatest(fetchActions.request, fetchSaga);
  yield safeTakeLatest(updateActions.request, updateSaga);
  yield safeTakeLatest(updateActions.success, refreshProfileSaga);
}

const actions = {
  fetchTimezonesAndDateFormats: fetchActions.request,
  updateUserSettings: updateActions.request,
};

export { saga, actions, reducer };
