import React, { FC } from 'react';

import { HARD_SPACE } from 'constants/CHARACTERS';

import { TopBar } from '../top-bar';

import styles from './styles.module.scss';

type TopBarBoltInfoProps = {
  localStorageKey: string;
};

export const TopBarBoltInfo: FC<TopBarBoltInfoProps> = ({ localStorageKey }) => {
  return (
    <TopBar className={styles.boltBar} closable localStorageKey={localStorageKey}>
      Tipser is now part of Bolt,
      {HARD_SPACE}
      <a href="https://tipser.co/post/tipser-joins-bolt" target="_blank" rel="noreferrer">
        learn more here
      </a>
      .
    </TopBar>
  );
};
