import { safeTakeEvery } from '@frontend/commons';

import { createDefaultMultiAsyncHelpers } from 'store/helpers/default-multi-async-helpers';
import { ignoreSaga404Error } from 'store/helpers/ignore-saga-404-error';

import { getCampaignDetails } from './fetch-campaign-service';

export const {
  actions: fetchCampaignActions,
  reducer,
  saga: fetchCampaignSaga,
} = createDefaultMultiAsyncHelpers('MERCHANT_SINGLE_CAMPAIGN__FETCH', getCampaignDetails, [ignoreSaga404Error]);

export function* saga() {
  yield safeTakeEvery(fetchCampaignActions.request, fetchCampaignSaga);
}
