import { useEffect, useMemo } from 'react';

import { useActionWithDispatch, useSelector } from 'store/helpers';
import { useCurrentMerchantMarket } from 'store/markets/hooks';

import { actions, cleanProductSingleFetchAction, fetchProductAction } from './duck';
import {
  brandItemsSelector,
  categoriesSelector,
  categoryTaxRatesSelector,
  departmentsSelector,
  categoryPropertySelector,
} from './selectors';

export function useProductFormResources() {
  return useSelector((state) => state.pages.productFormResources);
}

export function useCreatedProduct() {
  return useSelector((state) => state.pages.merchantCreateProductSingle);
}

export function useUpdatedProduct() {
  return useSelector((state) => state.pages.merchantUpdateProductSingle);
}

export function useBrandsItems() {
  return useSelector(brandItemsSelector);
}

export function useDepartments() {
  return useSelector(departmentsSelector);
}

export function useCategoryProperties() {
  return useSelector(categoryPropertySelector);
}

export function useCategories(parentCategoryId: string) {
  const categories = useSelector(categoriesSelector);

  return useMemo(() => categories.filter((x) => x.parentId === parentCategoryId), [categories, parentCategoryId]).sort(
    (cat1, cat2) => cat1.name.localeCompare(cat2.name)
  );
}

export function useTaxRate(categoryId: string) {
  const categoryTaxRates = useSelector(categoryTaxRatesSelector);

  return useMemo(() => categoryTaxRates.filter((x) => x.categoryId === categoryId), [categoryTaxRates, categoryId]);
}

export function useEffectFetchProductFormResources() {
  const { pristine } = useSelector((state) => state.pages.productFormResources);
  const { name: marketName, id } = useCurrentMerchantMarket();
  const dispatchFetchResourcesAction = useActionWithDispatch(actions.request);
  useEffect(() => {
    if (pristine && marketName && id) {
      dispatchFetchResourcesAction({
        market: marketName,
        marketId: id,
      });
    }
  }, [dispatchFetchResourcesAction, pristine, marketName, id]);
}

export function useEffectFetchEditedProduct(productId: string) {
  const dispatchFetchProductAction = useActionWithDispatch(fetchProductAction.request);
  const dispatchCleanFetchProductAction = useActionWithDispatch(cleanProductSingleFetchAction);
  const { pristine } = useSelector((state) => state.pages.merchantProductSingle);

  useEffect(() => {
    if (pristine) {
      dispatchFetchProductAction({ productId });
    }
  }, [dispatchFetchProductAction, pristine, productId]);

  useEffect(() => {
    return () => {
      dispatchCleanFetchProductAction();
    };
  }, [dispatchCleanFetchProductAction]);
}
