import { safeTakeLatest } from '@frontend/commons';
import { createAction } from 'redux-act';

import { getUserProfiles } from 'services/api/user/profile/get-user-profiles';
import { createDefaultAsyncHelpers } from 'store/helpers';

export const tokenOutOfSyncModalClosedAction = createAction('TOKEN_OUT_OF_SYNC_MODAL__CLOSE');

const {
  actions: { request: requestAction },
  reducer,
  saga: asyncSaga,
} = createDefaultAsyncHelpers({
  actionName: 'TOKEN_OUT_OF_SYNC_MODAL__FETCH_PROFILE',
  cleanAction: tokenOutOfSyncModalClosedAction,
  fetchFunc: getUserProfiles,
  initialData: undefined,
});

export { reducer, requestAction };

export function* saga() {
  yield safeTakeLatest(requestAction, asyncSaga);
}
