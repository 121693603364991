import { safeTakeLatest } from '@frontend/commons';

import { PosCampaignsAPI } from 'services/api/pos/campaigns';
import { cleanAction } from 'store/clean-action';
import { createDefaultAsyncHelpers } from 'store/helpers';

const {
  reducer,
  saga: fetchAsyncSaga,
  actions: fetchActions,
} = createDefaultAsyncHelpers({
  actionName: 'POS_CAMPAIGNS__ALL_FETCH',
  fetchFunc: PosCampaignsAPI.getCampaigns,
  cleanAction,
  initialData: undefined,
});

const fetchCampaignsAction = fetchActions.request;

export { reducer, fetchCampaignsAction };

export function* saga() {
  yield safeTakeLatest(fetchCampaignsAction, fetchAsyncSaga);
}
