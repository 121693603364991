import { PosMerchantResponse } from 'services/api/pos/merchants/types-get-merchant';
import { GlobalState } from 'store/types';

export const posCampaignProposalMerchantSelector: (
  merchantId?: string
) => (state: GlobalState) => { data: PosMerchantResponse | undefined; isLoading: boolean } =
  (merchantId) => (state) => ({
    data: merchantId ? state.pages.posCampaignSingle?.merchant?.data?.[merchantId] : undefined,
    isLoading: merchantId ? state.pages.posCampaignSingle?.merchant?.pending[merchantId] : false,
  });
