import React, { FC, useCallback } from 'react';

import { ConfirmationModal } from '../../../molecules/confirmation-modal';

type Props = { onClose: () => void };

export const ChunkErrorModal: FC<Props> = ({ onClose }) => {
  const reloadApplication = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <ConfirmationModal
      hideRejectButton
      title="Portal has been updated"
      content={`Click the "Reload" or browser’s refresh button to experience newest portal.`}
      confirmButtonLabel="Reload"
      onConfirm={reloadApplication}
      onReject={onClose}
    />
  );
};
