import { useEffect, useMemo } from 'react';

import { usePosMarket } from 'store/auth/hooks';

import { merchantMarkets } from '../auth/selectors';
import { useActionWithDispatch, useSelector } from '../helpers';

import { fetchMarketsActions } from './duck';
import { marketsSelector } from './selectors';

export function useMarkets() {
  const fetchMarkets = useActionWithDispatch(fetchMarketsActions.request);
  const markets = useSelector(marketsSelector);

  useEffect(() => {
    if (markets?.length === 0) {
      fetchMarkets();
    }
  }, [fetchMarkets, markets?.length]);
  return markets;
}

export function useCurrentMerchantMarket() {
  const markets = useMarkets();
  const currentMerchantMarketId = useSelector(merchantMarkets)[0];
  const currentMarket = useMemo(
    () => markets?.find((market) => currentMerchantMarketId === market.id),
    [markets, currentMerchantMarketId]
  );

  return currentMarket ?? {};
}

export function useMerchantShippingCompanies() {
  const market = useCurrentMerchantMarket();
  return market?.shippingCompanies?.map((company) => company.name) || [];
}

export function useCurrentPosMarket() {
  const markets = useMarkets();
  const currentPosMarketId = usePosMarket();

  return useMemo(() => markets.find((market) => currentPosMarketId === market.id) || {}, [markets, currentPosMarketId]);
}
