import { put } from 'services/api/base';

import { Product } from './types-search-for-products';
import { MetadataPayload } from './types-update-metadata';

export function updateMetadata({
  productId,
  payload,
}: {
  productId: string;
  payload: MetadataPayload;
}): Promise<{ updatedProduct: Product }> {
  return put(`v5/pos/product/${productId}/metadata`, { json: payload }).json();
}
