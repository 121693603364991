import { safeTakeLatest } from '@frontend/commons';

import { InvitationsAPI } from 'services/api';
import { UserInvitationsResponse } from 'services/api/invitations/types-get-user-invitations';
import { createDefaultActions, createDefaultReducer, createDefaultSaga } from 'store/helpers';

export const fetchInvitations = createDefaultActions<void, UserInvitationsResponse>(
  'PROFILE_SWITCHER__GET_INVITATIONS'
);

export const fetchInvitationsReducer = createDefaultReducer(fetchInvitations, undefined);

export const saga = createDefaultSaga(InvitationsAPI.getUserInvitations, fetchInvitations);

export function* fetchInvitationsSaga() {
  yield safeTakeLatest(fetchInvitations.request, saga);
}
