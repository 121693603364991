import { safeTakeLatest } from '@frontend/commons';

import { push } from 'connected-react-router';
import { put } from 'redux-saga/effects';

import { UserAPI } from 'services/api';
import { fetchProfileActions } from 'store/auth/sub-ducks/profile-duck';
import { createDefaultAsyncHelpers } from 'store/helpers';

const {
  actions: updateActions,
  reducer,
  saga: updateSaga,
} = createDefaultAsyncHelpers({
  actionName: 'UPDATE_USER_DISCOVERY_SETTINGS',
  fetchFunc: UserAPI.updateUserSettings,
  initialData: undefined,
});

function* redirectSaga() {
  yield put(fetchProfileActions.request());
  yield put(push('/pos/discover-merchants/'));
}

function* saga() {
  yield safeTakeLatest(updateActions.request, updateSaga);
  yield safeTakeLatest(updateActions.success, redirectSaga);
}

const actions = { updateDiscoverySettings: updateActions.request };

export { saga, actions, reducer };
