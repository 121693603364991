import { createReducer, ActionCreator } from 'redux-act';

import { createDefaultActions, createDefaultSaga } from './default-async-helpers';
import { DefaultActions, MemoizedAsyncState, DefaultError } from './types';

const getInitialMemoizedState = <SuccessPayload, InitialData, FailurePayload = DefaultError>(
  data: InitialData
): MemoizedAsyncState<SuccessPayload, InitialData, FailurePayload> => ({
  data,
  pending: false,
});

export const createMemoizedReducer = <
  RequestPayload,
  SuccessPayload,
  InitialData = SuccessPayload[],
  FailurePayload = DefaultError
>(
  actions: DefaultActions<RequestPayload, SuccessPayload, FailurePayload>,
  uniqueIdSelector: (singleItem: SuccessPayload) => any,
  initialData: InitialData,
  cleanAction?: ActionCreator<any>
) =>
  createReducer<MemoizedAsyncState<SuccessPayload, InitialData, FailurePayload>>(
    {
      [actions.request.toString()]: (state) => ({
        data: state.data,
        error: undefined,
        pending: true,
      }),
      [actions.success.toString()]: (state, payload) => ({
        pending: false,
        data: replaceOrAdd(state.data as SuccessPayload[], payload, uniqueIdSelector),
        error: undefined,
      }),
      [actions.failure.toString()]: (state, payload) => ({
        pending: false,
        data: state.data,
        error: payload,
      }),
      ...(cleanAction
        ? {
            [cleanAction.toString()]: () => getInitialMemoizedState(initialData),
          }
        : {}),
    },
    getInitialMemoizedState<SuccessPayload, InitialData, FailurePayload>(initialData)
  );

function replaceOrAdd<T>(collection: Array<T>, toAddOrReplace: T, uniqueIdSelector: (item: T) => any): T[] {
  const id = uniqueIdSelector(toAddOrReplace);
  const index = collection.findIndex((item) => uniqueIdSelector(item) === id);
  if (index === -1) {
    return [...collection, toAddOrReplace];
  }
  const newCollection = [...collection];
  newCollection.splice(index, 1, toAddOrReplace);
  return newCollection;
}

export const createMemoizedAsyncHelpers = <
  RequestPayload,
  SuccessPayload,
  InitialData = SuccessPayload,
  FailurePayload = DefaultError
>({
  actionName,
  initialData,
  fetchFunc,
  uniqueIdSelector,
  cleanAction,
}: {
  actionName: string;
  initialData: InitialData;
  fetchFunc: (arg: RequestPayload) => Promise<SuccessPayload>;
  uniqueIdSelector: (singleItem: SuccessPayload) => any;
  cleanAction?: ActionCreator<any>;
}) => {
  const actions = createDefaultActions<RequestPayload, SuccessPayload, FailurePayload>(actionName);
  const reducer = createMemoizedReducer<RequestPayload, SuccessPayload, InitialData, FailurePayload>(
    actions,
    uniqueIdSelector,
    initialData,
    cleanAction
  );
  const saga = createDefaultSaga<RequestPayload, SuccessPayload, FailurePayload>(fetchFunc, actions);
  const initialState = getInitialMemoizedState<SuccessPayload, InitialData, FailurePayload>(initialData);

  return {
    actions,
    reducer,
    saga,
    initialState,
  };
};
