import { safeTakeLatest } from '@frontend/commons';

import { PosSearchedMerchant } from 'services/api/pos/merchants/types-search-merchants';
import { cleanAction } from 'store/clean-action';
import { createDefaultAsyncHelpers } from 'store/helpers';

import { searchMerchants } from '../service';

const {
  actions,
  reducer,
  saga: localSaga,
} = createDefaultAsyncHelpers({
  actionName: 'CATEGORY-MERCHANTS__SEARCH-MERCHANTS',
  fetchFunc: searchMerchants,
  cleanAction,
  initialData: {
    total: 0,
    merchants: [] as PosSearchedMerchant[],
  },
});

reducer.off(actions.success);

reducer.on(actions.success, (state, payload) => ({
  pending: false,
  error: undefined,
  data: {
    total: payload.total,
    merchants: payload.requestedPage === 0 ? payload.merchants : [...state.data.merchants, ...payload.merchants],
  },
  initialPending: false,
  pristine: false,
}));

const searchMerchantRequestAction = actions.request;

function* saga() {
  yield safeTakeLatest(searchMerchantRequestAction, localSaga);
}

export { searchMerchantRequestAction, saga, reducer };
