import { put } from '../base';

export function reorder({
  collectionId,
  items,
  isPrivate,
}: {
  collectionId: string;
  items: Array<string>;
  isPrivate: boolean;
}): Promise<void> {
  const updateContext = isPrivate ? 'users' : 'pos';
  return put(`v5/${updateContext}/collections/${collectionId}/ordering`, { json: { items } }).json();
}
