import { safeTakeLatest } from '@frontend/commons';

import { replaceOrAddElements } from 'helpers/array';
import { Customization } from 'services/api/product-customization/types';
import { cleanAction } from 'store/clean-action';
import { createDefaultAsyncHelpers } from 'store/helpers';

import { handleProductFetchError } from '../helpers/handle-product-fetch-error';

import { createOrUpdateProductCustomizationActions } from './create-or-update-duck';
import { fetchProductWithoutCustomization } from './fetch-service';
import { FetchProductCustomizationState } from './types';

const {
  saga,
  reducer: fetchProductCustomizationReducer,
  actions,
} = createDefaultAsyncHelpers({
  actionName: 'INSERT_PRODUCTS__FETCH_PRODUCT_CUSTOMIZATION',
  fetchFunc: fetchProductWithoutCustomization,
  cleanAction,
  sagaErrorHandlers: [handleProductFetchError],
  initialData: undefined,
});

function* fetchProductCustomizationSaga() {
  yield safeTakeLatest(actions.request, saga);
}

fetchProductCustomizationReducer.on(createOrUpdateProductCustomizationActions.success, (state, payload) =>
  assignPayloadAsCurrentCustomization(state, payload.customization)
);

const fetchProductCustomizationAction = actions.request;

export { fetchProductCustomizationAction, fetchProductCustomizationReducer, fetchProductCustomizationSaga };

function assignPayloadAsCurrentCustomization(
  state: FetchProductCustomizationState,
  customization: Customization
): FetchProductCustomizationState {
  if (!state.data) {
    return state;
  }

  return {
    ...state,
    data: {
      ...state.data,
      customization: {
        customizations: replaceOrAddElements(state.data?.customization?.customizations, [customization], (x) => x.id),
      },
    },
  };
}
