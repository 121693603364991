/* eslint-disable max-len */
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const MULTIPLE_EMAIL_REGEX =
  /^(([a-zA-Z0-9_\-.+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,15}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))+$/;
export const BIC_REGEX = /^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/;
export const SE_IBAN_REGEX =
  /^(\d{4,5}-\d{11,14})|(\d{3,4}-\d{4})|([A-Z]{2}[0-9]{2}[A-Z0-9]{4}[0-9]{7}([A-Z0-9]?){0,16})$/;
export const BANKGIRO_REGEX = /^(\d{3,4}-\d{4})$/;
export const I_IBAN_REGEX = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{4}[0-9]{7}([A-Z0-9]?){0,16}$/;
export const NUMBER_REGEX = /^(?=.*[0-9])[- +()0-9]+$/;
export const VAT_REGEX = /^[A-Z]{0,3}[0-9]{2,13}$/;
export const LINK_REGEX = /(?:(?:[\D]{0,5}):\/\/)?[\w\-?=%.]+\.[\w]+/;
export const LINK_WITH_PROTOCOL_REGEX = /^((?:https?:\/\/)[^./]+(?:\.[^./]+)+(?:\/.*)?)$/;
export const ABSOLUTE_LINK = /^(?:[a-z]+:)?\/\//i;

export const MONGO_ID_REGEX = /^[a-f\d]{24}$/;
export const DOUBLE_MONGO_ID_REGEX = /^[a-f\d]{24}_[a-f\d]{24}$/;
export const LETTERS_AND_NUMBERS_REGEX = /[^A-Za-z0-9]/;
export const DIACRITICAL_MARKS = /[\u0300-\u036f]/g;
