import { safeTakeLatest } from '@frontend/commons';
import { Action, createReducer } from 'redux-act';
import { call, put, select } from 'redux-saga/effects';

import { PAGE_SIZE } from 'constants/PAGINATION';
import { CollectionsAPI } from 'services/api/collections';
import { SingleCollection, SingleCollectionProductItem } from 'services/api/collections/types';
import { createDefaultActions, getInitialState } from 'store/helpers';
import { DefaultAsyncState } from 'store/helpers/types';
import { AsyncReturnType } from 'types/async-return-type';

import { collectionFiltersSelector } from '../filters';
import { isRearrangeModeOnSelector } from '../rearrange';

import { currentCollectionSelector } from './selectors';
import { SingleCollectionState } from './types';

const fetchMoreCollectionItemsActions = createDefaultActions<number, SingleCollection>(
  'INSERT_PRODUCTS__FETCH_MORE_COLLECTION_ITEMS'
);

const fetchMoreCollectionItemsReducer = createReducer<SingleCollectionState>(
  {
    [fetchMoreCollectionItemsActions.request.getType()]: (state) => ({
      ...state,
      pending: true,
    }),
    [fetchMoreCollectionItemsActions.success.getType()]: (
      state,
      payload: { items: SingleCollectionProductItem[]; numberOfItems: number }
    ) => {
      if (!state.data) {
        return state;
      }

      if (state.data.id !== payload.items[0]?.collectionId) {
        return state;
      }

      const currentIds = new Set(state.data.items.map((_) => _.id));
      const uniqueProducts = payload.items.filter((item) => !currentIds.has(item.id));

      return {
        ...state,
        pending: false,
        data: {
          ...state.data,
          items: state.data.items.concat(uniqueProducts),
          numberOfItems: payload.numberOfItems,
        },
      };
    },
    [fetchMoreCollectionItemsActions.failure.getType()]: (state, payload) => ({
      ...state,
      pending: false,
      error: payload,
    }),
  },
  getInitialState<SingleCollection, undefined>(undefined)
);

function* fetchMoreCollectionProductsLocalSaga(action: Action<number>) {
  const collection = currentCollectionSelector(yield select()) as DefaultAsyncState<SingleCollection>;
  const filters = collectionFiltersSelector(yield select());
  const isRearrangeOn = isRearrangeModeOnSelector(yield select());

  const apiPayload = isRearrangeOn
    ? {
        collectionId: collection.data.id,
        isPrivate: collection.data.isPrivate,
        take: PAGE_SIZE,
        skip: action.payload,
      }
    : {
        collectionId: collection.data.id,
        isPrivate: collection.data.isPrivate,
        take: PAGE_SIZE,
        skip: action.payload,
        ...filters,
      };

  try {
    const data = (yield call(CollectionsAPI.getById, apiPayload)) as AsyncReturnType<typeof CollectionsAPI.getById>;
    yield put(fetchMoreCollectionItemsActions.success(data));
  } catch (e) {
    yield put(fetchMoreCollectionItemsActions.failure(e));
    throw e;
  }
}

function* fetchMoreCollectionItemsSaga() {
  yield safeTakeLatest(fetchMoreCollectionItemsActions.request, fetchMoreCollectionProductsLocalSaga);
}

export { fetchMoreCollectionItemsActions, fetchMoreCollectionItemsSaga, fetchMoreCollectionItemsReducer };
