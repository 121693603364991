import { createReducer } from 'redux-act';

import { fetchCollectionActions, fetchMoreCollectionItemsActions } from '../collection';
import { manageCollectionViewActions } from '../manage-collection-view';

const defaultState = 1;

export const pageNumberReducer = createReducer(
  {
    [fetchCollectionActions.request.toString()]: () => defaultState,
    [fetchMoreCollectionItemsActions.success.toString()]: (state) => state + 1,
  },
  defaultState
);

pageNumberReducer.on(manageCollectionViewActions.registerClosedView, () => defaultState);
