import { safeTakeLatest } from '@frontend/commons';

import { OrdersAPI } from 'services/api/merchants/orders';
import { OrdersPayload, OrdersResponse } from 'services/api/merchants/orders/types-orders';
import { cleanAction } from 'store/clean-action';
import { createDefaultActions, createDefaultReducer, createDefaultSaga } from 'store/helpers';

export const fetchOrdersTable = createDefaultActions<OrdersPayload, OrdersResponse>('ORDERS__FETCH_ORDERS');

export const reducer = createDefaultReducer(fetchOrdersTable, undefined, cleanAction);

export const fetchOrdersTableSaga = createDefaultSaga(OrdersAPI.getOrders, fetchOrdersTable);

export function* saga() {
  yield safeTakeLatest(fetchOrdersTable.request, fetchOrdersTableSaga);
}
