import { Location } from 'history';
import React, { FC, useMemo, lazy } from 'react';
import { Switch, Route, useRouteMatch, Redirect, RouteProps } from 'react-router-dom';

import { withProps } from 'recompose';

import { createDefaultModule } from 'helpers/module';
import { removeTrailingSlash } from 'helpers/url-helpers';

const MerchantGalleryRouting = lazy(() =>
  import(/* webpackChunkName: "page-pos-merchant-gallery" */ './merchant-gallery').then(
    createDefaultModule((m) => m.MerchantGalleryRouting)
  )
);

const Home = lazy(() =>
  import(/* webpackChunkName: "page-pos-home" */ './home').then(createDefaultModule((m) => m.Home))
);

const Brands = lazy(() =>
  import(/* webpackChunkName: "page-pos-brands" */ './brands').then(createDefaultModule((m) => m.Brands))
);

const Installation = lazy(() =>
  import(/* webpackChunkName: "page-pos-installation" */ './installation').then(
    createDefaultModule((m) => m.Installation)
  )
);
const Page404 = lazy(() =>
  import(/* webpackChunkName: "page-pos-page404" */ './page404').then(createDefaultModule((m) => m.Page404))
);
const Sales = lazy(() =>
  import(/* webpackChunkName: "page-pos-sales" */ './sales/sales').then(createDefaultModule((m) => m.Sales))
);
const CollectionsAndInsertProducts = lazy(() =>
  import(/* webpackChunkName: "page-pos-insert-products" */ './insert-products/collections-and-insert-products').then(
    createDefaultModule((m) => m.CollectionsAndInsertProducts)
  )
);
const Analytics = lazy(() =>
  import(/* webpackChunkName: "page-pos-analytics" */ './analytics-dashboard').then(
    createDefaultModule((m) => m.Analytics)
  )
);
const Campaigns = lazy(() =>
  import(/* webpackChunkName: "page-pos-campaigns" */ './campaigns').then(createDefaultModule((m) => m.Campaigns))
);
const CampaignProposal = lazy(() =>
  import(/* webpackChunkName: "page-pos-campaign-proposal" */ './campaign-single/campaign-proposal').then(
    createDefaultModule((m) => m.CampaignProposal)
  )
);

const CampaignDetails = lazy(() =>
  import(/* webpackChunkName: "page-pos-campaign-details" */ './campaign-single').then(
    createDefaultModule((m) => m.CampaignDetails)
  )
);

export const Pos: FC = () => {
  const { url } = useRouteMatch();
  const CollectionsAndInsertProductsWithParentUrl = useMemo(
    () => withProps({ parentUrl: url })(CollectionsAndInsertProducts),
    [url]
  );

  return (
    <Switch>
      <Route path={`${url}/home`} component={Home} />
      <Route path={`${url}/analytics`} component={Analytics} />
      <Route path={`${url}/inventory`} component={CollectionsAndInsertProductsWithParentUrl} />
      <Route path={`${url}/collection`} component={CollectionsAndInsertProductsWithParentUrl} />
      <Route path={`${url}/brands`} component={Brands} />
      <Route path={`${url}/campaign-proposals/:id`} component={CampaignProposal} />
      <Route path={`${url}/campaigns/:id`} component={CampaignDetails} />
      <Route path={`${url}/campaigns`} component={Campaigns} />
      <Route path={`${url}/installation`} component={Installation} />
      <Route path={`${url}/sales`} component={Sales} />
      <Route path={`${url}/discover-merchants`} component={MerchantGalleryRouting} />
      {/* insert-products redirects */}
      <Route path={`${url}/insert-products`} component={InsertProductsRedirect} />
      {/* End of insert-products redirects */}
      <Route path={`${url}`} exact render={() => <Redirect to={`${removeTrailingSlash(url)}/home`} />} />
      <Route component={Page404} />
    </Switch>
  );
};

const InsertProductsRedirect: FC<RouteProps<string, any>> = (props) => {
  const { pathname, search } = props.location as Location;
  const newPathname = pathname.replace('/pos/insert-products', '/pos/inventory');
  return <Redirect to={newPathname + search} />;
};
