import { createSelector } from 'reselect';

import { GlobalState } from 'store/types';

export const newMerchantsSelector = (state: GlobalState) => state.pages.posHome.newMerchants;
export const newMerchantsWithProductsSelector = createSelector(
  newMerchantsSelector,
  (newMerchants) => newMerchants.data?.newMerchants?.filter((merchant) => !!merchant.products.length) || []
);
export const getNewMerchantsTotalSelector = (state: GlobalState) => state.pages.posHome.newMerchants.data?.total || 0;
