import { getAuthToken, sentryErrorHandler } from '@frontend/commons';
import jwtDecode from 'jwt-decode';
import memoizeOne from 'memoize-one';

export function getDecodedAuthToken(): { posId?: string; merchantId?: string } {
  const authToken = getAuthToken();

  if (!authToken) {
    return {};
  }

  try {
    const { posId, merchantId } = memoizedDecodeToken(authToken) ?? {};
    return { posId, merchantId };
  } catch (e) {
    console.warn(e);
    sentryErrorHandler(e);
    return {};
  }
}

const memoizedDecodeToken = memoizeOne((token: string): {
  posId: string | undefined;
  merchantId: string | undefined;
} => {
  return jwtDecode(token);
});
