import { getShortTipserOriginEnv, SimpleSpinner as Spinner } from '@frontend/commons';
import { ConnectedRouter } from 'connected-react-router';
import React, { FC, useEffect, Suspense, lazy } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';

import { createDefaultModule } from 'helpers/module';
import { redirectToOldAppUserProfile } from 'helpers/redirect-to-old-app';
import { Merchant } from 'pages/merchant';
import { Pos } from 'pages/pos';
import { User } from 'pages/user';
import { history } from 'routes/history';
import { userTypeSelector } from 'store/auth';
import { logoutSoftly } from 'store/auth/common-actions';
import { useActionWithDispatch, useSelector } from 'store/helpers';
import { initActions } from 'store/init';

import { ProtectedRoute } from './protected-route';
import { RedirectRoot } from './redirect-root';

const Auth = lazy(() =>
  import(/* webpackChunkName: "page-auth" */ 'pages/auth').then(createDefaultModule((m) => m.Auth))
);

const Dev = lazy(() => import(/* webpackChunkName: "page-dev" */ 'pages/dev').then(createDefaultModule((m) => m.Dev)));

const DevError = lazy(() =>
  import(/* webpackChunkName: "page-dev-error" */ 'pages/dev').then(createDefaultModule((m) => m.DevError))
);

const MerchantForm = lazy(() =>
  import(/* webpackChunkName: "merchant-form" */ 'pages/merchant/merchant-form').then(
    createDefaultModule((m) => m.MerchantForm)
  )
);

const MerchantGuides = lazy(() =>
  import(/* webpackChunkName: "page-merchant-guides" */ 'pages/merchant/merchant-guides').then(
    createDefaultModule((m) => m.MerchantGuides)
  )
);

const ProfileSwitcher = lazy(() =>
  import(/* webpackChunkName: "page-profile-switcher" */ 'pages/profile-switcher').then(
    createDefaultModule((m) => m.ProfileSwitcher)
  )
);

const isDev = getShortTipserOriginEnv() === 'dev';

export const Routes: FC = () => {
  const initiateApp = useActionWithDispatch(initActions.initiate);

  useEffect(() => {
    initiateApp();
  }, [initiateApp]);

  return (
    <ConnectedRouter history={history}>
      <Suspense fallback={<Spinner fadeIn="delayed" />}>
        <Switch>
          <ProtectedRoute
            validFor={['unauthenticated', 'user']}
            path="/"
            exact
            component={RedirectToAuthOrOldAppUserProfile}
          />
          <ProtectedRoute validFor="unauthenticated" path="/login" exact component={RedirectToAuth} />
          <Route path="/auth" component={Auth} />
          <ProtectedRoute validFor="pos" path="/pos" component={Pos} />
          <ProtectedRoute validFor="merchant" path="/merchant" component={Merchant} />
          <ProtectedRoute validFor={['merchant', 'pos']} path="/user" component={User} />
          <ProtectedRoute
            validFor={['merchant', 'pos']}
            path="/profiles/:invitationToken?"
            component={ProfileSwitcher}
          />
          {/* Contentfull pages, in future we should scope them under /merchant */}
          <ProtectedRoute validFor="merchant" path="/merchant-form" component={MerchantForm} />
          <ProtectedRoute validFor="merchant" path="/merchants-guides" component={MerchantGuides} />
          <Route path="/dev/error" component={DevError} />
          {isDev && <Route path="/dev" component={Dev} />}
          <RedirectRoot />
        </Switch>
      </Suspense>
    </ConnectedRouter>
  );
};

function RedirectToAuthOrOldAppUserProfile() {
  const userType = useSelector(userTypeSelector);
  const dispatchLogoutSoftly = useActionWithDispatch(logoutSoftly);

  if (userType === 'user') {
    dispatchLogoutSoftly();
    redirectToOldAppUserProfile();
    return null;
  }

  return <Redirect to="/auth" />;
}

function RedirectToAuth() {
  return <Redirect to="/auth" />;
}
