import { safeTakeLatest } from '@frontend/commons';
import { AnyAction } from 'redux';
import { put, select } from 'redux-saga/effects';

import { SingleCollection } from 'services/api/collections/types';
import { collectionsActions } from 'store/collections/actions';
import {
  RemoveProductByCollectionItemIdFromCollectionPayload,
  RemoveProductByProductIdFromCollectionPayload,
} from 'store/collections/types';

import { fetchMoreCollectionItemsReducer, fetchMoreCollectionItemsSaga } from './collection-fetch-more.duck';
import { fetchCollectionActions, fetchCollectionReducer, fetchCollectionSaga } from './collection-fetch.duck';
import { rearrangeCollectionItemsReducer } from './collection-rearrange.duck';
import { currentCollectionSelector } from './selectors';
import { SingleCollectionState } from './types';

const collectionReducer = (state: SingleCollectionState | undefined, action: AnyAction): SingleCollectionState =>
  fetchCollectionReducer(
    fetchMoreCollectionItemsReducer(rearrangeCollectionItemsReducer(state, action), action),
    action
  );

function* removeProductByCollectionItemIdFromCollectionSaga({
  payload,
}: {
  payload: RemoveProductByCollectionItemIdFromCollectionPayload;
}) {
  const collection = currentCollectionSelector(yield select());
  const items = collection.data?.items.filter((item) => payload.collectionItemId !== item.id);

  if (!items) {
    return;
  }

  yield put(
    fetchCollectionActions.success({
      ...(collection.data as SingleCollection),
      items,
    })
  );
}

function* removeProductByProductIdFromCollectionSaga({
  payload,
}: {
  payload: RemoveProductByProductIdFromCollectionPayload;
}) {
  const collection = currentCollectionSelector(yield select());
  const items = (collection.data?.items ?? []).filter(
    (item) => payload.collectionId !== item.collectionId || payload.productId !== item.product.id
  );

  if (!items) {
    return;
  }

  yield put(
    fetchCollectionActions.success({
      ...(collection.data as SingleCollection),
      items,
    })
  );
}

function* collectionSaga() {
  yield safeTakeLatest(
    collectionsActions.removeProductByCollectionItemId.request,
    removeProductByCollectionItemIdFromCollectionSaga
  );
  yield safeTakeLatest(collectionsActions.removeProductbyProductId.request, removeProductByProductIdFromCollectionSaga);
  yield* fetchCollectionSaga();
  yield* fetchMoreCollectionItemsSaga();
}

export { collectionReducer, collectionSaga };
