import { safeTakeLatest } from '@frontend/commons';
import { push } from 'connected-react-router';
import { Action, createAction } from 'redux-act';

import { put } from 'redux-saga/effects';

import { productIsCreatedAction } from 'components/organisms/merchant-activation-top-bar/store/duck';
import { Product } from 'services/api/merchants/products/types-product';
import { cleanAction } from 'store/clean-action';
import { createDefaultAsyncHelpers } from 'store/helpers';

import { getSingleProduct, updateSingleProduct, createSingleProduct } from './fetch-product';
import { fetchAllRelatedResources } from './fetch-services';
import { handleDuplicatedSKUSagaAsyncErrorHandler } from './handle-duplicated-sku-async-saga-error-handler';

const cleanProductSingleFetchAction = createAction('PRODUCT_SINGLE__FETCH_PRODUCT__CLEAN');

const {
  saga: productSingleBrandsSaga,
  reducer,
  actions,
  initialState,
} = createDefaultAsyncHelpers({
  actionName: 'PRODUCT_SINGLE__RESOURCES',
  fetchFunc: fetchAllRelatedResources,
  cleanAction,
  initialData: undefined,
});

const {
  actions: fetchProductAction,
  reducer: fetchProductReducer,
  saga: fetchProductSaga,
} = createDefaultAsyncHelpers({
  cleanAction: cleanProductSingleFetchAction,
  actionName: 'PRODUCT_SINGLE__FETCH_PRODUCT',
  fetchFunc: getSingleProduct,
  initialData: undefined,
});

const {
  actions: updateProductAction,
  reducer: updateProductReducer,
  saga: updateProductSaga,
} = createDefaultAsyncHelpers({
  cleanAction,
  actionName: 'PRODUCT_SINGLE__UPDATE_PRODUCT',
  fetchFunc: updateSingleProduct,
  sagaErrorHandlers: [handleDuplicatedSKUSagaAsyncErrorHandler],
  initialData: undefined,
});

const {
  actions: createProductAction,
  reducer: createProductReducer,
  saga: createProductSaga,
} = createDefaultAsyncHelpers({
  cleanAction,
  actionName: 'PRODUCT_SINGLE__CREATE_PRODUCT',
  fetchFunc: createSingleProduct,
  sagaErrorHandlers: [handleDuplicatedSKUSagaAsyncErrorHandler],
  initialData: undefined,
});

function* navigateToEditPageSaga(action: Action<Product>) {
  yield put(productIsCreatedAction());
  yield put(push(`/merchant/products/${action.payload.id}`));
}

export {
  reducer,
  actions,
  initialState,
  fetchProductAction,
  fetchProductReducer,
  fetchProductSaga,
  updateProductAction,
  updateProductReducer,
  updateProductSaga,
  createProductAction,
  createProductReducer,
  createProductSaga,
  cleanProductSingleFetchAction,
};

export function* saga() {
  yield safeTakeLatest(actions.request, productSingleBrandsSaga);
  yield safeTakeLatest(fetchProductAction.request, fetchProductSaga);
  yield safeTakeLatest(updateProductAction.request, updateProductSaga);
  yield safeTakeLatest(createProductAction.request, createProductSaga);
  yield safeTakeLatest(createProductAction.success, navigateToEditPageSaga);
}
