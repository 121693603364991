import { get } from 'services/api/base';
import {
  PreviewCampaignProductsRequest,
  PreviewCampaignProductsResponse,
} from 'services/api/common-types/types-campaign-product-preview';

export function fetchPosCampaignProductsPreview(
  request: PreviewCampaignProductsRequest
): Promise<PreviewCampaignProductsResponse> {
  return get(`v5/pos/campaigns/products-preview`, {
    searchParams: { ...request, productIds: request.productIds.join(',') },
  }).json();
}
