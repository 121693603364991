import * as Sentry from '@sentry/react';
import { Action } from 'redux-act';

import { safelyGetLoggedUserId } from './safely-get-logged-user-id';

import { isSentryInitialized } from './sentry-init';

export const sentryErrorHandler = (arg: { error: any; action: Action<any> } | any) => {
  if (!isSentryInitialized()) {
    return;
  }

  const [error, extraContent] =
    arg.error != null && arg.action != null ? [arg.error, { reduxAction: arg.action.type }] : [arg, {}];

  const userDetails = safelyGetLoggedUserId();
  Sentry.captureException(error, {
    user: userDetails,
    extra: extraContent,
  });
};
