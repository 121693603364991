const messageRegex = /Product with SKU: \S+ already exists\./;
const existingProductIdRegex = /Existing product id: (\S+)/;

export async function handleDuplicatedSKUSagaAsyncErrorHandler(e: any, next: () => undefined) {
  if (e?.response?.json === undefined) {
    return next();
  }
  const json = await e.response.json();
  const message = json?.error?.message;

  if (!message?.match || !message.match(messageRegex)) {
    next();
  }

  const [, existingProductId] = message.match(existingProductIdRegex);

  return {
    existingProductId,
    skuAlreadyTaken: true,
  };
}
