import { FunctionComponent } from 'react';

let explicitlyShowErrorModalHandler: (ModalComponent: FunctionComponent<{ onClose: () => void }>) => void;

export function registerExplicitlyShowErrorModalHandler(callback: typeof explicitlyShowErrorModalHandler) {
  explicitlyShowErrorModalHandler = callback;
}

export function removeExplicitlyShowErrorModalHandler(callback: typeof explicitlyShowErrorModalHandler) {
  if (explicitlyShowErrorModalHandler === callback) {
    // @ts-ignore
    explicitlyShowErrorModalHandler = undefined;
  }
}
export function explicitlyShowErrorModal(ModalComponent: FunctionComponent<{ onClose: () => void }>): void {
  explicitlyShowErrorModalHandler?.(ModalComponent);
}
