import {
  CampaignProductPreview,
  PreviewCampaignProductsResponse,
} from 'services/api/common-types/types-campaign-product-preview';
import { CampaignProposal, FetchCampaignProposalRequest } from 'services/api/merchants/campaign-proposals';
import { fetchCampaignProposal } from 'services/api/merchants/campaign-proposals/fetch-campaign-proposal';
import { MerchantCampaignsAPI } from 'services/api/merchants/campaigns';

type CampaignProposalDetails = {
  proposal: CampaignProposal;
  products: CampaignProductPreview[];
};

export async function getCampaignProposalDetails(
  request: FetchCampaignProposalRequest
): Promise<CampaignProposalDetails> {
  const proposal = await fetchCampaignProposal(request);

  const productsPreview: PreviewCampaignProductsResponse = await MerchantCampaignsAPI.fetchCampaignProductsPreview({
    discountType: proposal.actions.discount.discountType,
    discountValue: proposal.actions.discount.discountValue,
    productIds: proposal.campaignConditions.productIds,
    totalCommission: proposal.commission.totalCommission,
  });

  return {
    proposal,
    products: productsPreview.products,
  };
}
