import React, { createContext, ReactNode, FC } from 'react';

export const PageWithSidebarContext = createContext<{ renderExtraContent?: () => ReactNode }>({
  renderExtraContent: () => null,
});

type Props = {
  renderExtraContent?: () => ReactNode;
};
export const PageWithSidebarContextProvider: FC<Props> = ({ renderExtraContent, children }) => {
  return <PageWithSidebarContext.Provider value={{ renderExtraContent }}>{children}</PageWithSidebarContext.Provider>;
};
