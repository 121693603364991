import React, { FC, lazy } from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import { createDefaultModule } from 'helpers/module';
import { removeTrailingSlash } from 'helpers/url-helpers';

const Settings = lazy(() =>
  import(/* webpackChunkName: "page-user-settings" */ './settings').then(createDefaultModule((m) => m.Settings))
);

export const User: FC = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/settings`} component={Settings} />
      <Route path={`${url}`} exact render={() => <Redirect to={`${removeTrailingSlash(url)}/settings`} />} />
    </Switch>
  );
};
