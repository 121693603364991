import { GlobalState } from 'store/types';

export function saveReorderedCollectionItemsSelector(state: GlobalState) {
  return state.pages.insertProducts.singleCollection.rearrangeCollection.saveNewOrder;
}

export function itemsReferenceSelector(state: GlobalState) {
  return state.pages.insertProducts.singleCollection.rearrangeCollection.itemsReference;
}

export function isRearrangeModeOnSelector(state: GlobalState) {
  return state.pages.insertProducts.singleCollection.rearrangeCollection.isRearrangeOn;
}
