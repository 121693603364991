import { safeTakeLatest } from '@frontend/commons';

import { MerchantPosesAPI } from 'services/api';
import { GetPosesResponse } from 'services/api/merchants/poses/types-get-poses';
import { createDefaultActions, createDefaultReducer, createDefaultSaga } from 'store/helpers';

export const initState: GetPosesResponse | null = null;

export const fetchMerchantPosesActions = createDefaultActions<void, GetPosesResponse>('MERCHANT_POSES__FETCH');

export const getMerchantPosesReducer = createDefaultReducer(fetchMerchantPosesActions, initState);

const saga = createDefaultSaga(MerchantPosesAPI.getPoses, fetchMerchantPosesActions);

export function* fetchMerchantPosesSaga() {
  yield safeTakeLatest(fetchMerchantPosesActions.request, saga);
}
