import { safeTakeLatest } from '@frontend/commons';

import { createDefaultAsyncHelpers } from 'store/helpers';

import { fetchMerchantLatestProducts } from './service';

const {
  saga: fetchLatestProductsSaga,
  actions: fetchLatestProductsActions,
  reducer,
} = createDefaultAsyncHelpers({
  fetchFunc: fetchMerchantLatestProducts,
  actionName: 'MERCHANT_LATEST_PRODUCTS__GET',
  initialData: undefined,
});

const actions = {
  fetchLatestProductsAction: fetchLatestProductsActions.request,
};

function* saga() {
  yield safeTakeLatest(fetchLatestProductsActions.request, fetchLatestProductsSaga);
}

export { saga, actions, reducer };
