import Fuse from 'fuse.js';

import { createSelector } from 'reselect';

import { uniqueCombineArray } from 'helpers/array';
import { FilterGroups } from 'types/filters';

import { GetProductSearchSuggestions, SuggestionStringFilter } from '../../autosuggestion-popup/types';
import { UNCATEGORIZED } from '../../constants';

import { availableCountedFiltersSelector } from './available-filters-selectors';

const findMatchedFilters = (filters: FilterGroups, word: string, filterOut: SuggestionStringFilter[]) => {
  return filters.reduce((acc, filterGroup) => {
    const fuse = new Fuse(filterGroup.available, {
      minMatchCharLength: 3,
      findAllMatches: true,
      threshold: 0.05,
      keys: ['value'],
    });
    const toFilterOut = filterOut.map((f) => f.value);
    const newFilters = fuse.search(word).map((searchResultItem) => searchResultItem.item);
    if (newFilters.length > 6) {
      newFilters.length = 6;
    }

    return [
      ...acc,
      ...newFilters
        .map((f) => ({ name: f.value, value: f.id, type: filterGroup.value } as SuggestionStringFilter))
        .filter((result) => !toFilterOut.includes(result.value)),
    ];
  }, [] as SuggestionStringFilter[]);
};

const findExactMatchedFilters = (filters: FilterGroups, word: string) => {
  return filters.reduce((acc, filterGroup) => {
    const fuse = new Fuse(filterGroup.available, {
      findAllMatches: true,
      threshold: 0.1,
      distance: 200,
      keys: ['value'],
    });

    const newFilters = fuse.search(word).map((searchResultItem) => searchResultItem.item);
    if (newFilters.length > 6) {
      newFilters.length = 6;
    }

    return [
      ...acc,
      ...newFilters.map((f) => ({ name: f.value, value: f.id, type: filterGroup.value } as SuggestionStringFilter)),
    ];
  }, [] as SuggestionStringFilter[]);
};

const FILTERS_ORDER = ['colors', 'subcategories', 'categories', 'brands', 'merchants', 'departments', 'genders'];
const NON_SEARCHABLE_FILTERS = ['keywords', 'price'];
const NON_SEARCHABLE_FILTERS_AVAILABLES = [UNCATEGORIZED];
export const getFiltersMatchSelector = createSelector(
  availableCountedFiltersSelector,
  (allFiltersGroups: FilterGroups) =>
    (query: string): GetProductSearchSuggestions => {
      const filterableFilters = allFiltersGroups
        .filter((filterGroup) => !NON_SEARCHABLE_FILTERS.includes(filterGroup.id))
        .map((filterGroup) => ({
          ...filterGroup,
          available: filterGroup.available.filter(
            (available) => !NON_SEARCHABLE_FILTERS_AVAILABLES.includes(available.value.toString())
          ),
        }))
        .sort((a, b) => {
          const aIndex = FILTERS_ORDER.indexOf(a.id);
          const bIndex = FILTERS_ORDER.indexOf(b.id);
          if (aIndex > bIndex) {
            return 1;
          }
          if (aIndex < bIndex) {
            return -1;
          }
          return 0;
        });

      const combinedFilters: SuggestionStringFilter[][] = [];
      const exactSuggestions = findExactMatchedFilters(filterableFilters, query);
      const words = query.trim().split(' ');
      const relatedSuggestions = words.reduce<SuggestionStringFilter[][]>(
        (acc, word) => [...acc, findMatchedFilters(filterableFilters, word, exactSuggestions)],
        [] as SuggestionStringFilter[][]
      );

      // if it founds an exact match, show them at the beginning
      if (exactSuggestions.length > 0) {
        combinedFilters.push(...exactSuggestions.map((match) => [match]));
        if (combinedFilters.length > 6) {
          combinedFilters.length = 6;
        }
      }

      combinedFilters.push(
        ...uniqueCombineArray(relatedSuggestions, 'type', exactSuggestions.length > 6 ? 0 : 6 - exactSuggestions.length)
      );

      return combinedFilters;
    }
);
