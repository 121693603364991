import React, { FC } from 'react';

import { ConfirmationModal } from '../../../molecules/confirmation-modal';

type Props = {
  closeModal: () => void;
  handleRetry: () => void;
};

const TITLE_TEXT = 'Some error occurred :(';
const CONTENT_TEXT =
  'We apologise for the inconvenience. The team has been notified about the problem and we are working on the fix.';

export const RetryableErrorModal: FC<Props> = ({ closeModal, handleRetry }) => {
  return (
    <>
      <ConfirmationModal
        title={TITLE_TEXT}
        content={CONTENT_TEXT}
        onConfirm={handleRetry}
        onReject={closeModal}
        confirmButtonLabel="Try again"
        rejectButtonLabel="Close"
      />
    </>
  );
};
