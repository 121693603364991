import { flow, keyBy, assign, values } from 'lodash';
import { createReducer, ActionCreator } from 'redux-act';

import { createDefaultActions, createDefaultSaga, getInitialState } from './default-async-helpers';
import { DefaultActions, DefaultError, MemoizedCollectionAsyncState } from './types';

export const getInitialMemoizedCollectionState = <SuccessPayloadItem, InitialData, FailedPayload>(
  data: InitialData
): MemoizedCollectionAsyncState<SuccessPayloadItem, InitialData, FailedPayload> => ({
  data,
  initialPending: false,
  pending: false,
  pristine: true,
});

export const createMemoizedCollectionReducer = <
  RequestPayload,
  SuccessPayloadItem,
  InitialData = SuccessPayloadItem[],
  FailurePayload = DefaultError
>(
  actions: DefaultActions<RequestPayload, SuccessPayloadItem[], FailurePayload>,
  uniqueIdSelector: (singleItem: SuccessPayloadItem) => any,
  initialData: InitialData,
  cleanAction?: ActionCreator<any>
) =>
  createReducer<MemoizedCollectionAsyncState<SuccessPayloadItem, InitialData, FailurePayload>>(
    {
      [actions.request.toString()]: (state) => ({
        data: state.data,
        error: undefined,
        initialPending: state.data === initialData,
        pending: true,
        pristine: false,
      }),
      [actions.success.toString()]: (state, payload) => ({
        pending: false,
        initialPending: false,
        data: replaceOrAdd(state.data, payload, uniqueIdSelector),
        pristine: false,
        error: undefined,
      }),
      [actions.failure.toString()]: (state, payload) => ({
        pending: false,
        initialPending: false,
        data: state.data,
        error: payload,
        pristine: false,
      }),
      ...(cleanAction
        ? {
            [cleanAction.toString()]: () => getInitialMemoizedCollectionState(initialData),
          }
        : {}),
    },
    getInitialMemoizedCollectionState<SuccessPayloadItem, InitialData, FailurePayload>(initialData)
  );

export const createMemoizedCollectionAsyncHelpers = <
  RequestPayload,
  SuccessPayloadItem,
  InitialData = SuccessPayloadItem[],
  FailurePayload = DefaultError
>({
  actionName,
  initialData,
  fetchFunc,
  uniqueIdSelector,
  cleanAction,
}: {
  actionName: string;
  initialData: InitialData;
  fetchFunc: (arg: RequestPayload) => Promise<SuccessPayloadItem[]>;
  uniqueIdSelector: (singleItem: SuccessPayloadItem) => any;
  cleanAction?: ActionCreator<any>;
}) => {
  const actions = createDefaultActions<RequestPayload, SuccessPayloadItem[], FailurePayload>(actionName);
  const reducer = createMemoizedCollectionReducer<RequestPayload, SuccessPayloadItem, InitialData, FailurePayload>(
    actions,
    uniqueIdSelector,
    initialData,
    cleanAction
  );
  const saga = createDefaultSaga<RequestPayload, SuccessPayloadItem[], FailurePayload>(fetchFunc, actions);
  const initialState = getInitialState<SuccessPayloadItem[], InitialData, FailurePayload>(initialData);

  return {
    actions,
    reducer,
    saga,
    initialState,
  };
};

function replaceOrAdd<SuccessPayloadItem, InitialData extends Array<any>>(
  oldCollection: SuccessPayloadItem[] | InitialData,
  newCollection: SuccessPayloadItem[] | InitialData,
  uniqueIdSelector: (item: SuccessPayloadItem) => any
): SuccessPayloadItem[] {
  const oldCollectionObj = keyBy(oldCollection, uniqueIdSelector);
  const newCollectionObj = keyBy(newCollection, uniqueIdSelector);

  return flow(assign, values)(oldCollectionObj, newCollectionObj);
}
