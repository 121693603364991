import { get } from 'services/api/base';

import {
  GetSingleCampaignsSummaryRequest,
  GetSingleCampaignsSummaryResponse,
} from './types-get-single-campaigns-summary';

export function getPosSingleCampaignSummary(
  payload: GetSingleCampaignsSummaryRequest
): Promise<GetSingleCampaignsSummaryResponse> {
  return get(`alfred/v1/pos/campaigns/${payload.id}/summary`).json();
}
