import { combineReducers } from 'redux';

import {
  fetchInvitationsReducer,
  fetchInvitationsSaga,
} from 'store/user-profiles/fetch-invitations/fetch-invitations-duck';

import { acceptInvitationSaga, acceptInvitationReducer } from './accept-invitation/accept-invitation-duck';
import {
  fetchProfilesSaga,
  fetchProfilesReducer,
  fetchUserProfilesActions,
} from './fetch-profiles/fetch-profiles-duck';
import {
  setProductEditorTermsSaga,
  setProductEditorTermsReducer,
} from './set-product-editor-terms/set-product-editor-terms-duck';
import { State } from './types';

export const reducer = combineReducers<State>({
  fetchProfiles: fetchProfilesReducer,
  setProductEditorTerms: setProductEditorTermsReducer,
  fetchInvitations: fetchInvitationsReducer,
  acceptInvitation: acceptInvitationReducer,
});

export function* saga() {
  yield* fetchProfilesSaga();
  yield* setProductEditorTermsSaga();
  yield* fetchInvitationsSaga();
  yield* acceptInvitationSaga();
}

export { fetchUserProfilesActions };
