import { KnownProductMultiSelectFilters } from 'types/model/products-search';

export type KnownMultiselectFiltersKeys = keyof KnownProductMultiSelectFilters;
const KNOWN_NESTED_SEARCH_FILTERS: KnownMultiselectFiltersKeys[] = ['departments', 'categories', 'subcategories'];
export const KNOWN_SEARCH_FILTERS_ORDERED_TOP: KnownMultiselectFiltersKeys[] = [
  'departments',
  'categories',
  'subcategories',
  'sections',
  'genders',
  'materials',
  'colors',
];
export const KNOWN_SEARCH_FILTERS_ORDERED_BOTTOM: KnownMultiselectFiltersKeys[] = ['brands', 'merchants', 'keywords'];

export const ALL_KNOWN_FILTERS = [
  ...KNOWN_NESTED_SEARCH_FILTERS,
  ...KNOWN_SEARCH_FILTERS_ORDERED_TOP,
  ...KNOWN_SEARCH_FILTERS_ORDERED_BOTTOM,
];
