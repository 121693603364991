import { getBasicAuthToken, setTempInitialToken } from '@frontend/commons';

import { getTipserOriginUrl } from './url-helpers';

export function redirectToOldAppUserProfile() {
  redirectToOldApp('/profile/account');
}

export function redirectToOldApp(path: string) {
  const globalToken = getBasicAuthToken();
  if (!globalToken) {
    return;
  }

  setTempInitialToken(globalToken);
  window.location.href = getTipserOriginUrl() + path;
}
