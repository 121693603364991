import { safeTakeLatest } from '@frontend/commons';

import { MarketsAPI } from 'services/api';
import { Market } from 'services/api/markets/types-get-markets';

import { createDefaultActions, createDefaultReducer, createDefaultSaga } from '../helpers';

export const fetchMarketsActions = createDefaultActions<void, Market[]>('MARKETS__FETCH');

const fetchMarketsSaga = createDefaultSaga(MarketsAPI.getMarkets, fetchMarketsActions);

export const reducer = createDefaultReducer(fetchMarketsActions, []);

export function* saga() {
  yield safeTakeLatest(fetchMarketsActions.request, fetchMarketsSaga);
}
