import { safeTakeLatest } from '@frontend/commons';
import { chunk } from 'lodash';
import { Action, createReducer } from 'redux-act';
import { put, select, call } from 'redux-saga/effects';

import { replaceOrAddElements } from 'helpers/array';
import { CommissionsAPI, PosProductsAPI } from 'services/api';
import { ProductCommission } from 'services/api/common-types/types-commissions';
import { ProductSingleDetailed } from 'services/api/pos/products/types-get-one-product';
import { Product, ProductsSearchResponse } from 'services/api/pos/products/types-search-for-products';
import { Customization } from 'services/api/product-customization/types';
import { authActions } from 'store/auth';
import { createDefaultActions, createDefaultReducer, createDefaultSaga } from 'store/helpers';
import { createMemoizedReducer } from 'store/helpers/memoized-async-helpers';
import { createMemoizedCollectionReducer } from 'store/helpers/memoized-collection-async-helpers';

import { AsyncReturnType } from 'types/async-return-type';
import { GeneratorReturnType } from 'types/generator-return-type';

import { customizationImageConverter } from '../../product-details/product-images-container/customization-image-converter';

import { resetFiltersAndResult } from '../common';
import { handleProductFetchError } from '../helpers/handle-product-fetch-error';
import { getMarketId, productsSearchPayloadConverter, scrollWindowToTop } from '../helpers/helpers';

import { productDetailsUpdateMetadataAction } from '../metadata';
import { createOrUpdateProductCustomizationActions } from '../product-customization/create-or-update-duck';
import { ProductFetchErrorPayload } from '../product-customization/types';
import { SearchParamsState } from '../search-params/types';

import { allSearchParamsSelector } from '../selectors';

import { ProductsState } from './types';

export const fetchSingleDetailedProduct = createDefaultActions<
  { productId: string; posId?: string },
  ProductSingleDetailed,
  ProductFetchErrorPayload
>('INSERT_PRODUCTS__FETCH_SINGLE_DETAILED_PRODUCT');

export const fetchProducts = createDefaultActions<SearchParamsState, ProductsSearchResponse>(
  'INSERT_PRODUCTS__FETCH_PRODUCTS'
);

export const fetchMoreProducts = createDefaultActions<number, ProductsSearchResponse>(
  'INSERT_PRODUCTS__FETCH_MORE_PRODUCTS'
);
export const fetchCommissions = createDefaultActions<string[], ProductCommission[]>(
  'INSERT_PRODUCTS__FETCH_COMMISSIONS'
);

export const fetchCommissionsReducer = createMemoizedCollectionReducer<string[], ProductCommission>(
  fetchCommissions,
  (x) => `${x.productId}_${x.commission}`,
  [],
  authActions.authorizeViaProfileId.success
);

export const detailedProductsReducer = createMemoizedReducer<
  { productId: string; posId?: string },
  ProductSingleDetailed
>(fetchSingleDetailedProduct, ({ id }: ProductSingleDetailed) => id, [], authActions.authorizeViaProfileId.success);

detailedProductsReducer.on(productDetailsUpdateMetadataAction.success, (state, payload) => {
  return {
    ...state,
    data: replaceOrAddElements(state.data, [payload.updatedProduct], (x) => x.id),
  };
});

const fetchProductsInitialData: ProductsState = {
  data: undefined,
  pending: false,
  initialPending: false,
  pristine: true,
};

const fetchProductsReducer = createDefaultReducer<SearchParamsState, ProductsSearchResponse, undefined>(
  fetchProducts,
  undefined,
  authActions.authorizeViaProfileId.success
);

fetchProductsReducer.on(createOrUpdateProductCustomizationActions.success, (state, payload) =>
  updateProductAfterCustomizationChange(state, payload)
);

function updateProductAfterCustomizationChange(
  state: ProductsState,
  payload: { customization: Customization; productWithoutCustomization: ProductSingleDetailed }
) {
  if (state.data == null) {
    return state;
  }

  const { products } = state.data;

  const matchedProductIndex = products.findIndex((product) => payload.customization.productId === product.id);

  if (matchedProductIndex === -1) {
    return state;
  }

  const matchedProduct = products[matchedProductIndex];

  const newMatchedProduct = {
    ...matchedProduct,
    images:
      payload.customization.publicImages?.map((image) => customizationImageConverter.toCloudinaryImage(image)) ??
      payload.productWithoutCustomization?.images,
    posTags: payload.customization.posTags ?? payload.productWithoutCustomization?.posTags,
    title: payload.customization.title ?? payload.productWithoutCustomization?.title,
  };

  const newProducts = [...products];
  newProducts.splice(matchedProductIndex, 1, newMatchedProduct);

  return {
    ...state,
    data: {
      ...state.data,
      products: newProducts,
    },
  };
}

const fetchMoreProductsReducer = createReducer<ProductsState>(
  {
    [fetchMoreProducts.request.getType()]: (state) => ({
      data: state.data,
      error: undefined,
      pending: true,
      initialPending: state.data === fetchProductsInitialData.data,
      pristine: false,
    }),
    [fetchMoreProducts.success.getType()]: (state, payload: { products: Product[] }) => {
      // do not process "fetch more", if there are no data -> this is probably some rouge or "orphan" side effect
      if (!state.data) {
        return state;
      }

      const currentIds = new Set(state.data?.products.map((_) => _.id) ?? []);
      const uniqueProducts = payload.products.filter((product) => !currentIds.has(product.id));
      return {
        pending: false,
        data: {
          ...state.data,
          products: state.data.products.concat(uniqueProducts),
        },
        initialPending: false,
        pristine: false,
      };
    },
    [fetchMoreProducts.failure.getType()]: (_, payload) => ({
      pending: false,
      data: undefined,
      error: payload,
      initialPending: false,
      pristine: false,
    }),
    [resetFiltersAndResult.getType()]: () => ({ ...fetchProductsInitialData }),
  },
  fetchProductsInitialData
);

export const pageNumberReducer = createReducer(
  {
    [fetchProducts.success.toString()]: () => 1,
    [fetchMoreProducts.success.toString()]: (state) => state + 1,
  },
  0
);

export const productsReducer = (state: ProductsState | undefined, action: any): ProductsState =>
  fetchProductsReducer(fetchMoreProductsReducer(state, action), action);

function* productsSaga(action: Action<SearchParamsState>) {
  const marketId = yield* getMarketId();
  if (!marketId) {
    return;
  }

  try {
    const apiPayload = productsSearchPayloadConverter.fromParams({ params: action.payload, marketId });
    const data = (yield call(PosProductsAPI.search, apiPayload)) as AsyncReturnType<typeof PosProductsAPI.search>;
    yield put(fetchProducts.success(data));
    yield call(scrollWindowToTop);
  } catch (e) {
    yield put(fetchProducts.failure(e));
    throw e;
  }
}

const detailedProductsSaga = createDefaultSaga(PosProductsAPI.getOne, fetchSingleDetailedProduct, [
  handleProductFetchError,
]);

/** CommissionsAPI.getMany fails if too many products ids are in payload, so we need to split big payloads into chunks */
function* fetchSafelyCommissionSaga(action: Action<string[]>) {
  const chunksOfIds = chunk(action.payload, 60);
  for (let i = 0; i < chunksOfIds.length; i += 1) {
    const commissions = (yield call(CommissionsAPI.getMany, chunksOfIds[i])) as AsyncReturnType<
      typeof CommissionsAPI.getMany
    >;
    yield put(fetchCommissions.success(commissions));
  }
}

function* fetchMoreProductsSaga(action: Action<number>) {
  const marketId = (yield call(getMarketId)) as GeneratorReturnType<typeof getMarketId>;
  const params = allSearchParamsSelector(yield select());

  if (!marketId) {
    return;
  }

  const apiPayload = productsSearchPayloadConverter.fromParams({
    params,
    skip: action.payload,
    marketId,
  });

  try {
    const data = (yield call(PosProductsAPI.search, apiPayload)) as AsyncReturnType<typeof PosProductsAPI.search>;
    yield put(fetchMoreProducts.success(data));
  } catch (e) {
    yield put(fetchMoreProducts.failure(e));
    throw e;
  }
}

export function* fetchProductsSaga() {
  yield safeTakeLatest(fetchProducts.request, productsSaga);
  yield safeTakeLatest(fetchProducts.request, scrollWindowToTop);
  yield safeTakeLatest(fetchMoreProducts.request, fetchMoreProductsSaga);
  yield safeTakeLatest(fetchSingleDetailedProduct.request, detailedProductsSaga);
  yield safeTakeLatest(fetchCommissions.request, fetchSafelyCommissionSaga);
}
