import clsx from 'clsx';
import React, { ButtonHTMLAttributes, FC } from 'react';

import styles from './button-like-link-styles.module.scss';

type Underline = keyof typeof underLineToClassName;
type Props = {
  underline?: Underline;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const underLineToClassName = {
  'hide-on-hover': styles.underLineHideOnHover,
  'show-on-hover': styles.underLineShowOnHover,
  'static-hidden': styles.underLineStaticHidden,
  'static-visible': styles.underLineStaticVisible,
};

export const ButtonLikeLink: FC<Props> = ({
  type = 'button',
  underline = 'static-visible',
  className,
  ...restOfProps
}) => {
  const finalClassName = clsx(className, styles.buttonLikeLink, underLineToClassName[underline]);
  // eslint-disable-next-line react/button-has-type
  return <button {...restOfProps} type={type} className={finalClassName} />;
};
