import { useEffect } from 'react';

import { useActionWithDispatch } from 'store/helpers';

import { actions } from './new-merchants.duck';

export function useEffectFetchNewMerchants({
  firstActivationDate = null,
  pageIndex = 0,
  pageSize = 20,
  filterByUserMerchantDiscoveryPreferences = false,
}: {
  firstActivationDate?: Date | null;
  pageIndex?: number;
  pageSize?: number;
  filterByUserMerchantDiscoveryPreferences?: boolean;
}) {
  const dispatchFetchNewMerchantsAction = useActionWithDispatch(actions.fetchNewMerchants);

  useEffect(() => {
    dispatchFetchNewMerchantsAction({
      firstActivationDate,
      pageIndex,
      pageSize,
      filterByUserMerchantDiscoveryPreferences,
    });
  }, [
    dispatchFetchNewMerchantsAction,
    firstActivationDate,
    pageIndex,
    pageSize,
    filterByUserMerchantDiscoveryPreferences,
  ]);
}
