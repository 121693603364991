import clsx from 'clsx';
import React, { FC, useContext, useMemo } from 'react';

import { ScrollToTopOnPathChange } from 'components/atoms/scroll-to-top';

import { PageWithSidebarContext } from './page-with-sidebar-context';
import styles from './styles.module.scss';

type PropTypes = {
  contentClassName?: string;
  sidebarClassName?: string;
  sidebar?: React.ReactNode;
  sidebarSize?: 'normal' | 'wide';
  spacedContent?: boolean;
  spacedBottom?: boolean;
  fullHeight?: boolean;
  renderExtraContent?: () => React.ReactNode;
  disableScrollToTop?: boolean;
};

const sidebarSizeToSidebarClassName = {
  normal: styles.sidebarNormal,
  wide: styles.sidebarWide,
};

const sidebarSizeToContentClassName = {
  normal: styles.contentNormal,
  wide: styles.contentWide,
};

export const PageWithSidebar: FC<PropTypes> = ({
  sidebarSize = 'normal',
  sidebarClassName,
  contentClassName,
  renderExtraContent,
  spacedContent,
  spacedBottom,
  fullHeight,
  sidebar,
  children,
  disableScrollToTop,
}) => {
  const context = useContext(PageWithSidebarContext);
  const sidebarSizeClassName = useMemo(() => sidebarSizeToSidebarClassName[sidebarSize], [sidebarSize]);
  const contentSizeClassName = useMemo(() => sidebarSizeToContentClassName[sidebarSize], [sidebarSize]);
  return (
    <>
      {!disableScrollToTop && <ScrollToTopOnPathChange />}
      <div className={styles.container}>
        <aside className={clsx(sidebarClassName, styles.sidebar, sidebarSizeClassName)}>{sidebar}</aside>
        <div
          className={clsx(contentClassName, styles.content, contentSizeClassName, { [styles.fullHeight]: fullHeight })}
        >
          {context.renderExtraContent?.()}
          {renderExtraContent?.()}
          <div
            className={clsx({
              [styles.fullHeight]: fullHeight,
              [styles.spacedContent]: spacedContent,
              [styles.spacedBottom]: spacedBottom,
            })}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
};
