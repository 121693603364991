import { combineReducers } from 'redux';

import { collectionReducer, collectionSaga } from './collection';
import { filtersReducer } from './filters/filters.duck';
import { manageCollectionViewReducer } from './manage-collection-view';
import { pageNumberReducer } from './page-number';
import { rearrangeReducer, rearrangeSaga } from './rearrange';
import { State } from './types';

export const reducer = combineReducers<State>({
  collection: collectionReducer,
  filters: filtersReducer,
  pageNumber: pageNumberReducer,
  view: manageCollectionViewReducer,
  rearrangeCollection: rearrangeReducer,
});

export function* saga() {
  yield* collectionSaga();
  yield* rearrangeSaga();
}
