import { CollectionsAPI } from 'services/api/collections';
import { CollectionAvailability } from 'services/api/collections/types';

import { createEmptyCollectionAvailability } from '../../helpers/collection-availability';

export type RemoveProductAndFetchStockCountResponse = CollectionAvailability[];

export async function removeProductAndFetchStockCount({
  collectionItemId,
  isPrivate,
  collectionId,
}: {
  collectionItemId: string;
  isPrivate: boolean;
  collectionId: string;
}): Promise<RemoveProductAndFetchStockCountResponse> {
  await CollectionsAPI.removeProduct({ collectionId, isPrivate, collectionItemId });
  return fetchSingleAvailability(collectionId, isPrivate);
}

export async function removeProductsAndFetchStockCount({
  collectionItemsIds,
  productsIds,
  isPrivate,
  collectionId,
}: {
  collectionItemsIds: string[];
  productsIds: string[];
  isPrivate: boolean;
  collectionId: string;
}): Promise<RemoveProductAndFetchStockCountResponse> {
  await CollectionsAPI.removeProducts({ collectionId, isPrivate, collectionItemsIds, productsIds });
  return fetchSingleAvailability(collectionId, isPrivate);
}

export type AddProductAndFetchStockCountResponse = CollectionAvailability[];

export async function addProductAndFetchStockCount({
  productIds,
  isPrivate,
  collectionId,
}: {
  productIds: string[];
  isPrivate: boolean;
  collectionId: string;
}): Promise<RemoveProductAndFetchStockCountResponse> {
  await CollectionsAPI.addProducts({ collectionId, isPrivate, productIds });
  return fetchSingleAvailability(collectionId, isPrivate);
}

async function fetchSingleAvailability(collectionId: string, isPrivate: boolean) {
  const fetchAvailabilities = isPrivate
    ? CollectionsAPI.getPrivateCollectionsProductsAvailability
    : CollectionsAPI.getSharedCollectionsProductsAvailability;
  const collectionAvailabilities = await fetchAvailabilities([collectionId]);
  if (!collectionAvailabilities.find((x) => x.collectionId === collectionId)) {
    collectionAvailabilities.push(createEmptyCollectionAvailability(collectionId));
  }
  return collectionAvailabilities;
}
