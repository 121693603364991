import { post } from '../base';

export function removeProducts({
  collectionId,
  isPrivate,
  productsIds = [],
  collectionItemsIds = [],
}: {
  collectionId: string;
  isPrivate: boolean;
  productsIds: string[];
  collectionItemsIds: string[];
}): Promise<void> {
  const updateContext = isPrivate ? 'users' : 'pos';
  return post(`v5/${updateContext}/collections/${collectionId}/items/batch-delete`, {
    json: { productsIds, collectionItemsIds },
  }).json();
}
