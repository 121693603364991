import { Action } from 'redux-act';
import { takeLeading, takeEvery, takeLatest, all } from 'redux-saga/effects';

type EffectType = typeof takeLatest | typeof takeLeading | typeof takeEvery;
let onErrorActionCreators: ((arg: { error: any; action?: Action<unknown> }) => void)[] = [];

export function registerHandlerOnSagaError(callback: (arg: { error: any; action?: Action<unknown> }) => void) {
  onErrorActionCreators.push(callback);
}

export function removeHandlerOnSagaError(callback: (arg: { error: any; action?: Action<unknown> }) => void) {
  onErrorActionCreators = onErrorActionCreators.filter((x) => x !== callback);
}

function createSafeEffect(effect: EffectType) {
  return function T<ActionPayload extends SagaPayload, SagaPayload>(
    pattern: ((payload: ActionPayload) => unknown) | ((payload: ActionPayload) => unknown)[] | string,
    generator: (action: Action<SagaPayload>) => any
  ) {
    // @ts-ignore - the typings here are not perfect, still coulnd't figure how to get rid of this ts-ignore
    return effect(pattern, function* (action: Action<ActionPayload>) {
      try {
        yield generator(action);
      } catch (e) {
        console.error(e);
        yield all(onErrorActionCreators.map((callback) => callback({ error: e, action })));
      }
    });
  };
}

export const safeTakeLatest = createSafeEffect(takeLatest);
export const safeTakeEvery = createSafeEffect(takeEvery);
