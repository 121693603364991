import { Action } from 'redux-act';

import { errorModalErrorHandler } from '../../components/organisms/error-modal';
import { sentryErrorHandler } from '../../sentry';

export function globalErrorHandler(arg: { error: any; action: Action<any> } | any) {
  const { handledNicely } = errorModalErrorHandler(arg);

  if (!handledNicely) {
    sentryErrorHandler(arg);
  }
}
