import { isEmpty } from 'lodash';
import { call, put, select, take } from 'redux-saga/effects';

import { UserAPI } from 'services/api';
import { CurrentProfileResponse } from 'services/api/user/profile/types-get-current';
import { MerchantProfile, PosProfile } from 'services/api/user/profile/types-get-user-profiles';

import { fetchUserProfilesActions } from '../user-profiles';

import { getMerchantsById } from './selectors';

export function getDefaultPosId(profile: CurrentProfileResponse) {
  return profile.posIds[0];
}

export function getDefaultMerchantId(profile: CurrentProfileResponse) {
  return profile.merchantIds[0];
}

// Saga helper
export function* getProfiles(posId: string | undefined, merchantId: string | undefined, token: string) {
  const defaultProfiles: { posProfile: PosProfile | undefined; merchantProfile: MerchantProfile | undefined } = {
    merchantProfile: undefined,
    posProfile: undefined,
  };

  if (posId) {
    // @ts-ignore
    const posProfile = yield call(UserAPI.getPosProfileById, token);
    // @ts-ignore
    return yield {
      ...defaultProfiles,
      posProfile,
    };
  }

  if (merchantId) {
    // @ts-ignore
    const merchantsById = yield select(getMerchantsById);
    if (isEmpty(merchantsById)) {
      yield put(fetchUserProfilesActions.request(token));
      yield take(fetchUserProfilesActions.success);
      // @ts-ignore
      const newMerchantsById = yield select(getMerchantsById);
      // @ts-ignore
      return yield {
        ...defaultProfiles,
        merchantProfile: newMerchantsById[merchantId],
      };
    }

    // @ts-ignore
    return yield {
      ...defaultProfiles,
      merchantProfile: merchantsById[merchantId],
    };
  }

  // @ts-ignore
  return yield defaultProfiles;
}

export function getCurrentProfiles(token: string): Promise<CurrentProfileResponse> {
  return UserAPI.getCurrentProfile(token);
}
