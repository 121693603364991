import { get } from 'services/api/base';

import {
  GetSingleCampaignProposalResponse,
  GetSingleCampaignsProposalRequest,
} from './types-get-single-campaign-proposals';

export function getMerchantSingleCampaignsProposal(
  payload: GetSingleCampaignsProposalRequest
): Promise<GetSingleCampaignProposalResponse> {
  return get(`v5/merchant/campaigns/${payload.id}/proposal`).json();
}
