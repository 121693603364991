import { safeTakeLatest } from '@frontend/commons';
import { combineReducers } from 'redux';

import { createAction } from 'redux-act';

import { setMerchantReady } from 'services/api/merchants/set-ready';
import { cleanAction } from 'store/clean-action';
import { createDefaultAsyncHelpers } from 'store/helpers';

import { fetchActivationData } from './service';
import { State } from './types';

const {
  actions: fetchSettingsActions,
  reducer: fetchSettingsReducer,
  saga: fetchSettingsDefaultAsyncSaga,
} = createDefaultAsyncHelpers({
  cleanAction,
  fetchFunc: fetchActivationData,
  actionName: 'MERCHANT_ACTIVATION__SETTINGS_FETCH',
  initialData: undefined,
});

const productIsCreatedAction = createAction('MERCHANT_ACTIVATION__PRODUCT_IS_CREATED');

fetchSettingsReducer.on(productIsCreatedAction, (state) => {
  if (!state.data) {
    return state;
  }

  return {
    ...state,
    data: { ...state.data, itemsCount: state.data.itemsCount + 1 },
  };
});

const {
  reducer: setMerchantReadyReducer,
  saga: setReadyDefaultAsyncSaga,
  actions: setReadyActions,
} = createDefaultAsyncHelpers({
  cleanAction,
  fetchFunc: setMerchantReady,
  actionName: 'MERCHANT_ACTIVATION__SET_READY',
  initialData: undefined,
});

const reducer = combineReducers<State>({
  products: fetchSettingsReducer,
  setReady: setMerchantReadyReducer,
});

const merchantActivationFetchSettingsAction = fetchSettingsActions.request;
const merchantActivationSetReadyAction = setReadyActions.request;

function* saga() {
  yield safeTakeLatest(merchantActivationFetchSettingsAction, fetchSettingsDefaultAsyncSaga);
  yield safeTakeLatest(setReadyActions.request, setReadyDefaultAsyncSaga);
}

export {
  productIsCreatedAction,
  merchantActivationFetchSettingsAction,
  merchantActivationSetReadyAction,
  reducer,
  saga,
};
