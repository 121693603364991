import { combineReducers } from 'redux';

import { saga as bestSellersSaga, reducer as bestSellersReducer } from './best-sellers';
import { saga as latestProductsSaga, reducer as latestProductsReducer } from './latest-products';
import { saga as productStatisticsSaga, reducer as productStatisticsReducer } from './merchant-product-statitics';
import { saga as publicInfoUpdateSaga, reducer as publicInfoUpdateReducer } from './public-info-update';

export function* saga() {
  yield* productStatisticsSaga();
  yield* publicInfoUpdateSaga();
  yield* bestSellersSaga();
  yield* latestProductsSaga();
}

export const reducer = combineReducers({
  publicInformationUpdate: publicInfoUpdateReducer,
  productStats: productStatisticsReducer,
  bestSellers: bestSellersReducer,
  latestProducts: latestProductsReducer,
});
