import { usePrevious } from '@frontend/commons/src/helpers/hooks';
import { useEffect } from 'react';

import { MANUAL_MERCHANT_INTEGRATION_TYPE } from 'pages/merchant/constants';
import { useActionWithDispatch, useSelector } from 'store';
import { getMerchantSettings } from 'store/merchant-settings';

import { merchantActivationFetchSettingsAction } from './store';
import { merchantActivationSetReadyAction } from './store/duck';

export function useMerchantSettings() {
  const {
    data: merchantProductsData,
    pending: merchantProductsPending,
    pristine: merchantProductsPristine,
  } = useSelector((state) => state.organisms.merchantActivation.products);
  const {
    data: { merchant: merchantSettingsData },
    pending: merchantSettingsPending,
    pristine: merchantSettingsPristine,
  } = useSelector(getMerchantSettings);
  const dataLoaded = !merchantProductsPristine && !merchantSettingsPristine;
  const isVisible = merchantSettingsData?.isVisible;
  const isReady = merchantSettingsData?.isReady;
  const isManual =
    merchantSettingsData != null
      ? merchantSettingsData.integrationType === MANUAL_MERCHANT_INTEGRATION_TYPE
      : undefined;
  const { integrationType } = merchantSettingsData ?? {};
  const hasProducts = (merchantProductsData?.itemsCount ?? 0) > 0;
  const pending = merchantProductsPending || merchantSettingsPending;

  return { dataLoaded, isVisible, isReady, hasProducts, pending, isManual, integrationType };
}

export function useEffectFetchMerchantSettings() {
  const { pending, dataLoaded } = useMerchantSettings();
  const dispatchFetchAction = useActionWithDispatch(merchantActivationFetchSettingsAction);

  useEffect(() => {
    if (!dataLoaded && !pending) {
      dispatchFetchAction();
    }
  }, [dataLoaded, pending, dispatchFetchAction]);
}

export function useSetMerchantReadyAction() {
  const dispatchSetReadyAction = useActionWithDispatch(merchantActivationSetReadyAction);
  const { pending } = useSelector((state) => state.organisms.merchantActivation.setReady);
  const prevPending = usePrevious(pending);
  const finished = prevPending === true && !pending;

  return { dispatchSetReadyAction, pending, finished };
}
