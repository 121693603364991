import { changePosCampaignName } from './change-pos-campaign-name';
import { fetchPosCampaignProductsPreview } from './fetch-pos-campaign-products-preview';
import { getProposalForCampaign } from './fetch-proposal-for-campaign';
import { getCampaignSingle } from './get-campaign-single';
import { getCampaigns } from './get-campaigns';
import { getPosSingleCampaignSummary } from './get-single-campaigns-summary';
export type { SummaryProductInfo } from './types-get-single-campaigns-summary';

export const PosCampaignsAPI = {
  getCampaigns,
  getCampaignSingle,
  fetchPosCampaignProductsPreview,
  getProposalForCampaign,
  getPosSingleCampaignSummary,
  changePosCampaignName,
};

export type { GetCampaignSingleRequest, PosCampaign } from './types-get-campaign-single';
