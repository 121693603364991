import { keyBy } from 'lodash';
import { createSelector } from 'reselect';

import { USER_PRODUCT_EDITOR, USER_UNCATEGORIZED_VIEWER } from 'constants/USER_CLAIMS';
import { userProfilesSelector } from 'pages/profile-switcher/store/selectors';
import { ProductCommission } from 'services/api/common-types/types-commissions';
import { Product } from 'services/api/pos/products/types-search-for-products';
import { profileSelector } from 'store/auth/selectors';

import { GlobalState } from 'store/types';

import { SearchParamsState } from '../search-params/types';

export const productState = (state: GlobalState) => state.pages.insertProducts.products;
export const productData = (state: GlobalState) => productState(state).data;

export const productsPagingDataSelector = createSelector(productState, (state) => ({
  total: state.data?.total ?? 0,
  loading: state.pending,
}));

export const productsSelector = (state: GlobalState): Product[] =>
  state.pages.insertProducts.products.data?.products ?? [];

export const productsPendingSelector = (state: GlobalState): boolean => state.pages.insertProducts.products.pending;

export const getProducts = createSelector(productsSelector, (products: Product[]): Product[] => products);

export const getTotalSelector = (state: GlobalState) => state.pages.insertProducts.products.data?.total ?? null;

export const selectedFiltersSelector = (state: GlobalState): SearchParamsState['filters'] =>
  state.pages.insertProducts.searchParams.filters;

const searchParamsSelector = (state: GlobalState): SearchParamsState => state.pages.insertProducts.searchParams;

export const hasUncategorizedViewerClaimSelector = createSelector(
  profileSelector,
  (profile) => profile?.claims.includes(USER_UNCATEGORIZED_VIEWER) || false
);

export const hasProductEditorClaimSelector = createSelector(
  profileSelector,
  (profile) => profile?.claims.includes(USER_PRODUCT_EDITOR) || false
);

export const termsRequestPendingSelector = createSelector(
  userProfilesSelector,
  (userProfile) => userProfile.setProductEditorTerms.pending
);

export const allSearchParamsSelector = createSelector(
  searchParamsSelector,
  hasUncategorizedViewerClaimSelector,
  (params, includeUncategorized) => ({
    ...params,
    filters: { ...params.filters, includeUncategorized },
  })
);

export const getCommissions = (state: GlobalState): ProductCommission[] =>
  state.pages.insertProducts.commissions?.data ?? [];

export const commissionsByProductId = createSelector(getCommissions, (commissions) => keyBy(commissions, 'productId'));

export const getCurrentPageNumber = (state: GlobalState) => state.pages.insertProducts.pageNumber;
